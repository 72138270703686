import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getvegdata, getlanguage, AuditLogdata } from "./store/index";
import { Card, Button } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';


const VegetablesFeed = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredVegetables, setFilteredVegetables] = useState([]);
  const [expanded, setExpanded] = useState(null); // For expanding vegetable details
  const cardRefs = useRef([]); // Array to store refs for all cards
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [placeholder, setPlaceholder] = useState('Search');
  

  const userId = localStorage.id
  const auditLogged = useRef(false);
  const screenName = 'Vegetables'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Vegetables - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  const handleFocus = () => {
    setPlaceholder(''); // Clear the placeholder text on focus
  };
  const handleBlur = () => {
    if (searchTerm === '') {
      setPlaceholder('Search'); // Reset placeholder if the input is empty
    }
  };
  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        const lang = res.payload.language;
        dispatch(getvegdata(lang)).then((response) => {
          const data = response.payload.data;
          logAuditData()
          // Sort the data alphabetically by name
          const sortedData = data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          setImageData(sortedData);
          setFilteredVegetables(sortedData); // Initially show all vegetables
        });
      });
    }
  }, [dispatch]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredVegetables(
      imageData.filter((item) =>
        item.name.toLowerCase().includes(searchValue)
      )
    );
  };

  const scrollToCard = (index) => {
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center", // Centers the card in the viewport
      });
    }
  };

  const toggleDetails = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle expanded details
  };

  return (
    <>
    <div className="nuts-feed-container"  >
      <div className="feed-layout" style={{ width: "100%", height: "75vh ",  marginTop: "0%" }}>
        <div className="feed-main" >

          <div className="nuts-card-wrapper" >
            {filteredVegetables.map((item, index) => (
              <Card
                key={index}
                ref={(el) => (cardRefs.current[index] = el)} // Attach ref to each card
                className="fruit-card"
                onClick={() => toggleDetails(index)}
                style={{width:"95%",margin:"auto",marginBottom:"2%"}}
              >
                <div className="fruit-card-header">
                  <div className="fruit-card-img-wrapper">
                    <h5 className="fruit-card-title" style={{ marginTop: "2%", fontSize: "26px" }}>{t(item.name)}</h5>
                    <img
                      src={item.img}
                      alt={item.name}
                      className="fruit-card-img"
                    // style={{ filter: "drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.3))" }}
                    />
                  </div>
                </div>
                <Card.Body className="fruit-card-body">
                  <div
                    className=""
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content || t("No content available"),
                      }}
                    ></div>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.content ),
                      }}
                    
                    ></div> */}

                  </div>
                  <Button
                    variant="link"
                    className="view-more-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDetails(index);
                    }}
                  >
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>

        <div className="feed-sidebar">
          {/* <div className="search-container"> */}
          <div className="search-container" style={{ display: 'flex', alignItems: 'center' }}>
            <FaSearch style={{ position: 'absolute', left: '20px', color: '#aaa' }} />
            <input
              type="text"
              placeholder={placeholder}
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
              style={{ paddingRight: '30px', fontSize: "18px" }} // Adds space on the right side for the icon
              autoFocus
              onFocus={handleFocus} // Clear placeholder on focus
              onBlur={handleBlur} // Reset placeholder if empty on blur
            />

          </div>
          {/* </div> */}
          <ul className="sidebar-list">
            {/* Fruit List */}
            {imageData.map((item, index) => (
              <li
                key={index}
                className="sidebar-item"
                onClick={() => scrollToCard(index)} // Scroll to the corresponding card
                style={{ textAlign: "center", cursor: "pointer" }}
              >
                {t(item.name)}
              </li>
            ))}
          </ul>
        </div>

      </div>

    </div>
  </>
  );
};

export default VegetablesFeed;
