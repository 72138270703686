import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { AesDecrypt } from "../../../services/crypto";
import { getAnxietyData, getDepressionData, getStressData, AuditLogdata } from './store';
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../breadcrumbs";
import { useTranslation } from "react-i18next";
import "./../../../css/custom.css"
import depression from '../../../images/iconly/bulk/Distress.svg';
import anxiety from '../../../images/iconly/bulk/Anxiety.svg';
import stress from '../../../images/iconly/bulk/Sadness.svg';
import img from '../../../images/EmptyFile.svg'
import moment from "moment";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const DASHOME = () => {
  const [stressData, setstressData] = useState(null);
  const [depressionData, setdepressionData] = useState(null);
  const [anxietyData, setanxietyData] = useState(null);
  const _id = localStorage.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userId = localStorage.id;
  const screenName = 'Mental Health'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const auditLogged = useRef(false); // Ref to track if audit logging has been done

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Mental Health - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  // const logAuditData = () => {
  //   const action = 'Mental Health - View'
  //   const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
  //   dispatch(AuditLogdata(auditData));
  // };
  // Fetch Stress Data
  useEffect(() => {
    const fetchStressData = async () => {
      try {
        const res = await dispatch(getStressData(_id));
        const tempdata = res.payload?.data;
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setstressData(data);
        }
      } catch (error) {
        console.error(t('Error fetching stress data:'), error);
      }
    };

    fetchStressData();
    logAuditData(); // Log audit data only once
  }, [dispatch, _id]);
  // Fetch Depression Data
  useEffect(() => {
    dispatch(getDepressionData(_id))
      .then((res) => {
        const tempdata = res.payload?.data;
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setdepressionData(data);
        }
      })
      .catch((error) => {
        console.error(t('Error fetching depression data:'), error);
      });
  }, [dispatch, _id]);
  // Fetch Anxiety Data
  useEffect(() => {
    dispatch(getAnxietyData(_id))
      .then((res) => {
        const tempdata = res.payload?.data;
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setanxietyData(data);
        }
      })
      .catch((error) => {
        console.error(t('Error fetching anxiety data:'), error);
      });
  }, [dispatch, _id]);

  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;
    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);
    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    let adjustedScore;
    switch (type) {
      case "depression":
        adjustedScore = (totalScore * 100) / 27;
        break;
      case "anxiety":
        adjustedScore = (totalScore * 100) / 21;
        break;
      case "stress":
        adjustedScore = (totalScore * 100) / 40;
        break;
      default:
        return 0; // Return 0 for unknown types
    }
    // Cap the adjusted score at 100
    return Math.round(Math.min(adjustedScore, 100));
  };
  const getAnxietySeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return t("Severe");
  };
  const getDepressionSeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return t("Severe");
  };
  const getStressSeverity = (score) => {
    if (score >= 0 && score <= 5) return t("None-minimal");
    if (score >= 6 && score <= 25) return t("Mild");
    if (score >= 26 && score <= 50) return t("Moderate");
    if (score >= 51 && score <= 75) return t("Moderately Severe");
    if (score >= 76 && score <= 100) return t("Severe");
    return t("Severe");
  };
  let anxietyScore = 0;
  let anxietySeverity = t("");
  let depressionScore = 0;
  let depressionSeverity = t("");
  let stressScore = 0;
  let stressSeverity = t("");
  const getColor = (score) => {
    if (score < 20) return "#4CAF50"; // Green
    else if (score < 40) return "#2196F3"; // Blue
    else if (score < 60) return "#ffa500"; // Yellow
    else if (score < 80) return "#ff6347"; // Orange
    else return "#f42702"; // Red
  };
  const getTrailColor = (score) => {
    if (score < 20) return "#a7f4aa"; // Lighter Green
    else if (score < 40) return "#96c6ec"; // Lighter Blue
    else if (score < 60) return "#f6d089"; // Lighter Yellow
    else if (score < 80) return "#fca596"; // Lighter Orange
    else return "#f42702"; // Lighter Red
  };
  const getSeverityColor = (score) => {
    if (score < 20) return '#059862';       // Low severity
    else if (score < 40) return "#2196F3"; // Blue
    else if (score < 60) return "#ffa500"; // Yellow
    else if (score < 80) return "#ff6347"; // Orange
    else return "#f42702";                   // Severe
  };
  // Ensure data is present before calculating
  if (anxietyData?.anxiety) {
    anxietyScore = calculateScore(anxietyData.anxiety, "anxiety");
    anxietySeverity = getAnxietySeverity(anxietyScore);
  }
  if (depressionData?.depression) {
    depressionScore = calculateScore(depressionData.depression, "depression");
    depressionSeverity = getDepressionSeverity(depressionScore);
  }
  if (stressData?.stress) {
    stressScore = calculateScore(stressData.stress, "stress");
    stressSeverity = getStressSeverity(stressScore);
  }
  return (
    <Fragment>
      <h1 className="container_heading" style={{ marginBottom: '2%', marginTop:"-2%" }}>MentalHealth</h1>
      {/* <BreadCrumbs title={t('')} data={[{ title: t('MentalHealth') }]} /> */}
      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="card" >
            <div style={{ position: "relative" }}>
              <div className="card_text_image">
                <div className="card_text">{t('Depression')}</div>
                <div className="card_image"><img src={depression} alt="Depression" style={{ width: '100px', height: '100px' }} /></div>
              </div>
              <div className="circular_background">
                <div className="card_circular_path">
                  <div className="circularpath">
                    {depressionData && depressionData.length > 0 ? (
                      <CircularProgressbar
                        value={depressionData[0].score}
                        maxValue={100}
                        text={`${depressionData[0].score}%`}
                        styles={buildStyles({
                          pathColor: getColor(depressionData[0].score),
                          trailColor: getTrailColor(depressionData[0].score),
                          textColor: "#fff",
                        })}
                      />
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="card_start">
                <Link to="/depression">
                  <button className="startquiz">Start Quiz</button>
                </Link>
              </div>
            </div>


            <h1 className="dsa_header">Recent records</h1>
            <div className="dsa_content">
              <div className="dsa_card_container">
                {depressionData && depressionData.length > 0 ? (
                  depressionData.map((depressionData, index) => {
                    const depressionScore = depressionData.score;
                    const depressionSeverity = getDepressionSeverity(depressionData.score);
                    const depressiontestDate = depressionData.createdDate;

                    return (
                      depressionScore > 0 && (
                        <div className="dsa_card_item" key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                          <div className="dsa_card">
                            <h5 className="dsa_font">
                              {t('Score :')} {depressionScore}
                            </h5>
                            <h5 className="dsa_font" style={{ fontWeight: "600" }}>
                              {t('Level : ')}
                              <span
                                style={{ color: getSeverityColor(depressionScore) }} // Color based on score
                              >
                                {getDepressionSeverity(depressionScore)} {/* Text based on score */}
                              </span>
                            </h5>
                            <h5 className="dsa_font">
                              {t('Date :')} {t(moment(depressiontestDate).format('DD-MM-YYYY h:mm A'))}
                            </h5>
                          </div>
                          <div style={{ width: 70, height: 70, }}>
                            <CircularProgressbar
                              value={depressionScore}
                              maxValue={100}
                              text={`${depressionScore}%`}
                              styles={buildStyles({
                                pathColor: getColor(depressionScore),
                                trailColor: getTrailColor(depressionScore),
                                textColor: "#333",
                              })}
                            />
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p>{<div className="d-flex justify-content-center">
                    <img src={img} alt="No Data" style={{ position: "relative", top: "75px", height: "30vh" }} />
                  </div>}
                  </p>
                )}
              </div>

            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card" style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px" }}>
          <div style={{ position: "relative" }}>
              <div className="card_text_image">
                <div className="card_text">{t('Anxiety')}</div>
                <div className="card_image"><img src={anxiety} alt="Anxiety" style={{ width: '100px', height: '100px' }} /></div>
              </div>
              <div className="circular_background">
                <div className="card_circular_path">
                  <div className="circularpath">
                    {anxietyData && anxietyData.length > 0 ? (
                      <CircularProgressbar
                        value={anxietyData[0].score}
                        maxValue={100}
                        text={`${anxietyData[0].score}%`}
                        styles={buildStyles({
                          pathColor: getColor(anxietyData[0].score),
                          trailColor: getTrailColor(anxietyData[0].score),
                          textColor: "#fff",
                        })}
                      />
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="card_start">
                <Link to="/anxiety">
                  <button className="startquiz">Start Quiz</button>
                </Link>
              </div>
            </div>
            <h1 className="dsa_header">Recent records</h1>
            <div className="dsa_content">
              <div className="dsa_card_container">
                {anxietyData && anxietyData.length > 0 ? (
                  anxietyData.map((anxietyData, index) => {
                    const anxietyScore = anxietyData.score;
                    // const anxietySeverity = getDepressionSeverity(anxietyData.score);
                    const anxietytestDate = anxietyData.createdDate;

                    return (
                      anxietyScore > 0 && (
                        <div className="dsa_card_item" key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                          <div className="dsa_card">
                            <h5 className="dsa_font">
                              {t('Score :')} {anxietyScore}
                            </h5>
                            <h5 className="dsa_font" style={{ fontWeight: "600" }}>
                              {t('Level : ')}
                              <span
                                style={{ color: getSeverityColor(anxietyScore) }} // Color based on score
                              >
                                {getAnxietySeverity(anxietyScore)} {/* Text based on score */}
                              </span>
                            </h5>
                            <h5 className="dsa_font">
                              {t('Date :')} {t(moment(anxietytestDate).format('DD-MM-YYYY h:mm A'))}
                            </h5>
                          </div>
                          <div style={{ width: 70, height: 70 }}>
                            <CircularProgressbar
                              value={anxietyScore}
                              maxValue={100}
                              text={`${anxietyScore}%`}
                              styles={buildStyles({
                                pathColor: getColor(anxietyScore),
                                trailColor: getTrailColor(anxietyScore),
                                textColor: "#333",
                              })}
                            />
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p>{<div className="d-flex justify-content-center">
                    <img src={img} alt="No Data" style={{ position: "relative", top: "75px", height: "30vh" }} />
                  </div>}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="card" style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px" }}>
          <div style={{ position: "relative" }}>
              <div className="card_text_image">
                <div className="card_text">{t('Stress')}</div>
                <div className="card_image"><img src={stress} alt="Anxiety" style={{ width: '100px', height: '100px' }} /></div>
              </div>
              <div className="circular_background">
                <div className="card_circular_path">
                  <div className="circularpath">
                    {stressData && stressData.length > 0 ? (
                      <CircularProgressbar
                        value={stressData[0].score}
                        maxValue={100}
                        text={`${stressData[0].score}%`}
                        styles={buildStyles({
                          pathColor: getColor(stressData[0].score),
                          trailColor: getTrailColor(stressData[0].score),
                          textColor: "#fff",
                        })}
                      />
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="card_start">
                <Link to="/stress">
                  <button className="startquiz">Start Quiz</button>
                </Link>
              </div>
            </div>
            <h1 className="dsa_header">Recent records</h1>
            <div className="dsa_content">
              <div className="dsa_card_container">
                {stressData && stressData.length > 0 ? (
                  stressData.map((scoreData, index) => {
                    const stressScore = scoreData.score;
                    const stressSeverity = getStressSeverity(scoreData.score);
                    const stresstestDate = scoreData.createdDate;

                    return (
                      stressScore > 0 && (
                        <div className="dsa_card_item" key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div className="dsa_card">
                            <h5 className="dsa_font">
                              {t('Score :')} {stressScore}
                            </h5>
                            <h5 className="dsa_font" style={{ fontWeight: "600" }}>
                              {t('Level : ')}
                              <span
                                style={{ color: getSeverityColor(stressScore) }}
                              >
                                {getStressSeverity(stressScore)}
                              </span>
                            </h5>
                            <h5 className="dsa_font">
                              {t('Date :')} {t(moment(stresstestDate).format('DD-MM-YYYY h:mm A'))}
                            </h5>
                          </div>
                          <div style={{ width: 70, height: 70, }}>
                            <CircularProgressbar
                              value={stressScore}
                              maxValue={100}
                              text={`${stressScore}%`}
                              styles={buildStyles({
                                pathColor: getColor(stressScore),
                                trailColor: getTrailColor(stressScore),
                                textColor: "#333",
                              })}
                            />
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p>{<div className="d-flex justify-content-center">
                    <img src={img} alt="No Data" style={{ position: "relative", top: "75px", height: "30vh" }} />
                  </div>}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DASHOME;
