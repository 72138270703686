import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Updatepassword, getPassword, AuditLogdata } from './store';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import { AesDecrypt } from '../../../services/crypto';
import { MD5 } from 'crypto-js';
import Swal from 'sweetalert2';
import { Label } from 'reactstrap';

const Changepassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [oldpassword, setoldpassword] = useState("");
    const navigate = useNavigate();
    const [Id, setId] = useState("");
    const currentPasswordRef = useRef();
    const inputRef = useRef();
    const newPasswordata = useRef();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password1, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ currentPassword: '', password1: '', confirmPassword: '' });
    const userId = localStorage.id

    const screenName = 'Update Password'
    const updatedDate = new Date()
    const deviceType = 'web'
    const appVersion = ''

    const logUpdateAuditData = () => {
        const action = 'Password - Update'
        const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
        dispatch(AuditLogdata(auditData));
    };

    useEffect(() => {
        const id = localStorage.id;
        setId(id);

        if (id !== "undefined") {
            dispatch(getPassword(id)).then((res) => {
                try {
                    const oldpassword = AesDecrypt(res.payload.password);
                    setoldpassword(oldpassword);
                } catch (error) {
                    console.error("Decryption failed: Invalid encrypted data.", error);
                    // Handle error by setting a default or clearing old password
                    setoldpassword("");  // Set an empty or default value if decryption fails
                }
            });
        }
    }, [dispatch]);


    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
        setErrors(prevErrors => ({ ...prevErrors, currentPassword: '' }));
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrors(prevErrors => ({ ...prevErrors, password1: '' }));
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '' }));
    };

    const handlePasswordClick = (e) => {
        e.preventDefault();
        // window.location.href = '/page-forgot-password';
        window.open('/page-forgot-password', '_blank');
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!currentPassword || !password1 || !confirmPassword) {
            setErrors({
                currentPassword: !currentPassword ? t('Current password is required') : '',
                password1: !password1 ? t('New password is required') : '',
                confirmPassword: !confirmPassword ? t('Confirm password is required') : ''
            });
            return;
        }

        if (password1.length < 6 || confirmPassword.length < 6) {
            setErrors({
                password1: password1.length < 6 ? t('Password contains minimum 6 digits') : '',
                confirmPassword: confirmPassword.length < 6 ? t('Password contains minimum 6 digits') : ''
            });
            return;
        }

        const Oldpassword = MD5(currentPassword).toString();
        const password = MD5(password1).toString();
        const Newpassword = MD5(confirmPassword).toString();
        const data = { password, Id };

        if (Oldpassword === oldpassword) { // Check if current password matches old password
            if (password === Newpassword) {
                dispatch(Updatepassword(data)).then((res) => {
                    const Response = res.payload.response;
                    if (Response === "S") {
                        Swal.fire({
                            title: t("Password Update Successful"),
                            icon: "success",
                        });
                        logUpdateAuditData()
                        navigate('/dashboard');
                    }
                });
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    password1: t('Passwords do not match')
                }));
            }
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                currentPassword: t('Current password does not match')
            }));
        }

        if (password !== Newpassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: t('Passwords do not match')
            }));
        }
    };



    return (
        <Fragment>
            <div className="card col-lg-6 container justify-content-center " style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className='icon_backButton' >
                            <Link to={"/dashboard"}>
                                <i className="fas fa-arrow-alt-circle-left"></i>
                            </Link>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-xl-12 tab-content">
                                <div id="sign-in" className="form-validate">
                                    <form className="form-validate" onSubmit={handleSubmit}>
                                        <h3 className="text-center mb-4 text-black">{t('Update Password')}</h3>
                                        <div className="form-group mb-3">
                                            <label className="mb-1" htmlFor="val-password"><strong className='text-black'>{t('Current Password')}</strong></label>
                                            <div className="input-group">
                                                <input
                                                    ref={currentPasswordRef}
                                                    onChange={handleCurrentPasswordChange}
                                                    value={currentPassword}
                                                    maxLength={25}
                                                    type="password" style={{ display: "block", width: "100%", border: "0px", borderBottom: "1px solid orange", padding: "5px", fontSize: "20px", outline: "none" }} />
                                                <ReactPasswordToggleIcon
                                                    inputRef={currentPasswordRef}
                                                    showIcon={() => <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                                    hideIcon={() => <i className="fa fa-eye" aria-hidden="true"></i>}
                                                />
                                            </div>
                                            {errors.currentPassword && <span style={{ color: 'red' }}>{errors.currentPassword}</span>}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="mb-1"><strong className='text-black'>{t('New Password')}</strong></label>
                                            <div className="input-group">
                                                <input
                                                    ref={inputRef}
                                                    onChange={handlePasswordChange}
                                                    value={password1}
                                                    maxLength={25}
                                                    type="password" style={{ display: "block", width: "100%", border: "0px", borderBottom: "1px solid orange", padding: "5px", fontSize: "20px", outline: "none" }} />
                                                <ReactPasswordToggleIcon
                                                    inputRef={inputRef}
                                                    showIcon={() => <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                                    hideIcon={() => <i className="fa fa-eye" aria-hidden="true"></i>}
                                                />
                                            </div>
                                            {errors.password1 && <span style={{ color: 'red' }}>{errors.password1}</span>}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="mb-1"><strong className='text-black'>{t('Confirm Password')}</strong></label>
                                            <div className="input-group">
                                                <input
                                                    ref={newPasswordata}
                                                    style={{ display: "block", width: "100%", border: "0px", borderBottom: "1px solid orange", padding: "5px", fontSize: "20px", outline: "none" }}
                                                    type="password"
                                                    maxLength={25}
                                                    onChange={handleConfirmPasswordChange}
                                                />
                                                <ReactPasswordToggleIcon
                                                    inputRef={newPasswordata}
                                                    showIcon={() => <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                                    hideIcon={() => <i className="fa fa-eye" aria-hidden="true"></i>}
                                                />
                                            </div>
                                            {errors.confirmPassword && <span style={{ color: 'red' }}>{errors.confirmPassword}</span>}
                                        </div>

                                        <div className="text-center form-group mb-3" >
                                            {/* <button type="button" className="btn btn-secondary" style={{ marginRight: "5px" }} onClick={() => navigate('/dashboard')}>{t('Back')}</button> */}
                                            <button type="submit" className="btn btn-primary" >{t('Submit')}</button>
                                        </div>
                                        <div>
                                            {/* <Link to={'/page-forgot-password'} onClick={handlepasswordclick}> */}
                                            <Label onClick={handlePasswordClick} style={{ textAlign: 'end', width: "100%", color: 'blue', cursor: 'pointer' }}>
                                                Forgot password
                                            </Label>
                                            {/* </Link> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Changepassword;
