import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import "react-datepicker/dist/react-datepicker.css";
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice'
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
// import Switchuser from "./Switchuser";
import { getProfileData, getprofession, updateUserData, Addfamilydata, getfamilyData, deletemember, getprofile, removeprofile, AuditLogdata } from "../store";

import IntlDropdown from "../../../layouts/langdropdown";
import LogoutPage from '../../../layouts/nav/Logout';
import { IoMdAddCircle } from "react-icons/io";
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse, isValid } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AvatarPaths = {
  A: "",
  B: "",
  C: "",
  D: "",
  E: "",
  F: "",
  G: "",
  H: "",
  I: "",
  J: "",
  K: "",
  L: "",
  M: "",
  N: "",
  O: "",
  P: "",
  Q: "",
  R: "",
  S: "",
  T: "",
  U: "",
  V: "",
  W: "",
  X: "",
  Y: "",
  Z: ""
};


const AppProfile = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [Alldata, setAlldata] = useState([]);
  const [Id, setId] = useState("");
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [UserType, setUserType] = useState("");
  const [parentId, setParentId] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [customProfession, setCustomProfession] = useState('');
  const [FamilyData, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [relationship, setRelation] = useState('');
  const [error, setErrorchange] = useState({ name: '', dob: '', gender: '', relationship: '' });
  
  const auditLogged = useRef(false);
  const screenName = 'User Profile'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'User Profile - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  const logAddAuditData = () => {
    const action = 'User Profile - Child Add'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const logUpdateAuditData = () => {
    const action = 'User Profile - User Profile Update'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const logDeleteAuditData = () => {
    const action = 'User Profile - Child Delete'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const handleReloadTable = () => {
    dispatch(getfamilyData(parentId)).then((res) => {
      const Encparam = AesDecrypt(res.payload.data)

      setData(Encparam)
    })
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : 'transparent', // Customize background color
      color: state.isSelected ? 'white' : 'black', // Customize text color
    }),
  };
  // const [showDOB, setShowDOB] = useState(true);

  const lang = localStorage.lang

  const usertype = localStorage.getItem('isChild') === 'true';
  // to get family members 
  useEffect(() => {
    const data = localStorage.getItem('userDetails');
    const id = localStorage.getItem('id');
    // const UserType = JSON.parse(data).UserType
    const parentId = JSON.parse(data)._id
    setParentId(parentId)
    setId(id);

    if (id !== "undefined") {
      dispatch(getProfileData(id)).then((response) => {
        const profiledata = AesDecrypt(response.payload.encryptedData)
        setAlldata(profiledata);
        logAuditData()
      })
      dispatch(getfamilyData(parentId)).then((res) => {
        const Encparam = AesDecrypt(res.payload.data)
        setData(Encparam)
      })
    }
  }, [dispatch]);


  const renderAvatar = () => {
    if (!Alldata.img) {
      const firstLetter = Alldata.name ? Alldata.name.charAt(0).toUpperCase() : '';
      return firstLetter;
    }
    // If image data is available, return the image
    return `data:image/jpeg;base64,${Alldata.img}`;
  };

  ///update  the user Details

  const bloodGroupOptions = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' }
  ];

  const maritalStatusOptions = [
    { value: 'Single', label: t('Single') },
    { value: 'Married', label: t('Married') },
    { value: 'Divorced', label: t('Divorced') },
    { value: 'Widowed', label: t('Widowed') },
  ];

  const genderOptions = [
    { value: 'Male', label: t('Male') },
    { value: 'Female', label: t('Female') },
    { value: 'Transgender', label: t('Transgender') },

  ];

  // const RelationOptions = [
  //   { value: 'Father', label: t('Father') },
  //   { value: 'Mother', label: t('Mother') },
  //   { value: 'Son', label: t('Son') },
  //   { value: 'Brother', label: t('Brother') },
  //   { value: 'Sister', label: t('Sister') },
  //   { value: 'Wife', label: t('Wife') },
  // ];

  const FoodOptions = [
    { value: 'Veg', label: t('Veg') },
    { value: 'Non-Veg', label: t('Non-Veg') },
    { value: 'Vegan', label: t('Vegan') },
  ];
  const [typeOptions, setTypeOptions] = useState([]);

  const [isEditing, setIsEditing] = useState(""); // Tracks the field being edited inline
  const [updatedData, setUpdatedData] = useState({ ...Alldata }); // Local state for changes
  // const [errors, setErrors] = useState({}); // Tracks validation errors
  // const [showInput, setShowInput] = useState(false); // Controls custom profession input
  const editRef = useRef(null); // Ref to detect clicks outside the editable field

  const handleFieldEdit = (field) => {
    setIsEditing(field);
    setUpdatedData({ ...Alldata }); // Ensure updatedData starts with current Alldata
  };


  const handleFieldBlur = () => {
    if (isEditing) {
      if (!updatedData[isEditing]?.trim()) {
        setUpdatedData((prev) => ({
          ...prev,
          [isEditing]: Alldata[isEditing], // Revert to previous value
        }));
      } else {
        handleUpdate(); // Save changes only if the value is valid
      }
      setIsEditing(""); // Exit edit mode
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        handleFieldBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, updatedData, Alldata]);
  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case "name":
        // if (!value) error = "Name is required.";
        break;
      case "dob":
        if (!value) error = "Date of Birth is required.";
        else if (new Date(value) > new Date()) error = "Date of Birth cannot be in the future.";
        break;
      case "bloodGroup":
      case "gender":
      case "maritalStatus":
      case "foodType":
        if (!value) error = `Please select a valid ${field}.`;
        break;
      case "profession":
        if (!value) error = "Profession is required.";
        break;
      default:
        break;
    }
    return error;
  };
  const handleSelectChange = (field, selectedOption) => {
    setUpdatedData((prev) => ({
      ...prev,
      [field]: selectedOption.value,
    }));
    if (field === "profession" && selectedOption.value === "Others") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  const renderField = (field, value) => {
    switch (field) {
      case "name":
        return (
          <>
            <input
              type="text"
              className="form-control text-black"
              value={updatedData.name || ""}
              onChange={(e) => {
                handleNameChange(e);
                const error = validateField("name", e.target.value.trim());
                setErrors((prev) => ({ ...prev, name: error }));
              }}
            />
            {errors.name && <small className="text-danger">{errors.name}</small>}
          </>
        );
      case "dob":
        return (
          <>
            <input
              type="date"
              className="form-control text-black"
              value={
                updatedData.dob
                  ? (() => {
                    try {
                      return format(
                        parse(updatedData.dob, "dd-MM-yyyy", new Date()),
                        "yyyy-MM-dd"
                      );
                    } catch {
                      return ""; // Fallback for invalid dates
                    }
                  })()
                  : ""
              }
              onChange={(e) => {
                handleDOBChange(e);
                const error = validateField("dob", e.target.value);
                setErrors((prev) => ({ ...prev, dob: error }));
              }}
              max={format(new Date(), "yyyy-MM-dd")}
            />
            {errors.dob && <small className="text-danger">{errors.dob}</small>}
          </>
        );
      case "bloodGroup":
      case "gender":
      case "maritalStatus":
      case "foodType":
        const options =
          field === "bloodGroup"
            ? bloodGroupOptions
            : field === "gender"
              ? genderOptions
              : field === "maritalStatus"
                ? maritalStatusOptions
                : FoodOptions;
        return (
          <>
            <Select
              className="text-black"
              options={options}
              value={options.find((option) => option.value === updatedData[field])}
              onChange={(option) => {
                handleSelectChange(field, option);
                const error = validateField(field, option.value);
                setErrors((prev) => ({ ...prev, [field]: error }));
              }}
              placeholder={t(`Select your ${field}`)}
            />
            {errors[field] && <small className="text-danger">{errors[field]}</small>}
          </>
        );
      case "profession":
        return (
          <>
            <div style={{ position: "relative" }}>
              <Select
                className="text-black profession_drop"
                options={typeOptions}
                value={
                  typeOptions.find(option => option.value === updatedData.profession) ||
                  typeOptions.find(option => option.value === "Others")
                }
                onChange={(option) => {
                  handleSelectChange(field, option);
                  const error = validateField("profession", option.value);
                  setErrors((prev) => ({ ...prev, profession: error }));
                }}
                placeholder={t("Select profession...")}
              />
              {showInput && (
                <div className="custom-input-wrapper mt-2">
                  <input
                    type="text"
                    className="form-control text-black"
                    value={updatedData.profession || ""}
                    onChange={(e) => {
                      handleCustomProfessionChange(e);
                      const error = validateField("profession", e.target.value.trim());
                      setErrors((prev) => ({ ...prev, profession: error }));
                    }}
                    placeholder={t("Enter profession...")}
                  />
                </div>
              )}
              {errors.profession && (
                <small className="text-danger">{errors.profession}</small>
              )}
            </div>
          </>
        );
      default:
        return value || t("Click to add");
    }
  };


  useEffect(() => {
    dispatch(getprofession()).then((response) => {
      const profession = response.payload.data
      const options = profession.map((user) => ({
        value: user.job,
        label: t(user.label),
      }));
      setTypeOptions(options);

    })
  }, [dispatch]);


  const handleCloseModal = () => {
    setErrors({
      name: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      maritalStatus: "",
      profession: "",
      relationShip: "",
      foodType: ""
    });
    setUpdatedData({
      name: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      maritalStatus: "",
      profession: "",
      relationShip: "",
      foodType: ""
    });
    setShowModal(false);
  };


  const handleUpdateClick = (data) => {

    setShowModal(true);
    // const originalDate = Alldata.dob;
    // const [day, month, year] = originalDate.split('-');
    // const formattedDate = `${year}-${month}-${day}`;
    setUpdatedData({
      name: Alldata.name,
      // mobile: Alldata.mobile,
      dob: Alldata.dob, // Use formattedDate instead of Alldata.dob
      gender: Alldata.gender,
      bloodGroup: Alldata.bloodGroup,
      maritalStatus: Alldata.maritalStatus,
      profession: Alldata.profession,
      _id: Alldata._id,
      // relationShip: Alldata.relationShip,
      foodType: Alldata.foodType
      // Add other fields here as needed
    });
  };
  const [errors, setErrors] = useState({
    name: "",
    // mobile: "",
    dob: "",
    gender: "",
    bloodGroup: "",
    maritalStatus: "",
    profession: "",
    // relationShip: "",
    foodType: ""
  });


  // Function to handle changes in name field
  const handleNameChange = (e) => {
    // Allow only alphabetic characters and spaces
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "")
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setUpdatedData({ ...updatedData, name: capitalizedValue });
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  // Function to handle changes in blood group field
  const handleBloodGroupChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, bloodGroup: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, bloodGroup: "" }));
  };

  // Function to handle changes in date of birth field
  // const handleDOBChange = (event) => {
  //   const dateValue = event.target.value; // Get the date string from the input


  //   let formattedDate = "";

  //   if (dateValue) {
  //     const parsedDate = new Date(dateValue); // Parse the string into a Date object
  //     if (!isNaN(parsedDate)) { // Check if the date is valid
  //       formattedDate = format(parsedDate, "yyyy-MM-dd");
  //     }
  //   }

  //   setUpdatedData((prevData) => ({ ...prevData, dob: formattedDate }));
  //   setErrors((prevErrors) => ({ ...prevErrors, dob: "" }));
  // };
  // const handleDOBChange = (e) => {
  //   const dateValue = e.target.value;
  //   if (dateValue) {
  //     const formattedDate = format(parse(dateValue, "yyyy-MM-dd", new Date()), "dd-MM-yyyy");
  //     setUpdatedData((prev) => ({ ...prev, dob: formattedDate }));
  //   } else {
  //     setUpdatedData((prev) => ({ ...prev, dob: "" }));
  //   }
  // };

  const handleDOBChange = (e) => {
    const dateValue = e.target.value;
 
    if (dateValue) {
      const formattedDate = format(parse(dateValue, "yyyy-MM-dd", new Date()), "dd-MM-yyyy");
      setUpdatedData((prev) => ({ ...prev, dob: formattedDate }));
    } else {
      setUpdatedData((prev) => ({ ...prev, dob: "" }));
    }
  };

  // Function to handle changes in marital status field
  const handleMaritalStatusChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, maritalStatus: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, maritalStatus: "" }));
  };

  // Function to handle changes in profession field
  const handleProfessionChange = (selectedOption) => {
    if (selectedOption.value === "Others") {
      setShowInput(true);
    } else {
      setShowInput(false);
      setUpdatedData({ ...updatedData, profession: selectedOption.value });
      setErrors((prevErrors) => ({ ...prevErrors, profession: "" }));
    }
  };

  const handleCustomProfessionChange = (e) => {
    const value = e.target.value.trim();
    setUpdatedData((prevData) => ({ ...prevData, profession: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      profession: value ? "" : "Profession is required",
    }));
  };



  // Function to handle changes in gender field
  const handleGenderChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, gender: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, gender: "" }));
  };

  const handlefoodTypeChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, foodType: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, foodType: "" }));
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if (!updatedData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!updatedData.dob) {
      newErrors.dob = "Date of Birth is required";
      isValid = false;
    }

    if (!updatedData.gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }

    if (!updatedData.bloodGroup) {
      newErrors.bloodGroup = "Blood Group is required";
      isValid = false;
    }

    if (!updatedData.maritalStatus) {
      newErrors.maritalStatus = "Marital Status is required";
      isValid = false;
    }

    if (!updatedData.profession || (updatedData.profession === "Others" && !customProfession.trim())) {
      newErrors.profession = "Profession is required";
      isValid = false;
    }

    if (!updatedData.foodType) {
      newErrors.foodType = "Food Type is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleUpdate = () => {
    if (!validateFields()) {
      return;
    }

    // Dispatch the updated data
    dispatch(updateUserData(updatedData)).then((res) => {
      if (res.payload.data) {
        const decryptedData = AesDecrypt(res.payload.data);
        setAlldata(decryptedData);
        logUpdateAuditData()
        if (res.payload.response === "S") {
          localStorage.setItem("name", decryptedData.name || "");
          toast.success('Data Edited Successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000, // 1 seconds
          });
        }
      }
      setShowModal(false);
    });
  };


  // errorsobject for Addfamily members
  const Addfamily = (data) => {
    setName('');
    setDob('');
    setGender(''); // Corrected to setGender
    setRelation('');
    setopenModal(true);
  };


  const resetFields = () => {
    setName('');
    setDob('');
    setGender('');
    setRelation('');
    setErrorchange({ name: '', dob: '', gender: '', relationship: '' });
  };

  const options = [
    { value: 'Male', label: t('Male') },
    { value: 'Female', label: t('Female') },
    { value: 'Transgender', label: t('Transgender') },
  ];
  const Relationoptions = [
    { value: 'Father', label: t('Father') },
    { value: 'Mother', label: t('Mother') },
    { value: 'Son', label: t('Son') },
    { value: 'Brother', label: t('Brother') },
    { value: 'Sister', label: t('Sister') },
    { value: 'Wife', label: t('Wife') },
    { value: 'Husband', label: t('Husband') },
  ];

  const handlegenderChange = selectedOption => {
    setGender(selectedOption.value);
    setErrorchange({ ...error, gender: '' });
  };

  const handlerelationChange = selectedoption => {
    setRelation(selectedoption.value);
    setErrorchange({ ...error, relationship: '' });
  };

  const handleNamechange = (e) => {
    // Allow only alphabetic characters and spaces
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "")
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setName(capitalizedValue);
    setErrorchange({ ...error, name: '' });
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    setDob(value);
    setErrorchange({ ...error, dob: '' });
  };

  const addmember = (e) => {
    e.preventDefault();
    let hasError = false;
    const newError = { name: '', dob: '', gender: '', relationship: '' };

    if (!name.trim()) {
      newError.name = t('Name is Required');
      hasError = true;
    }
    if (!dob || isNaN(new Date(dob).getTime())) {
      // Check if dob is valid
      newError.dob = t('DOB is Required');
      hasError = true;
    }
    if (!gender.trim()) {
      newError.gender = t('Select Gender..');
      hasError = true;
    }
    if (!relationship.trim()) {
      newError.relationship = t('Select relationship');
      hasError = true;
    }

    if (hasError) {
      setErrorchange(newError);
      return;
    }

    // Format `dob` as a string in DD-MM-YYYY format
    const dateofbirth = new Date(dob);
    const day = dateofbirth.getDate().toString().padStart(2, '0');
    const month = (dateofbirth.getMonth() + 1).toString().padStart(2, '0');
    const year = dateofbirth.getFullYear();
    const FullDate = `${day}-${month}-${year}`;

    const formatdob = FullDate;
    const data = { name, gender, dob: formatdob, parentId, lang, relationship };

    dispatch(Addfamilydata(data)).then((res) => {
      if (res.payload.response === 'S') {
        logAddAuditData()
        // Set state to show success alert
        handleReloadTable();
      }

      setName('');
      setDob(''); // Resetting dob to an empty string
      setGender('');
      setRelation('');
      // Close modal regardless of response
      setopenModal(false);
      resetFields();
    });
  };


  // delete family

  const deletefamily = (member) => {

    const id = member;
    const deletefamily = async () => {
      swal({
        title: t("Are you sure?"),
        text:
          t("Once deleted, you will not be able to recover this Child!"),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deletemember(id)).then((res) => {
            const deletedata = res.payload;
            if (deletedata.response === "S") {
              swal(t("Poof! Your child user has been deleted!"), {
                icon: "success",
              });
              logDeleteAuditData()
            } else {
              // Perform action when response is not "S"
              // For example, show another swal message
              swal(t("Failed to delete file!"), {
                icon: "error",
              });
            }
          }).then(() => {
            handleReloadTable();
          });
        } else {
          swal(t("Your child user is safe!"));
        }
      });
    };
    deletefamily();
    
  };


  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarImg, setAvatarImg] = useState(null);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Display selected image in the avatar circle
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarImg(e.target.result);
        // Call handleUpload immediately after selecting the file
        handleUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const userId = localStorage.id


  const handleEditClick = () => {
    // Trigger file input click
    const fileInput = document.getElementById("fileInput");

    if (fileInput) {
      fileInput.click();
    }
  };


  const handleUpload = async (file) => {

    // Handle file upload here
    if (file) {
      try {
        const MAX_FILE_SIZE_MB = 3; // Maximum file size in MB after compression

        // Create a temporary canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Load the image
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
          // Calculate new dimensions to reduce size by 50%
          const MAX_WIDTH = img.width * 0.5;
          const MAX_HEIGHT = img.height * 0.5;
          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
            if (img.width > img.height) {
              newWidth = MAX_WIDTH;
              newHeight = (img.height * MAX_WIDTH) / img.width;
            } else {
              newHeight = MAX_HEIGHT;
              newWidth = (img.width * MAX_HEIGHT) / img.height;
            }
          }

          // Set canvas dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw image on canvas
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // Convert canvas to Blob with desired quality (compression)
          canvas.toBlob(async (blob) => {
            // Check if compressed file size is within limit
            if (blob.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
              console.error('File size after compression exceeds limit.');
              return;
            }

            // Create FormData object
            const formData = new FormData();

            // Append the file
            formData.append('file', file); // Retain original filename

            // Create an object containing your data to be encrypted
            const dataToEncrypt = { userId: userId };

            // Encrypt the data and append it to the FormData object
            formData.append(
              'filedata',
              JSON.stringify({ Encparams: AesEncrypt(dataToEncrypt) })
            );

            // Upload compressed image
            const response = await fetch(webservice + 'api/uploadProfilepic', {
              method: 'POST',
              body: formData,

            });

            if (response.ok) {
              setShowPopup(!showPopup);
              dispatch(getprofile(localStorage.id))
                .then((res) => {
                  if (res.payload.response === 'S') {
                    const data1 = AesDecrypt(res.payload.data);
                    if (Array.isArray(data1) && data1.length !== 0) {
                      setAvatarImg(data1[0].image);
                    }
                  }
                })

              const responseData = await response.json(); // Parse JSON response

              // Handle success
            } else {
              console.error('Failed to upload file.');
              // Handle error
            }
          }, 'image/jpeg', 0.7); // Adjust quality as needed
        };
      } catch (error) {
        console.error('Error handling file:', error);
      }
    }
  };


  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getprofile(_id))
      .then((res) => {

        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);
          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);


  const handleRemoveProfile = () => {
    // Add logic to remove profile image
    setAvatarImg(null); // Assuming this clears the image
    setShowPopup(!showPopup);
    // Add logic to send ID to a collection or perform any other action
    const id = localStorage.id;
    dispatch(removeprofile(id)).then((res) => {

    })

    // Example: Send profile ID to a collection
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const today = new Date().toISOString().split('T')[0];
  // const [isEditing, setIsEditing] = useState(false);
  const [hover, setHover] = useState(false);

  const handleProfileClick = () => {
    setIsEditing(!isEditing); // Toggle the edit mode on profile picture click
  };
  return (


    <Fragment>
      <ToastContainer />
      {/* <Breadcrumbs title={t('App Profile')} data={[{ title: t('App Profile') }]} /> */}
      <div className="profile-details">
      <h2 style={{ fontSize: '24px', fontWeight: 'bold',marginLeft:"3%" }}>Profile</h2>
    </div>
      <Card className="profile-card" style={{ borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)', padding: '20px', width: '90%' }}>
        <Card.Header className="profilecard_header" style={{ borderRadius: '10px', padding: '20px' }}>
          <div className="profilecard_container" >
            <div className="profile_pricture_name">
              <div className="profilecard_picture " style={{ position: 'relative' }}>
                <input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />

                <div
                  className={`avatar-circle ${isEditing ? 'edit-mode' : ''}`} // Add class for edit mode
                  onClick={handleProfileClick} // Toggle edit mode on click
                  onMouseEnter={() => setHover(true)} // Set hover state
                  onMouseLeave={() => setHover(false)} // Unset hover state
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {avatarImg ? (
                    <img className="rounded-circle" src={`data:image/jpeg;base64,${avatarImg}`}
                      style={{ width: '100%', height: 'auto' }} />
                  ) : (
                    <span className="avatar-letter" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '30px' }}>
                      {renderAvatar()}
                    </span>
                  )}

                  
                </div>
              </div>
              <div className="profile_name">
                {Alldata.name}
                <span className="edit_name" onClick={() => handleUpdateClick(Alldata)}><i className="fas fa-pen"></i> {t('Edit')}</span>
              </div>
            </div>
          </div>

        </Card.Header>



        {/* Unified Information Section */}
        <div className="profileinfo mb-4" style={{ marginTop: "2.4%" }}>
          {/* <Card className="detail-card" style={{ borderRadius: '10px', padding: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}> */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="profileinfo_heading">{t('Profile Information')}</h4>
          </div>
          <div className="row">
            {[
              { label: "Name", value: Alldata.name, icon: "fa-solid fa-mobile-alt", field: "name" },
              { label: "Date of Birth", value: Alldata.dob, icon: "fa-solid fa-birthday-cake", field: "dob" },
              { label: "Gender", value: t(Alldata.gender), icon: "fa-solid fa-mars", field: "gender" },
              { label: "Blood Group", value: Alldata.bloodGroup, icon: "fa-solid fa-droplet", field: "bloodGroup" },
              { label: "Marital Status", value: t(Alldata.maritalStatus), icon: "fa-solid fa-heart", field: "maritalStatus" },
              { label: "Profession", value: t(Alldata.profession), icon: "fa-solid fa-user-tie", field: "profession" },
              { label: "Food Type", value: t(Alldata.foodType), icon: "fa-solid fa-carrot", field: "foodType" },
            ].map((item, index) => (
              <div key={index} className="col-md-6 mb-2">
                <div className="profileinfo_item d-flex align-items-center">
                  <i className={`fa ${item.icon} me-2 item_icon`}></i>
                  <strong className="item_label me-2">{t(item.label)}:</strong>
                  <span
                    className="ms-2 item_value"
                    ref={isEditing === item.field ? editRef : null}
                  >
                    {isEditing === item.field ? (
                      <div className="d-flex align-items-center">
                        {renderField(item.field, updatedData[item.field])}
                      </div>
                    ) : (
                      <span
                        className="editable-field"
                        onClick={() => handleFieldEdit(item.field)}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {Alldata[item.field] || t("Click to add")}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {/* </Card> */}
        </div>

        {/* Family Members Section */}

        {usertype ? (
          null
        ) : (
          <div className="family-members">
            <div className="profilefamily">
              <h4 className="profilefamily_heading" >{t('Family Members')}</h4>

              <div className="profilefamily_addbtn" variant="primary" onClick={Addfamily} >
                <IoMdAddCircle style={{}} />
                {/* {t('Add Family Member')} */}
              </div>
      
            </div>


            <div className="row mt-3 mb-4">
              {FamilyData && FamilyData.length > 0 ? (
                FamilyData.map((member, index) => (
                  member.userType === 'Child' && (
                    <div key={index} className="col-md-6">
                      <div className="family_member_card" >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="child_name">
                            <strong  >{member.name}</strong>
                          </div>
                          <div className="familyitem_btns">
                            <Link to={`/update-profile/${member._id}`} className="familyedit_btns" title={t('Edit')}>
                              <i className="fas fa-pen"></i>
                            </Link>
                            {member.childStatus === 'A' && member.profileStatus === 'I' && (
                              <div variant="danger" className="familydel_btns" style={{cursor:'pointer'}} onClick={() => deletefamily(member._id)} title={t('Delete')}>
                                <i className="fa fa-trash"></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ))
              ) : (
                <div className="text-muted">{t('No family members added.')}</div>
              )}
            </div>
          </div>
        )}



        <div className="profilecard_settings">
          <div className="setting_change_password">
            {usertype ? (null) : (<Link to={'/changepassword'}>
              <lable >Change password</lable>
            </Link>)}
          </div>
          <div className="setting_language">
            <IntlDropdown />
          </div>
          <div className="setting_logout">
            <LogoutPage />
          </div>


        </div>



      </Card>




      <Modal className="fade bd-example-modal-lg" size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Update Profile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create a form with input fields for each data field */}
          <div className="form-validation">
            <Form
              className="form-valide"
            >
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-username"
                    >
                      {t('Name')}&nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        onChange={handleNameChange}
                        value={updatedData.name}
                        className="form-control mt-2 text-black"
                        id="name"
                        name="val-username"
                        placeholder={t("Enter name..")}
                        maxLength={30}
                      />
                    </div>
                    {errors.name && <div className="text-danger errortext fs-12">{errors.name}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-blood-group">
                      {t('Blood')} <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className=" text-black"
                        options={bloodGroupOptions}
                        value={bloodGroupOptions.find(option => option.value === updatedData.bloodGroup)}
                        onChange={handleBloodGroupChange}
                        placeholder={t("Select your blood group")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.bloodGroup && <div className="text-danger fs-12 errortext">{errors.bloodGroup}</div>}
                  </div>

                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-password">
                      {t('D O B')}
                      &nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <input
                        type="date"
                        onChange={handleDOBChange}
                        value={
                          updatedData.dob
                            ? (() => {
                              try {
                                return format(
                                  parse(updatedData.dob, "dd-MM-yyyy", new Date()),
                                  "yyyy-MM-dd"
                                );
                              } catch {
                                return ""; // Fallback for invalid dates
                              }
                            })()
                            : ""
                        }
                        max={format(new Date(), "yyyy-MM-dd")}
                        className="form-control text-black"
                        id="dob"
                        name="dob"
                      />
                    </div>
                    {errors.dob && <div className="text-danger errortext fs-12">{errors.dob}</div>}
                  </div>


                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-gender">
                      {t('Gender')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <Select
                        options={genderOptions}
                        value={genderOptions.find(option => option.value === updatedData.gender)}
                        onChange={handleGenderChange}
                        placeholder={t("Select gender")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.gender && <div className="text-danger errortext fs-12">{errors.gender}</div>}
                  </div>

                  {/* <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-blood-group">
                      {t('Relationship')} <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className=" text-black"
                        options={RelationOptions}
                        value={RelationOptions.find(option => option.value === updatedData.relationShip)}
                        onChange={handlerelationshipChange}
                        placeholder={t("Select relationship")}
                      />
                    </div>
                    {errors.relationShip && <div className="text-danger fs-12">{errors.relationShip}</div>}
                  </div> */}

                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-digits"
                    >
                      {t('MaritalStatus')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={maritalStatusOptions}
                        value={maritalStatusOptions.find(option => option.value === updatedData.maritalStatus)}
                        onChange={handleMaritalStatusChange}
                        placeholder={t("Select marital status")}
                        styles={customStyles}
                      />
                    </div>

                    {errors.maritalStatus && <div className="text-danger errortext fs-12">{errors.maritalStatus}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-number">
                      {t('Profession')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={typeOptions}
                        value={
                          typeOptions.find(option => option.value === updatedData.profession) ||
                          typeOptions.find(option => option.value === "Others")
                        }
                        onChange={handleProfessionChange}
                        placeholder={t("Select profession...")}
                        styles={customStyles}
                      />
                      {showInput && (
                        <input
                          type="text"
                          className='form-control mt-2 text-black'
                          value={updatedData.profession}
                          onChange={handleCustomProfessionChange}
                          placeholder={t("Enter profession...")}
                        />
                      )}
                      {errors.profession && <div className="text-danger errortext fs-12">{errors.profession}</div>}

                    </div>
                  </div>

                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-number">
                      {t('Foodtype')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={FoodOptions}
                        value={FoodOptions.find(option => option.value === updatedData.foodType)}
                        onChange={handlefoodTypeChange}
                        placeholder={t("Select preferfood")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.foodType && <div className="text-danger fs-12 errortext mt-1">{errors.foodType}</div>}
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='me-2' variant="dark" style={{ width: "35%", marginRight: "35%" }} onClick={() => setShowModal(false)}>
            {t('Close')}
          </Button>
          <Button className='me-2' variant="primary" onClick={handleUpdate} >
            {t('Save Changes')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* add family members modal */}
      <Modal className="fade bd-example-modal-lg" size="lg" show={openModal} onHide={() => { setopenModal(false); resetFields(); }}>
        {/* <Modal className="fade bd-example-modal-lg" size="lg" show={openModal} onHide={() => setopenModal(false); resetFields();} > */}
        <Modal.Header closeButton>
          <Modal.Title>{t('Add Family Members')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create a form with input fields for each data field */}
          <div className="form-validation">
            <Form className="form-valide">
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-username">{t('Name')} <span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        onChange={handleNamechange}
                        value={name}
                        className="form-control text-black"
                        id="val-username"
                        name="val-username"
                        placeholder="Enter name.."
                      />
                    </div>
                    {error.name && <div className="text-danger errortext fs-12">{error.name}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="gender">{t('Gender')} <span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        value={options.find(option => option.value === gender)}
                        onChange={handlegenderChange}
                        options={options}
                        placeholder="Select "
                        styles={customStyles}
                      />
                    </div>
                    {error.gender && <div className="text-danger errortext fs-12">{error.gender}</div>}
                  </div>

                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="date">{t('DOB')} <span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <input
                        type="date"
                        onChange={handleDobChange}
                        value={dob}
                        max={today}
                        className="form-control text-black"
                        id="val-dob"
                        name="val-dob"
                        placeholder="Enter date of birth.."
                      />
                    </div>
                    {error.dob && <div className="text-danger errortext fs-12">{error.dob}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="relationship">{t('Relationship')}&nbsp;<span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        value={Relationoptions.find(option => option.value === relationship)}
                        onChange={handlerelationChange}
                        options={Relationoptions}
                        placeholder={t("Select")}
                        styles={customStyles}
                      />
                    </div>
                    {error.relationship && <div className="text-danger errortext fs-12">{error.relationship}</div>}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='me-2 ' variant="dark" style={{ width: "35%", marginRight: "35%" }} onClick={() => setopenModal(false)} >
            {t('Close')}
          </Button>
          <Button className='me-2' variant="primary" onClick={addmember}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AppProfile;
