// import React, { useState, Fragment, useEffect } from "react";
// import { GetAllInsuranceData } from "./store/index"
// import { useDispatch } from "react-redux";
// import "../../../css/custom.css"
// import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const Insurance = () => {
//   const [AllData, setAllData] = useState([]);
//   const dispatch = useDispatch()


//   useEffect(() => {
//     const id = localStorage.id;
//     if (id !== "undefined") {
//       dispatch(GetAllInsuranceData(id)).then((res) => {
//         console.log(res);
//         setAllData(res.payload.data);
//       });
//     }
//   }, [dispatch]);

//   console.log(AllData);


//   return (
//     <Fragment>
//       <div className="main_table">
//         <h1 className="container_heading">Insurance</h1>
//         &nbsp;<Link to={`/add_insurance`}><Button style={{ width: "auto" }}>Add Insurance</Button></Link>
//       </div>
//     </Fragment>
//   );
// };

// export default Insurance;


import React, { useState, Fragment, useEffect } from "react";
import { GetAllInsuranceData } from "./store/index";
import { useDispatch } from "react-redux";
import "../../../css/custom.css";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import gold from "./../../../images/card/FFFFFF.png"
import { Calendar, Hash, Phone, Shield, User } from "react-feather";
import { MdAccessAlarm, MdDelete } from "react-icons/md";
import { IoIosAlarm } from "react-icons/io";

const Insurance = () => {
  const [AllData, setAllData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(GetAllInsuranceData(id)).then((res) => {
        setAllData(res.payload.data);
      });
    }
  }, [dispatch]);

  return (

    <>
      <Fragment>
        <div className="insurance_main_table ">
          <div style={{ display: "flex", alignItems: "" }}>
            <h1 className="container_heading">Insurance</h1>
            &nbsp;
            <Link to={`/add_insurance`}><Button style={{ width: "auto" }}>Add Insurance</Button></Link>
          </div>


         
<div className="insurance_body">
{AllData.map((item, index) => (
            <div className="insurance_card" key={index}>
              
              <div className="card-content">
                {/* Header */}
                <div className="insurance-card-header">
                  <div>
                    <h1 className="insurance-card-title">{item.insuranceType} Insurance</h1>
                    <div className="header-underline" />
                  </div>
                  <Shield className="insurance_icon" />
                
                </div>

                {/* Policy Information */}
                <div className="policy-info">
                  <div className="d-flex jus-spa-bet" style={{}}>
                  <div className="policy-row">
                    <User className="policy-icon" />
                    <div>
                      <div className="policy-label">Policy Holder</div>
                      <div className="policy-value">{item.policyHolderName}</div>
                    </div>
                  </div>
                  <div className="policy-row">
                    <Hash className="policy-icon" />
                    <div>
                      <div className="policy-label">Policy Number</div>
                      <div className="policy-value">{item.insurancePolicyNumber}</div>
                    </div>
                  </div>
                  </div>
                  <div className="policy-row">
                    <Phone className="policy-icon" />
                    <div>
                      <div className="policy-label">Mobile Number</div>
                      <div className="policy-value">{item.phoneNumber}</div>
                    </div>
                  </div>
                </div>

                {/* Dates */}
                <div className="dates-container">
                  <div className="date-row">
                    <div >
                    <div className="d-flex  al-center gap-2">
                      <Calendar className="calendar-icon" />
                      <div className="date-label">Start Date</div>
                    </div>
                      <div className="date-value" style={{paddingLeft:"22px"}}>{new Date(item.policyStartDate).toLocaleDateString()}</div>
                    </div>
                    <div >
                    <div className="d-flex  al-center gap-2">
                      <Calendar className="calendar-icon" />
                      <div className="date-label">End Date</div>
                    </div>
                      <div className="date-value" style={{paddingLeft:"22px"}}>{new Date(item.policyEndDate).toLocaleDateString()}</div>
                    </div>
                  </div>
                  <div className="border-top date-row">
                    <div className="d-flex  al-center gap-2">
                      <MdAccessAlarm className="calendar-icon" />
                      <div className="date-label">Remainder</div>
                    </div>
                    <div className="date-value" style={{paddingLeft:"22px"}}>{new Date(item.reminderDate).toLocaleDateString()}</div>
                  </div>
                </div>
              </div>
            </div>

))}
</div>

          </div>

      </Fragment>
    </>
  );
};

export default Insurance;
