import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AddInsuranceData } from "./store/index";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

const AddInsuranceDetails = () => {
  const { t } = useTranslation(); // i18n translation hook
  const dispatch = useDispatch(); // Redux dispatcher
  const navigate = useNavigate(); // Redux dispatcher
  const [formData, setFormData] = useState({
    policyHolderName: "",
    insurancePolicyNumber: "",
    insuranceType: "",
    policyStartDate: "",
    policyEndDate: "",
    reminderDate: "",
    phoneNumber: "",
    emailAddress: "",
    reminderMode: [],
  });

  const insuranceTypeOptions = [
    { value: "Health", label: "Health" },
    { value: "Life", label: "Life" },
    { value: "Vehicle", label: "Vehicle" },
    { value: "Home", label: "Home" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, insuranceType: selectedOption.value }));
  };


  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      reminderMode: checked
        ? [...prev.reminderMode, value]
        : prev.reminderMode.filter((mode) => mode !== value),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AddInsuranceData(formData)).then((res) => {
      if (res.payload.response === "S") {
        Swal.fire({
          title: 'Insurance Details Added Successfully',
          icon: 'success',
          confirmButtonText: 'Yes',
        }).then(() => {
          navigate("/insurance")
        });
      }
    })
  };

  return (
    <div className="card" style={{ padding: "20px", margin: "20px auto", maxWidth: "600px" }}>
      <Form onSubmit={handleSubmit}>
        <header style={{ marginBottom: "20px", borderBottom: "2px solid #ccc", paddingBottom: "10px" }}>
          <h1>{t("Add Insurance Details")}</h1>
        </header>
        <div className="form-row">
          <FormGroup className="form-group" >
            <Link to="/insurance">
              <span className="rounded-icon-button">
                ✖
              </span>
            </Link>
            <Label className="text-black">{t("Policy Holder Name")}</Label>
            <Input
              type="text"
              className="text-black"
              name="policyHolderName"
              value={formData.policyHolderName}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
          <FormGroup className="form-group">
            <Label className="text-black">{t("Insurance Policy Number")}</Label>
            <Input
              type="text"
              className="text-black"
              name="insurancePolicyNumber"
              value={formData.insurancePolicyNumber}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="form-group">
            <Label className="text-black">{t("Insurance Type")}</Label>
            <Select
              name="insuranceType"
              options={insuranceTypeOptions}
              value={insuranceTypeOptions.find(option => option.value === formData.insuranceType)}
              onChange={handleSelectChange}
              style={{ height: "56%" }}
              required
            />

          </FormGroup>
          <FormGroup className="form-group">
            <Label className="text-black">{t("Policy Start Date")}</Label>
            <Input
              type="date"
              className="text-black"
              name="policyStartDate"
              value={formData.policyStartDate}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="form-group">
            <Label className="text-black">{t("Policy End Date")}</Label>
            <Input
              type="date"
              className="text-black"
              name="policyEndDate"
              value={formData.policyEndDate}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
          <FormGroup className="form-group">
            <Label className="text-black">{t("Reminder Date")}</Label>
            <Input
              type="date"
              className="text-black"
              name="reminderDate"
              value={formData.reminderDate}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="form-group">
            <Label className="text-black">{t("Phone Number")}</Label>
            <Input
              type="text"
              className="text-black"
              name="phoneNumber"
              value={formData.phoneNumber}
              // onChange={handleChange}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numeric values and restrict to 10 digits
                if (/^\d*$/.test(value) && value.length <= 10) {
                  handleChange(e); // Update form data only if the input is valid
                }
              }}
              style={{ height: "56%" }}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <Label className="text-black">{t("Email Address")}</Label>
            <Input
              type="email"
              className="text-black"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              style={{ height: "56%" }}
              required
            />
          </FormGroup>
        </div>
        <FormGroup>
          <Label>{t("Reminder Mode")}</Label>
          <div className="custom-checkbox-group">
            <Label className="custom-checkbox">
              <input
                type="checkbox"
                name="reminderMode"
                value="Notification"
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
              {t("Get Notification Alert")}
            </Label>
            <Label className="custom-checkbox">
              <input
                type="checkbox"
                name="reminderMode"
                value="Email"
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
              {t("Get Email Alert")}
            </Label>
          </div>
        </FormGroup>
        <Button type="submit" style={{ marginLeft: "31%" }} variant="primary">{t("Save")}</Button>
      </Form>
    </div>
  );
};

export default AddInsuranceDetails;
