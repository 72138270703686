import React, { useState, useEffect, useRef } from 'react';
import { Modal, Col, Row, Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import axios from 'axios';
import webservice from '../../../../services/webservice';
// import { useNavigate } from 'react-router-dom';
// import CalendarData from './calendarData';
// import moment from 'moment';
// import JSZip from 'jszip';
// import FileSaver from 'file-saver';
import love from './../../../../images/emoji/Emoji - Heart Eyes.gif'
import angry from './../../../../images/emoji/anger.gif'
import Fear from './../../../../images/emoji/fear.gif'
import Sadness from './../../../../images/emoji/sad.gif'
import Happy from './../../../../images/emoji/happy.gif'
import Cheerful from './../../../../images/emoji/cheerful.gif'
import Madness from './../../../../images/emoji/Madness.gif'
import Calm from './../../../../images/emoji/calm.gif'
import Negative from './../../../../images/emoji/negative_mood.gif'
import Anxious from './../../../../images/emoji/Anxious.gif'
import Irritability from './../../../../images/emoji/irritability.gif'
import Disgust from './../../../../images/emoji/disgust.gif'
import moment from 'moment';


import bloodwave from './../../../../icons/calendericons/pulse_hires.png';
import clock from './../../../../icons/calendericons/alarm-clock_hires.png';
import cutlery from './../../../../icons/calendericons/cutlery_hires.png';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

import { AuditLogdata } from "../store";
import { useDispatch } from 'react-redux';

const EventCalendar = () => {
   const dispatch = useDispatch();

   // const [allData, setAllData] = useState(null);
   // const navigate = useNavigate();

   // const [clickedDate, setClickedDate] = useState(null);

   const [modalShow, setModalShow] = useState(false);
   const [modalData, setModalData] = useState([0]);
   const [calendarEvents, setCalendarEvents] = useState([]);
   // const [progress, setProgress] = useState(0);
   // const [downloading, setDownloading] = useState(false);
   const [title, setTitle] = useState("")

   const auditLogged = useRef(false);
   const userId = localStorage.id;
   const screenName = 'Calender'
   const updatedDate = new Date()
   const deviceType = 'web'
   const appVersion = ''

   const logAuditData = () => {
      if (!auditLogged.current) {
         const action = 'Calender - View';
         const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
         dispatch(AuditLogdata(auditData));
         auditLogged.current = true; // Mark as logged
      }
   };

   useEffect(() => {
      fetchEvents();
      logAuditData()
   }, []);

   // const userId = localStorage.getItem('id');

   const fetchEvents = async () => {
      try {
         const response = await axios.post(webservice + 'api/getcalenderevents', { userId });
         const entries = response.data.events ? response.data.events[0] : {}; // Adjusting based on your backend response structure

         if (!entries || Object.keys(entries).length === 0) {
            return;
         }
         const events = [];

         Object.keys(entries).forEach(key => {
            if (key !== '_id' && key !== 'userID') {
               const date = key;
               const eventData = entries[date];

               // Create a new event object for each event type if the length is greater than 0
               if (eventData.bloodPressureId?.length > 0) {
                  const event = {
                     title: `Blood Pressure (${eventData.bloodPressureId.length})`,
                     ids: eventData.bloodPressureId,
                     type: 'BloodPressure',
                     start: formatDate(date),
                     allDay: true,
                     className: 'blood-pressure-event'
                  };
                  events.push(event);
               }
               if (eventData.diabetesId?.length > 0) {
                  const event = {
                     title: `Diabetes (${eventData.diabetesId.length})`,
                     ids: eventData.diabetesId,
                     type: 'Diabetes',
                     start: formatDate(date),
                     allDay: true,
                     className: 'diabetes-event'
                  };
                  events.push(event);
               }
               if (eventData.moodDataId?.length > 0) {
                  const event = {
                     title: `Mood (${eventData.moodDataId.length})`,
                     ids: eventData.moodDataId,
                     type: 'Mood',
                     start: formatDate(date),
                     allDay: true,
                     className: 'mood-event'
                  };
                  events.push(event);
               }
               if (eventData.medicalRecordId?.length > 0) {
                  const event = {
                     title: `Document's (${eventData.medicalRecordId.length})`,
                     ids: eventData.medicalRecordId,
                     type: 'Documents',
                     start: formatDate(date),
                     allDay: true,
                     className: 'documents-event'
                  };
                  events.push(event);
               }
            }
         });


         setCalendarEvents(events);
      } catch (error) {
         console.error('Error fetching events:', error);
      }
   };



   // Function to format the date from DD/MM/YYYY to YYYY-MM-DD
   const formatDate = (dateString) => {
      const [day, month, year] = dateString.split('/');
      return `${year}-${month}-${day}`;
   };

   const handleDateClick = async (clickedDate) => {
      const userId = localStorage.getItem("id");

      try {
         const response = await axios.post(webservice + "api/processIds", {
            userId,
            clickedDate,
         });
         const responseData = response.data;

         if (responseData && responseData.length > 0) {
            // setClickedDate(clickedDate); // Set clicked date
            setModalData(responseData); // Store fetched data
            setModalShow(true); // Show modal
         } else {
            Swal.fire("No Data!", "No data available for the selected date.", "info");
         }
      } catch (error) {
         console.error("Error processing IDs:", error);
         Swal.fire("Error!", "There was an error processing IDs.", "error");
      }
   };



   const dateClick = async (dateClickInfo) => {
      const clickedDate = dateClickInfo.dateStr;
      handleDateClick(clickedDate);
   };

   const handleDownload = async (data) => {
      try {
         // setProgress(0);
         // setDownloading(true);
         const ids = data.map(item => item._id)[0];

         const response = await fetch(webservice + 'api/downloadFiles', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ids }),
         });

         const contentLength = response.headers.get('Content-Length');
         const total = contentLength ? parseInt(contentLength, 10) : 0;

         const reader = response.body.getReader();
         const chunks = [];
         let receivedLength = 0;


         while (true) {
            const { done, value } = await reader.read();

            if (done) break;

            chunks.push(value);
            receivedLength += value.length;
            const percentCompleted = total ? (receivedLength / total) * 100 : 0;
            // setProgress(Math.round(percentCompleted));
         }

         const blob = new Blob(chunks);

         const responseData = await blob.text();
         const parsedData = JSON.parse(responseData);
         if (parsedData.length !== 1) {
            const zip = new JSZip();
            for (const item of parsedData) {
               const fileName = item.fileName;
               const base64Data = item.base64;
               zip.file(fileName, base64Data, { base64: true });
            }
            const content = await zip.generateAsync({ type: 'blob' });
            FileSaver.saveAs(content, 'files.zip');
         } else {
            const singleFile = parsedData[0];
            const fileName = singleFile.fileName;
            const base64Data = singleFile.base64;

            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
               byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            FileSaver.saveAs(blob, fileName);
         }

      } catch (error) {
         console.error('Error downloading files:', error);
      }
   };


   const eventClick = async (eventClickInfo) => {
      const clickedDate = eventClickInfo.event.startStr; // Extract date
      const title = eventClickInfo.event.title; // Extract title

      try {
         // Call the API with title and date
         const response = await axios.post(webservice + "api/calenderdatas", {
            title,
            clickedDate,
            userId,
         });
         const nameWithoutNumber = title.replace(/\s?\(\d+\)/, '').trim();
         setTitle(nameWithoutNumber)
         const responseData = response.data;

         // Update modal data and show the modal
         if (responseData.events && responseData.events.length > 0) {
            setModalData(responseData.events); // Store the events array
            setModalShow(true); // Show modal
         } else {
            Swal.fire("No Data!", "No data available for the selected event.", "info");
         }
      } catch (error) {
         console.error("Error processing event click:", error);
         Swal.fire("Error!", "There was an error processing the event.", "error");
      }
   };

   const moodColors = {
      Anger: "#fff2f2",
      Fear: "#fef4eb",
      Sadness: "#e0eefc",
      Happy: "#ebffe9",
      Cheerful: "#ececff",
      Romantic: "#ffb8c3",
      Madness: "#e3e2ae",
      Calm: "#ebffe9",
      NegativeMood: "#fff2f2",
      Anxious: "#fef4eb",
      Irritability: "#fff2f2",
      Disgust: "#fef4eb",
   };

   const borderColor = {
      Anger: "#f6d2d2",
      Fear: "#f8c76d",
      Sadness: "#306eff",
      Happy: "#bffab4",
      Cheerful: "#b8b8fc",
      Romantic: "#ff8096",
      Madness: "#b0b058",
      Calm: "#bffab4",
      NegativeMood: "#f6d2d2",
      Anxious: "#fae2b5",
      Irritability: "#f6d2d2",
      Disgust: "#fae2b5",
   };
   const style = {
      width: '100%',
      height: '100%'
   }
   const moodOptions = [
      { value: "Anger", emoji: <img src={angry} alt="Angry Emoji" style={style} /> },
      { value: "Fear", emoji: <img src={Fear} alt="Fear Emoji" style={style} /> },
      { value: "Sadness", emoji: <img src={Sadness} alt="Sadness Emoji" style={style} /> },
      { value: "Happy", emoji: <img src={Happy} alt="Happy Emoji" style={style} /> },
      { value: "Cheerful", emoji: <img src={Cheerful} alt="Cheerful Emoji" style={style} /> },
      { value: "Romantic", emoji: <img src={love} alt="Romantic Emoji" style={style} /> },
      { value: "Madness", emoji: <img src={Madness} alt="Madness Emoji" style={style} /> },
      { value: "Calm", emoji: <img src={Calm} alt="Calm Emoji" style={style} /> },
      { value: "Negative", emoji: <img src={Negative} alt="Negative Emoji" style={style} /> },
      { value: "Anxious", emoji: <img src={Anxious} alt="Anxious Emoji" style={style} /> },
      { value: "Irritability", emoji: <img src={Irritability} alt="Irritability Emoji" style={style} /> },
      { value: "Disgust", emoji: <img src={Disgust} alt="Disgust Emoji" style={style} /> }
   ];


   function getBackgroundColor(systolic, diastolic) {
      if (systolic >= 130 || diastolic >= 80) {
         return "#ff8d24"; // High blood pressure color
      } else if (systolic < 90 || diastolic <= 60) {
         return "#306eff"; // Low blood pressure color
      } else {
         return "green"; // Normal range color
      }
   }

   function getTextColor(systolic, diastolic) {
      if (systolic >= 130 || diastolic >= 80) {
         return "#fff"; // High blood pressure text color
      } else if (systolic < 90 || diastolic <= 60) {
         return "#fff"; // Low blood pressure text color
      } else {
         return "#fff"; // Normal range text color
      }
   }

   function getText(systolic, diastolic) {
      if (systolic < 90 || diastolic <= 60) {
         return "low"; // Low blood pressure
      } else if (systolic >= 130 || diastolic >= 80) {
         return "high"; // High blood pressure
      } else {
         return "normal"; // Normal blood pressure
      }
   }

   function getBorderColor(systolic, diastolic) {
      if (systolic >= 130 || diastolic >= 80) {
         return "#ff8d24"; // High blood pressure border color
      } else if (systolic < 90 || diastolic <= 60) {
         return "#306eff"; // Low blood pressure border color
      } else {
         return "green"; // Normal range border color
      }
   }
   function getLighterBackgroundColor(systolic, diastolic) {
      if (systolic >= 130 || diastolic >= 80) {
         return "#fff4eb"; // Very light peachy shade for high blood pressure
      } else if (systolic < 90 || diastolic <= 60) {
         return "#e0eefc"; // Very light blue for low blood pressure
      } else {
         return "#eafaf1"; // Very light version of #d6ecff for normal range
      }
   }


   const handleClose = () => setModalShow(false);

   return (
      <div className="animated fadeIn demo-app">
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Body>
                     <div
                        style={{ overflowX: "scroll", maxWidth: "100%" }}
                        className="demo-app-calendar"
                        id="mycalendartest"
                     >
                        <FullCalendar
                           initialView="dayGridMonth"
                           headerToolbar={{
                              start: "prev,next",
                              center: "title",
                              end: "",
                           }}
                           plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                           weekends={true}
                           events={calendarEvents}
                           eventClick={eventClick}
                           eventDidMount={(info) => {
                              info.el.style.cursor = "pointer";
                           }}
                           contentHeight="auto"
                           aspectRatio={1.35}
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         {/* Modal to display fetched data */}
         {/* <Modal show={modalShow} onHide={() => setModalShow(false)}>
            <Modal.Header closeButton>
               <Modal.Title>Event Details{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {modalData && modalData.length > 0 ? (
                  modalData.map((item, index) => (
                     <div key={item._id} style={{ marginBottom: "10px" }}>
                      
                        <p><strong>Diastolic:</strong> {item.diastolic}</p>
                        <p><strong>Systolic:</strong> {item.systolic}</p>
                        <p><strong>Pulse:</strong> {item.pulse}</p>
                        <p><strong>Test Date:</strong> {new Date(item.testDate).toLocaleString()}</p>
                      
                     </div>
                  ))
               ) : (
                  <p>No data available to display.</p>
               )} 

               {downloading && (
                  <div className="progress-overlay">
                     <div className="loaderBar" style={{ width: `${progress}%` }}></div>
                     <div className="progress-text">{progress} %</div>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setModalShow(false)}>
                  Close
               </Button>
            </Modal.Footer>
         </Modal> */}

         <Modal show={modalShow} onHide={() => setModalShow(false)}>
            <Modal.Header closeButton>
               <Modal.Title >{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">

               {modalData && modalData.length > 0 ? (
                  title === "Blood Pressure" ? (
                     modalData.map((item) => (


                        <div
                           className="calenderdatacard"
                           style={{
                              padding: "4.3%", border: `2px solid ${getBorderColor(item.systolic, item.diastolic)}`,
                              background: getLighterBackgroundColor(item.systolic, item.diastolic),
                           }}
                           key={item._id}
                        >
                           <div>
                              <div
                                 className="bp_overallresult"
                                 style={{
                                    backgroundColor: getBackgroundColor(item.systolic, item.diastolic),
                                    color: getTextColor(item.systolic, item.diastolic),
                                 }}
                              >
                                 {getText(item.systolic, item.diastolic)}
                              </div>
                           </div>
                           <div className="diasSys">
                              <div style={{ borderRight: `4px solid ${getBorderColor(item.systolic, item.diastolic)}`, borderRadius: "10px", width: "50%" }}>
                                 <div className="diasSys_lable">Diastolic:</div>
                                 <div className="diasSys_value">{item.diastolic}</div>
                              </div>
                              <div
                                 style={{
                                    width: "50%",
                                    // borderLeft: `4px solid ${getBorderColor(item.systolic, item.diastolic)}`,
                                    borderRight: `4px solid ${getBorderColor(item.systolic, item.diastolic)}`,
                                    paddingLeft: "3%",
                                    borderRadius: "10px",
                                 }}
                              >
                                 <div className="diasSys_lable">Systolic:</div>
                                 <div className="diasSys_value">{item.systolic}</div>
                              </div>
                           </div>
                           <div className="pulse_time" >
                              <div style={{ borderRadius: "10px", width: "50%" }}>
                                 <div className="pulse_time_lable" >Pulse:</div>
                                 <div className="pulse_time_value">{item.pulse}</div>
                              </div>
                              <div
                                 style={{
                                    width: "50%",
                                    // borderLeft: `4px solid ${getBorderColor(item.systolic, item.diastolic)}`,
                                    paddingLeft: "3%",
                                    borderRadius: "10px",
                                 }}
                              >
                                 <div className="pulse_time_lable">Test Date:</div>
                                 <div style={{ alignItems: "center", display: "flex", padding: "4% 0", }}>
                                    <div style={{ color: "#1e1e1e", fontWeight: "600", fontSize: "14px" }}>
                                       {moment(item.testDate).format('MMM DD, h:m a')}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     ))
                  ) : title === "Diabetes" ? (
                     modalData.map((item) => (
                        <div className="calenderdatacard" key={item._id}>

                           <div>
                              <div className='cal_sugar_lvl'>
                                 <div className='cal_sugarlvl_icon'><img src={bloodwave} alt='sugar level' /></div>
                                 <div style={{ margin: "3% 0" }}>
                                    <div style={{ fontWeight: "600", color: "#888", fontSize: "18px" }}>Sugar Level</div>
                                    <div style={{ display: "flex", alignItems: "flex-end" }}><div style={{ color: "#04AA6D", fontSize: "35px", fontWeight: "700" }}>{item.sugarLevel}</div> <div style={{ marginBottom: "8px", marginLeft: "4px", color: "#888" }}>mg/dl</div></div>
                                 </div>
                              </div>
                              <div className='' style={{ marginTop: "18px ", display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                 <div className='calender_clock_icon'><img src={clock} alt='time' /></div>
                                 <div>
                                    <div style={{ color: "#000", fontWeight: "600" }}>{item.sugarType}</div>
                                 </div>
                              </div>
                              <div className='' style={{ display: "flex", marginBottom: "10px" }}>
                                 <div className='calender_meal'><img src={cutlery} alt='meals' /></div>
                                 <div>
                                    <div style={{ color: "#000", fontWeight: "600" }}>{item.meal}</div>
                                 </div>
                              </div>
                              <div >
                                 <p style={{ marginLeft: "4px", fontWeight: "500" }}>Recorded on {moment(item.testDate).format('MMM DD,h:m a')}</p>
                              </div>
                           </div>

                           {/* <p><strong>Sugar Level:</strong> {item.sugarLevel}</p>
                           <p><strong>Sugar Type:</strong> {item.sugarType}</p>
                           <p><strong>Meal:</strong> {item.meal}</p>
                           <p><strong>Test Date:</strong> {new Date(item.testDate).toLocaleString()}</p> */}
                        </div>

                     ))
                  ) : title === "Document's" ? (
                     modalData.map((item) => (
                      

                         
                           <div className=" document-container" key={item._id} style={{marginBottom:"5%"}}>
                              <div className="document-header">
                                 <h2>Document Name : {item.documentName}</h2>
                              </div>

                              <div className="document-body">
                                 <div className="document-flex">
                                 <div className="field">
                                    <p className="field-label">Document Type</p>
                                    <p className="field-value">{item.documentType}</p>
                                 </div>

                                    <div onClick={() => handleDownload([item])} className="btn btn-link">
                                       <i className="fas fa-download"></i>
                                    </div>
                                 </div>


                                 <div className="field">
                                    <p className="field-label">Test Date</p>
                                    <p className="field-value"> {moment(item.testDate).format('MMM DD,h:m a')}</p>
                                 </div>
                              </div>
                           </div>
                        

                     ))
                  ) : title === "Mood" ? (
                     modalData.map((item) => (

                        <div class="calenderdatacard" style={{ backgroundColor: moodColors[item.mood] || "#f0f0f0", border: `1px solid ${borderColor[item.mood] || "#ccc"}` }}>

                           <p className='mooddate'>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                 {/* Find the emoji corresponding to item.mood */}
                                 <div style={{ width: "55px", height: "55px", }}>{moodOptions.find(option => option.value === item.mood)?.emoji}</div>
                                 <div style={{ color: "#000", fontSize: "22px", fontWeight: "800" }}>
                                    {/* Display the mood text */}
                                    {item.mood}
                                 </div>
                              </div>
                              <div
                                 style={{
                                    border: `1px solid ${borderColor[item.mood] || "#ccc"}`,
                                    padding: "1px 10px",
                                    borderRadius: "20px",
                                    color: "#303030",
                                    fontWeight: "600"
                                 }}
                              >
                                 {/* Display the formatted date */}
                                 {new Date(item.createdDate).toLocaleString()}
                              </div>
                           </p>
                           <p className='resonvalue' style={{ borderBottom: `1px solid ${borderColor[item.mood] || "#ccc"}` }}><div style={{ color: "#535353", fontWeight: "600" }}>Reason:</div> <div style={{ color: "#000", fontWeight: "600", fontSize: "20px", }}>{item.reason}</div></p>
                           <p className='notevalue'><div style={{ color: "#535353", fontWeight: "600" }}>Note:</div> <div style={{ color: "#000", fontWeight: "600", fontSize: "14px" }}>{item.note || "You haven't given any reason for this mood."}</div></p>

                        </div>

                     ))
                  ) : (
                     <p>Unknown event type</p>
                  )
               ) : (
                  <p>No data available to display.</p>
               )}

               {/* {downloading && (
                  <div className="progress-overlay">
                     <div className="loaderBar" style={{ width: `${progress}%` }}></div>
                     <div className="progress-text">{progress} %</div>
                  </div>
               )} */}
            </Modal.Body>

            <Modal.Footer>
               {/* <Button variant="secondary" onClick={() => setModalShow(false)}>
                  Close
               </Button> */}
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default EventCalendar;
