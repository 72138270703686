import React, { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getAllData, getlanguage, AuditLogdata } from "./store/index"
import { useDispatch } from "react-redux";
import "../../../css/custom.css"
import img from '../../../images/First-Aid-BG.png'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from "../../breadcrumbs";

const FirstAid = () => {
  const [AllData, setAllData] = useState([]);
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const auditLogged = useRef(false);


  const userId = localStorage.id;
  const screenName = 'First-Aid'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''


  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'First-Aid - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  // const logAuditData = () => {
  //   const action = 'First-Aid - View'
  //   const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
  //   dispatch(AuditLogdata(auditData));
  // };
  



  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        const lang = res.payload.language;
        dispatch(getAllData(lang)).then((response) => {
          logAuditData()
          setAllData(response.payload.data);
        })
      });
    }
  }, [dispatch]);

  return (
    <Fragment>
      <div className="main_table">
      <h1 className="container_heading">First - Aid</h1>
      </div>
      {/* <BreadCrumbs title={t('First - Aid')} data={[{ title: t('First - Aid') }]} /> */}
      <div className="first-aid-container"  >
      {/* <div className="first-aid-container" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 'auto', width: '100%', paddingTop: '10px' }}> */}
        <div >
          {/* <h3 style={{ textAlign: "center", fontWeight: "bold", color: '#000' }}>{t('First - Aid')}</h3> */}
          <br />
          <div className="row ">
            {AllData.map((item, index) => (
              <div className="mood-responsive-card" key={index}>
                {/* <div className="card card-zoom" style={{ boxShadow: "6px 20px 23px 0px rgb(124 50 181 / 58%)" }}> */}
                <div
                  className="card card-zoom"
                  style={{
                    boxShadow: " 0px 0px 10px 0px rgb(153, 201, 144)",
                  }}
                >
                  <Link to={`/first-aid-tips/${item.RefId}`} >
                    <div style={{ padding: '5%' }} className="">
                      <div className="media mb-3">
                        <img
                          src={`${item.img}`}
                          alt=""
                          className="rounded me-3"
                          // width={86}
                          style={{ maxWidth: '100px', maxHeight: '100px' }}
                        />
                        <div className="media-body">
                          <h4 className="fs-20 font-w600" style={{ marginTop: '10%' }}>
                            {item.name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FirstAid;
