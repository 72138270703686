import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AesDecrypt } from "../../../services/crypto";
import { getDonationHistory, getReceivedHistory } from "./store";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { Button, Label } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import img from '../../../images/EmptyFile.svg'
const TrackHistory = () => {
    // const { id } = useParams();
    const [donationData, setDonationData] = useState([]);
    const [receivedData, setReceivedData] = useState([]);
    const [activeTab, setActiveTab] = useState('donation');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userId = localStorage.id; // Adjust this if userId is obtained differently

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (activeTab === 'donation') {
                    const response = await dispatch(getDonationHistory(userId));
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        setDonationData(Array.isArray(decryptedData) ? decryptedData : []);
                    }
                } else {
                    const response = await dispatch(getReceivedHistory(userId));
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        setReceivedData(Array.isArray(decryptedData) ? decryptedData : []);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [activeTab, userId, dispatch]);

    return (
        <>
            <div>
                <div className="blooddonation-topmenu">
                <div className="bloodbackbutton" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={"/blood"}>
                        <i style={{ color: '#007bff',  marginBottom: '0%' }} className="fas fa-arrow-alt-circle-left"></i>
                    </Link>
                </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '50px' }}> */}

                    <div>
                        &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;       </div>
                </div>

                <div className="blooddonation_navbtns">
        <div className="blood_btns"  >
         <Link to={"/createpost"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Your Request")}
            </Button>
          </Link>
          <Link to={"/trackHistory"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Track History")}
            </Button>
          </Link>
          <Link to={"/acceptedList"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Accepted List")}
            </Button>
          </Link>
          <Link to={"/bloodNotification"}>
            <Button className="blood_nav_btns" variant="info">
              <i id="icon-dark" className="notification-icon">&#128276; {t("Notification")}</i>
            </Button>
          </Link>
         </div>
        </div>

                <div className="auth-switch-container">
                    <div className={`auth-switch ${activeTab === 'donation' ? 'active-donation' : 'active-received'}`}>
                        <button
                            onClick={() => setActiveTab('donation')}
                            className={`text-black auth-switch-option ${activeTab === 'received' ? 'selected' : ''}`}
                        >
                            Donation
                        </button>
                        <button
                            onClick={() => setActiveTab('received')}
                            className={`text-black auth-switch-option ${activeTab === 'received' ? 'selected' : ''}`}
                        >
                            Received
                        </button>
                        <div className="auth-switch-slider"></div>
                    </div>
                </div>



                <div className="notification-cards">
                    {activeTab === 'donation' ? (
                        donationData.length > 0 ? (
                            donationData.map((receiver) => (
                                <Card style={{ marginTop: '40px' }} key={receiver.createdDate} className="blood-request-card">

                                    <CardHeader style={{ display: 'flex', maxHeight: '40px', padding: '10px' }}>
                                        <div>
                                            <p className="blood-group-blood">{receiver.bloodGroup}</p>
                                        </div>
                                        <div>
                                            <Label className="blood-request-card-date">
                                                {moment(receiver.createdDate).format('MM/DD/YYYY, hh:mm A')}
                                            </Label>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="blood-request-card-body">
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('Receiver Name')} : {receiver.receiverName}</strong>
                                            </h4>
                                        </Label>
                                        <br></br>
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('District')} : {receiver.district}</strong>
                                            </h4>
                                        </Label>
                                        <br></br>
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('Hospital Name')} : {receiver.hospitalName}</strong>
                                            </h4>
                                        </Label>
                                    </CardBody>
                                </Card>
                            ))
                        ) : (
                            // <div className="d-flex justify-content-center">
                            //     <img src={img} alt="No Data" style={{ position: 'relative', top: '100px', height: "300px" }} />
                            // </div>
                            <div
                                className="no-data"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "auto",
                                    textAlign: "center",
                                    padding: "2rem",
                                    // backgroundImage: `url(${img})`, // Replace img with the actual path to your image
                                    backgroundSize: "contain", // Makes the image smaller
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    opacity: 0.8,
                                    borderRadius: "12px",
                                    margin: "auto",
                                    marginTop: "2rem",
                                    maxWidth: "800px",
                                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                                    color: "#ffffff",
                                }}

                            >
                                {/* Title */}
                                <h2
                                    style={{
                                        color: "rgb(76, 175, 80)",
                                        marginBottom: "1rem",
                                        fontSize: "2rem",
                                        fontWeight: "bold",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                    }}
                                >
                                    No Donation History Found
                                </h2>

                                {/* Paragraphs */}
                                <p
                                    style={{
                                        fontSize: "1.2rem",
                                        lineHeight: "1.8",
                                        color: "black",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    Every donation you make is a life saved. Your generosity and kindness can
                                    bring hope to those in need.
                                </p>
                                <p
                                    style={{
                                        fontSize: "1.2rem",
                                        lineHeight: "1.8",
                                        color: "black",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                        marginBottom: "1.5rem",
                                    }}
                                >
                                    Start your journey as a donor today and make a positive impact in someone's
                                    life.
                                </p>


                            </div>





                        )
                    ) : (
                        receivedData.length > 0 ? (
                            receivedData.map((receiver) => (
                                <Card style={{ marginTop: '20px' }} key={receiver._id} className="blood-request-card">
                                    <CardHeader style={{ display: 'flex', maxHeight: '40px', padding: '10px' }}>
                                        <div>
                                            <p className="blood-group-blood">{receiver.bloodGroup}</p>
                                        </div>
                                        <div>
                                            <Label className="blood-request-card-date">
                                                {moment(receiver.createdDate).format('MM/DD/YYYY, hh:mm A')}
                                            </Label>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="blood-request-card-body">
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('Donor Name')} : {receiver.donorName}</strong>
                                            </h4>
                                        </Label>
                                        <br></br>
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('District')} : {receiver.district}</strong>
                                            </h4>
                                        </Label>
                                        <Label className="blood-request-card-title">
                                            <h4>
                                                <strong>{t('Hospital Name')} : {receiver.hospitalName}</strong>
                                            </h4>
                                        </Label>
                                    </CardBody>
                                </Card>
                            ))
                        ) : (
                            // <div className="d-flex justify-content-center">
                            //     <img src={img} alt="No Data" style={{ position: 'relative', top: '100px', height: "300px" }} />
                            // </div>
                            <div
                                className="no-data"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "auto",
                                    textAlign: "center",
                                    padding: "2rem",
                                    // backgroundImage: `url(${img})`, // Replace img with the actual path to your image
                                    backgroundSize: "contain", // Makes the image smaller
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    opacity: 0.8,
                                    borderRadius: "12px",
                                    margin: "auto",
                                    marginTop: "2rem",
                                    maxWidth: "800px",
                                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                                    color: "#ffffff",
                                }}

                            >
                                {/* Title */}
                                <h2
                                    style={{
                                        color: "rgb(76, 175, 80)",
                                        marginBottom: "1rem",
                                        fontSize: "2rem",
                                        fontWeight: "bold",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                    }}
                                >
                                    No Received History Found
                                </h2>

                                {/* Paragraphs */}
                                <p
                                    style={{
                                        fontSize: "1.2rem",
                                        lineHeight: "1.8",
                                        color: "black",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    We are here to support you in times of need.
                                    If you ever require blood, our community is ready to help.
                                </p>
                                <p
                                    style={{
                                        fontSize: "1.2rem",
                                        lineHeight: "1.8",
                                        color: "black",
                                        // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                                        marginBottom: "1.5rem",
                                    }}
                                >
                                    Feel free to create a blood request and let us assist you
                                    in connecting with willing donors in your area.
                                </p>


                            </div>

                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default TrackHistory;
