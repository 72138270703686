import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "react-datepicker/dist/react-datepicker.css";
import webservice from "../../../services/webservice";
import { useTranslation } from "react-i18next";
import { getUserdata, AuditLogdata } from './store'
import Select from "react-select";
// import Switchuser from "./Switchuser";
import { AesDecrypt } from "../../../services/crypto";
import BreadCrumbs from "../../breadcrumbs";
import { RadialGauge } from "react-canvas-gauges";
import CheckBmi from "./Checkbmi";
import ChatBot from './ChatBot' 

const BMI = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userId = localStorage.id;
  const [age, setAge] = useState(24);
  const [weight, setWeight] = useState("0"); // kg
  const [height, setHeight] = useState("0"); // ft
  const [bmi, setBMI] = useState(0);
  const [category, setCategory] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [targetBmi, setTargetBmi] = useState(0);
  const screenName = 'BMI'
  const updateDate = new Date()
  const appType = 'web'
  const version = ''

  // const handleAuditLog = () => {
  //   const Action = 'BMI - View';

  //   const auditData = [{ userId, Screen, Action, updateDate, appType, version }];
  //   dispatch(AuditLogdata(auditData));
  // };
  const auditLogged = useRef(false); // Track if audit logging has been done

  const logAuditData = () => {
    if (!auditLogged.current) {
      const Action = 'BMI - View';
      const auditData = [{ userId, screenName, Action, updateDate, appType, version }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };


  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) return; // Exit if userId is not available

      try {
        const res = await dispatch(getUserdata(userId));
        const Data = res.payload.data;
        if (Data) {
          setHeight(Data.height);
          setWeight(Data.weight);
        }
      } catch (error) {
        console.error('Error during fetching user data:', error);
      }
    };

    fetchUserData();
    logAuditData(); // Log audit data only once
  }, [dispatch, userId]);

  const updateCategory = (bmi) => {
    if (bmi < 18.5) setCategory("Underweight");
    else if (bmi < 25) setCategory("Normal weight");
    else if (bmi < 30) setCategory("Overweight");
    else setCategory("Obesity");
  };


  useEffect(() => {
    if (weight && height) {
      const newBmi = calculateBMI(weight, height);
      setTargetBmi(newBmi);
      updateBmiGradually(newBmi);
      // Determine the BMI category
      const category =
        newBmi < 18.5
          ? "Underweight"
          : newBmi < 25
            ? "Normal"
            : newBmi < 30
              ? "Overweight"
              : "Obese";
      setCategory(category);
    }
  }, [weight, height]);


  const updateBmiGradually = (newBmi) => {
    const step = 0.1; // The amount by which the needle increases each step
    const interval = 50; // Interval between steps in milliseconds

    const intervalId = setInterval(() => {
      setBMI((prevBmi) => {
        if (prevBmi < newBmi) {
          return Math.min(prevBmi + step, newBmi); // Increase BMI gradually
        } else {
          clearInterval(intervalId); // Stop animation when target is reached
          return prevBmi;
        }
      });
    }, interval);
  };

  const handleUpdate = async () => {
    setErrorMessage("");
    setHeightError("");
    setWeightError("");

    if (height === undefined || height === null || height === '' || isNaN(height) || height <= 0 || height === 0) {
      setHeightError('Height is required')
      setTimeout(() => {
        setHeightError('');
      }, 2800);
    }

    if (weight === undefined || weight === null || weight === '' || isNaN(weight) || weight <= 0 || weight === 0) {
      setWeightError('Weight is required')
      setTimeout(() => {
        setWeightError('');
      }, 2800);
    }
    const Action = 'BMI - Update'
    const auditData = [{ userId, Screen, Action, updateDate, appType, version }]


    if (weight === "" && height === "") {
      setErrorMessage("Weight and Height is required")
      setTimeout(() => {
        setErrorMessage('');
      }, 2800);
    }


    try {
      const userId = localStorage.getItem("id");
      const response = await fetch(webservice + "api/updateHeightWeight", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, height, weight }),
      });


      const data = await response.json();
      dispatch(AuditLogdata(auditData))
      setSuccessMessage(t("Successfully updated height and weight."));

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error updating user data:", error);
      setErrorMessage(t("Error updating user data"));
    }
  };


  const calculateBMI = () => {
    const heightInMeters = height / 100; // Convert cm to meters
    const bmiValue = weight / (heightInMeters * heightInMeters);
    setBMI(bmiValue.toFixed(2));

    // Determine BMI Category
    if (bmiValue < 18.5) {
      setCategory("Under Weight");
    } else if (bmiValue >= 18.5 && bmiValue < 25) {
      setCategory("Normal");
    } else if (bmiValue >= 25 && bmiValue < 30) {
      setCategory("Over Weight");
    } else if (bmiValue >= 30 && bmiValue < 35) {
      setCategory("Obese Class I");
    } else if (bmiValue >= 35 && bmiValue < 40) {
      setCategory("Obese Class II");
    } else {
      setCategory("Obese Class III");
    }
  };

  return (
    <Fragment>
      <div className="row" style={{
              height: "75vh",
      }}>
        <div className="col-xl-4 bmi_update">
          <Card
            style={{
              height: "100%",
              maxWidth: "100%",
              borderRadius: "15px",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Header
              style={{
                backgroundColor: "#0099cc",
                color: "#ffffff",
                padding: "15px 20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",

              }}
            >
              <div>
                <Card.Title style={{
                  fontSize: "18px", color: "#fff",
                  fontWeight: "700",

                }}>

                  {t("Update BMI")}
                </Card.Title>
              </div>
              <div>
                <CheckBmi />
              </div>
            </Card.Header>
            <Card.Body
              style={{
                padding: "20px",
              }}
            >
              {/* RadialGauge Section */}
              <div className="gauge_container">

                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "170px",
                    maxWidth: "300px",
                    maxHeight: "300px",
                    margin: "0 auto",
                    position: "relative",
                  }}
                >
                  <div className="bmistatus">
                    {category}
                  </div>
                  {/* Radial Gauge Component */}
                  <RadialGauge
                    units="BMI"
                    title="Body Mass Index"
                    value={isNaN(bmi) || !isFinite(bmi) ? 0 : bmi}
                    minValue={15}
                    maxValue={40}
                    majorTicks={["15", "20", "25", "30", "35", "40"]}
                    highlights={[
                      { from: 15, to: 20, color: "#76c7c0" },
                      { from: 20, to: 25, color: "#28a745" },
                      { from: 25, to: 30, color: "#ffc107" },
                      { from: 30, to: 35, color: "#fd7e14" },
                      { from: 35, to: 40, color: "#dc3545" },
                    ]}
                    colorPlate="#eef3f7"
                    borders={false}
                    needleType="arrow"
                    needleWidth={2}
                    animationDuration={10000} // 10 seconds for the entire animation
                    animationRule="linear"
                    valueBox={true}
                  />
                  <h2
                    style={{
                      width: "180px",
                      fontSize: "100%",
                      color: "#888",
                      margin: "10px auto",
                      padding: "0px",
                    }}
                  >

                    {!isNaN(bmi) && isFinite(bmi) && weight && height ? (
                      <span>
                        <span style={{ color: "#555", fontWeight: "600" }}>
                          Your BMI result is
                        </span>
                        <span style={{ color: "blue", fontWeight: "600" }}> {bmi}</span>
                      </span>
                    ) : null}

                  </h2>
                </div>

                {/* Input Fields Section */}
                <div className="gaugevalues">
                  <div
                    style={{
                      padding: "1px 10px",
                      borderRadius: "12px",
                      maxWidth: "100%",
                      margin: "0 auto",
                    }}
                  >

                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                        margin: "0 auto",
                        maxWidth: "600px",
                        padding: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {/* Weight Input */}
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          marginBottom: "15px",
                        }}
                      >
                        <label
                          htmlFor="weight"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#555",
                          }}
                        >
                          Weight (kg)
                        </label>
                        <input
                          type="text"
                          id="weight"
                          value={weight}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/\D/g, ""); // Allow only numeric input
                            if (numericValue === "" || (parseInt(numericValue, 0) > 0 && parseInt(numericValue, 10) <= 640)) {
                              setWeight(numericValue);
                              calculateBMI();
                            }
                          }}
                          placeholder="Enter weight"
                          style={{
                            padding: "1px",
                            fontSize: "18px",
                            borderRadius: "10px",
                            border: "1px solid #ccc",
                            width: "100%",
                            textAlign: "center",
                          }}


                        />

                      </div>

                      {/* Height Input */}
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          marginBottom: "15px",
                        }}
                      >
                        <label
                          htmlFor="height"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#555",
                          }}
                        >
                          Height (cm)
                        </label>
                        <input
                          type="text"
                          id="height"
                          value={height}
                          min="8"
                          max="300"
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/\D/g, ""); // Allow only numbers
                            if (numericValue === "" || (parseInt(numericValue, 0) > 0 && parseInt(numericValue, 10) <= 300)) {
                              setHeight(numericValue); // Update the state with the new value (or empty string if deleted)
                              calculateBMI(); // Recalculate BMI if necessary
                            }
                          }}
                          placeholder="Enter height"
                          style={{
                            padding: "2px",
                            fontSize: "18px",
                            borderRadius: "10px",
                            border: "1px solid #ccc",
                            width: "100%",
                            textAlign: "center",
                          }}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* button */}

              <div style={{ textAlign: "center", marginTop: "-4%", marginBottom: "2%" }}>
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMessage || heightError || weightError}
                </span>
              </div>
              <div
                className="bmiupdate_btn"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() => {
                    calculateBMI();
                    handleUpdate();
                  }}
                  style={{
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    borderRadius: "10px",
                    padding: "7px 18px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = "#218838")}
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#28a745")}
                >
                  {t("Update")}
                </button>
              </div>

            </Card.Body>

          </Card>

        </div>
        <div className="col-xl-8 text-center" >
          {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "68vh", border:"1px solid #ccc", borderRadius:"20px" }}>
            <h3>Health tip based on your height and weight</h3>
            <div className="coming-soon">
              coming soon<span className="comingsoon_dots"></span><span className="comingsoon_dots"></span><span className="comingsoon_dots"></span>
            </div>
          </div> */}
          <ChatBot />
        </div>

      </div>
    </Fragment>
  );
};
export default BMI;
