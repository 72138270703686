import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Logout } from '../../../store/actions/AuthActions';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';
import { useTranslation } from 'react-i18next';

function LogoutPage({ isAuthenticated }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

function genRandonString(length) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charLength = chars.length;
    var result = '';
    for (var i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
    }

    function confirmLogout() {
    const web_token= genRandonString(16);
    const id =localStorage.id    

        Swal.fire({
            title: t('Confirm Logout'),
            text: t('Are you sure you want to logout?'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes'),
            cancelButtonText: t('Cancel'),
            reverseButtons: true
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(Logout(navigate ,web_token, id));
                    localStorage.clear();
                }
            });
    }

    return (
        <>
            <button className="dropdown-item ai-icon" onClick={confirmLogout}>
                {/* <svg
                    id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                    className="text-black" width={18} height={18} viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg> */}
                <span  style={{color:"red"}}>{t('Logout')} </span>
            </button>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default connect(mapStateToProps)(LogoutPage);
