import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, Modal, Form, FormLabel } from "react-bootstrap";
import ReactSelect from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import webservice from "../../../services/webservice";
import {  bloodreceiverdatas, getAcceptedBloodPost, userAccesptPost } from './store/index'; // Ensure this is the correct path to the action
import { AesDecrypt, AesEncrypt } from "../../../services/crypto";
// import img from '../../../images/EmptyFile.svg'
import moment from "moment";
import CreatePost from "./CreatePost";
import AcceptedList from "./acceptedList";
import TrackHistory from "./trackHistory";
import BloodNotification from "./bloodNotification";
import Switch from "react-switch";

// import './blood donation.css'
const Blood = () => {
  // const [userData, setUserData] = useState([]); // Ensure initial state is an array
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [donateModal, setDonateModal] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [talukOptions, setTalukOptions] = useState([]);
  const [stateData, setStateData] = useState({});
  const userId = localStorage.id;
  const [isToggled, setIsToggled] = useState(false);
  const [isdonor, setIsdonor] = useState('');
  const [activeComponent, setActiveComponant] = useState('');
  const [bloodReceivers, setBloodReceivers] = useState([]);
  const [acceptedPostList, setAcceptedPostList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allPostsFetched, setAllPostsFetched] = useState(false); // Track if no more posts are available
  const [reload, setReload] = useState(false); // Dummy state

  const loadMorePosts = () => {
    if (allPostsFetched || loading) {
      return;
    }

    setLoading(true);

    dispatch(bloodreceiverdatas(page + 1))
      .then((response) => {
        if (response.payload.response === "S") {
          const decryptedData = AesDecrypt(response.payload.data);

          if (decryptedData.length === 0) {
            setAllPostsFetched(true);
            setLoading(false);

          } else {
            setBloodReceivers((prevReceivers) => {
              const newReceivers = decryptedData.filter((newPost) => {
                const isDuplicate = prevReceivers.some(
                  (existingPost) => existingPost.postId === newPost.postId
                );
                if (isDuplicate) {
                }
                return !isDuplicate;
              });
              return [...prevReceivers, ...newReceivers];
            });
            setPage(page + 1)
          }
        } else {
          setLoading(false)
          setAllPostsFetched(true);
          console.error("Failed to fetch posts:", response.payload.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        // setVisiblePosts((prev) => prev + 3);
        setLoading(false);
      });

  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 1 && !loading && !allPostsFetched) {
      loadMorePosts();
    }
  };


  const navigate = useNavigate();

  useEffect(() => {
    fetchInitialState();
  }, []);

  const fetchInitialState = async () => {
    try {
      const maindata = {
        userId: userId,
      }
      // const userId = localStorage.id; // Assuming localStorage.id holds the userId
      const Encparams = AesEncrypt(maindata)
      const response = await axios.post(`${webservice}api/getWillingStatus`, {
        Encparams: Encparams
      });
      if (response.data.response === 'F') {
        setIsdonor('NoDonor')
      }
      if (response.data.response === 'S') {
        const decryptedResponce = AesDecrypt(response.data.data)
        const initialWillingState = decryptedResponce.willing;
        setIsToggled(initialWillingState);
      } else {
        setDonateModal(false)
      }

    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };

  const handleToggle = async () => {
    if (isdonor !== 'NoDonor') {
      setIsToggled(!isToggled);
    }
    const willing = !isToggled;
    const Encparams = AesEncrypt({ willing, userId })
    try {
      const response = await axios.post(`${webservice}api/updateWillingStatus`, {
        Encparams: Encparams
      });
      if (response.data.response === 'F') {
        setDonateModal(true);
      } else {
        Swal.fire({
          icon: 'success',
          title: 'You’re Ready to Donate!',
          text: 'Thank you for making yourself available to donate. Your willingness can make a real difference!',
          confirmButtonText: 'Got it',
        });

      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
    // Show SweetAlert based on toggle state
    if (isToggled) {
      Swal.fire({
        icon: 'info',
        title: 'Thank You for Letting Us Know!',
        text: 'You’ve turned off your availability to donate. We’ll stay in touch and welcome you back whenever you’re ready.',
        confirmButtonText: 'Understood',
      });

    }
  };


  const handleStateChange = async (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setDistrictOptions([]);
    setTalukOptions([]);
    try {
      const response = await axios.post(webservice + "api/get-districts-taluks", { state: selectedOption.value });
      const data = response.data.districtsAndTaluks;
      setStateData(data);
      const districts = Object.keys(data).map((district) => ({ value: district, label: district }));
      setDistrictOptions(districts);
    } catch (error) {
      console.error("Error fetching districts and taluks:", error);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedDistrict(null);
      setTalukOptions([]);
      return;
    }

    setSelectedDistrict(selectedOption);
    if (selectedState && selectedOption) {
      const taluks = stateData[selectedOption.value].map((taluk) => ({ value: taluk, label: taluk }));
      setTalukOptions(taluks);
      setSelectedTaluk("");
    }
  };

  const handleSubmit = async () => {
    if (!selectedState || !selectedDistrict || !selectedTaluk) {
      return;
    }
    const postData = {
      state: selectedState.value,
      district: selectedDistrict.value,
      subDistrict: selectedTaluk.value,
      address: address,
      userId: userId,
    };
    const Encparams = AesEncrypt(postData);
    try {
      const response = await axios.post(`${webservice}api/addDonorDetails`, { Encparams });
      setDonateModal(false);
      setSelectedState(null);
      setSelectedDistrict(null);
      setSelectedTaluk(null);
      setAddress("");

      let title, message, icon;

      if (response.data.response === "S") {
        title = "Registered Successfully!";
        message = "Thank you for registering to donate blood. Your contribution can save lives!";
        icon = "success";
        setIsToggled(true)
        setIsdonor('Donor')
      } else {
        title = "Already Registered";
        message = "Thank you for your continued support as a blood donor. Your contributions are invaluable!";
        icon = "info";
      }

      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };


  const props = 'get the postID here from acceptedlist componant'

  const handleReloadData = () => {
    setBloodReceivers((prevReceivers) =>
      prevReceivers.map((item) => {
        if (item.postId === props) {
          return {
            ...item,
            acceptStatus: !item.acceptStatus, // Toggle the status
          };
        }
        return item; // Leave other items unchanged
      })
    );
  };

  // const handleReloadData = () => {
  //   if (receiver.status === 'NT') {
  //     // Remove the item with matching postId
  //     setBloodReceivers((prevReceivers) =>
  //       prevReceivers.filter((item) => item.postId !== receiver.postId)
  //     );
  //   } else {
  //     // Toggle the acceptStatus for the item with matching postId
  //     setBloodReceivers((prevReceivers) =>
  //       prevReceivers.map((item) => {
  //         if (item.postId === receiver.postId) {
  //           return {
  //             ...item,
  //             acceptStatus: !item.acceptStatus, // Toggle the status
  //           };
  //         }
  //         return item; // Leave other items unchanged
  //       })
  //     );
  // };



  useEffect(() => {
    dispatch(bloodreceiverdatas(page)).then((response) => {
      const responsedata = response.payload.data;
      if (!responsedata || responsedata.length === 0) {
        setBloodReceivers([]);
        return;
      }
      const decryptedData = AesDecrypt(responsedata);
      setBloodReceivers(decryptedData);
    });
  }, [dispatch]);



  const stateOptions = [
    { value: "Andhra Pradesh", label: "ANDHRA PRADESH" },
    { value: "Arunachal Pradesh", label: "ARUNACHAL PRADESH" },
  ];

  const fetchAcceptedListCount = async (userId) => {
    try {
      // Dispatch the action to get accepted blood posts if necessary
      const dispatchResponse = await dispatch(getAcceptedBloodPost(userId));
      if (dispatchResponse.payload.response === 'S') {
        const decrypteddata = AesDecrypt(dispatchResponse.payload.data)
        return 1
      }

    } catch (error) {
      console.error('Error fetching accepted list count:', error);
      return 0; // Return 0 in case of an error
    }
  };

  const handleWillingToDonate = async (receiver) => {
    try {
      if (isdonor === 'NoDonor') {
        setDonateModal(true);
      } else {
        // Fetch the accepted list count
        const acceptedListCount = await fetchAcceptedListCount(receiver.userId);

        if (acceptedListCount > 0 && receiver.acceptStatus === false) {
          Swal.fire({
            title: 'Donation Limit Reached',
            text: 'You can only donate for one donor at a time. Please complete the current donation process before proceeding.',
            icon: 'info',
            confirmButtonText: 'Understood',
          });

          return;
        }

        dispatch(userAccesptPost(receiver)).then(response => {
          const decryptedData = response.payload.response;
          if (decryptedData === 'F') {
            Swal.fire({
              title: 'Info',
              text: 'You have already received the request. Please check your notifications.',
              icon: 'warning',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/bloodNotification'); // Navigate using React Router
              }
            });
          }
          if (decryptedData === 'S') {
            if (receiver.status === 'NT') {
              // Remove the item with matching postId
              setBloodReceivers((prevReceivers) =>
                prevReceivers.filter((item) => item.postId !== receiver.postId)
              );
            } else {
              // Toggle the acceptStatus for the item with matching postId
              setBloodReceivers((prevReceivers) =>
                prevReceivers.map((item) => {
                  if (item.postId === receiver.postId) {
                    return {
                      ...item,
                      acceptStatus: !item.acceptStatus, // Toggle the status
                    };
                  }
                  return item; // Leave other items unchanged
                })
              );
            }

            if (!receiver.acceptStatus) {
              Swal.fire({
                title: 'Donation Accepted',
                text: 'Thank you for accepting to donate! Your generosity can make a big difference.',
                icon: 'success',
                confirmButtonText: 'Continue',
              });
            } else {
              Swal.fire({
                title: 'Donation Canceled',
                text: 'You have successfully canceled the donation process. Feel free to restart whenever you are ready.',
                icon: 'info',
                confirmButtonText: 'Got it',
              });
            }
          } else {
            // Handle other cases if needed
          }
        });
      }
    } catch (error) {
      console.error('Error fetching isDonor:', error);
      Swal.fire({
        title: 'Error',
        text: 'Error while accepting status. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'CreatePost':
        return <CreatePost />;
      case 'AcceptedList':
        return <AcceptedList />;
      case 'TrackHistory':
        return <TrackHistory />;
      case 'BloodNotification':
        return <BloodNotification />;
      default:
        return '';
    }
  };

  const handleReloadBloodPost = () => {
    setBloodReceivers([...bloodReceivers]);
    setActiveComponant('')
  };



  return (
    <>


      {/* <div style={{ display: 'flex', justifyContent: 'space-between', overflow: 'scroll' }}>
        <div >
          <h1 className="container_heading">Blood Donation</h1>
        </div>

        <Button onClick={() => handleReloadBloodPost()} variant="info">
          {t("BloodPost")}
        </Button>


        <Button onClick={() => setActiveComponant('CreatePost')} variant="info">
          {t("Your Request")}
        </Button>


        <Button onClick={() => setActiveComponant('TrackHistory')} variant="info">
          {t("Track History")}
        </Button>

        <Button onClick={() => setActiveComponant('AcceptedList')} variant="info">
          {t("Accepted List")}
        </Button>

        <Button onClick={() => setActiveComponant('BloodNotification')} variant="info">
          <i id="icon-dark" className="notification-icon">&#128276; {t("Notification")}</i>
        </Button>

        <div className={`blood-card ${isToggled ? 'on' : 'off'}`} >
          <div className="text-black" style={{ textAlign: 'center' }}>
            {t('Donate blood')}
          </div>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <div className="toggle-switch" onClick={handleToggle} style={{ cursor: 'pointer' }}>
            <div className={`switch ${isToggled ? 'on' : 'off'}`} style={{ backgroundColor: isToggled ? 'green' : 'red' }}>
              <span className="toggle-text" style={{ color: 'white' }}>{isToggled ? 'On' : 'Off'}</span>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div> */}
      {/* <div  className="main_table" > */}
      {/* </div> */}
      <div className="blooddonation-topmenu">
        <div>
          <h2 className="container_heading" style={{ marginBottom: '7px' }}>Blood Groups</h2>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '50px' }}> */}

        <div className={`blood-card ${isToggled ? 'on' : 'off'}`} >
          <div className="text-black toggletext" style={{ textAlign: 'center' }}>
            {t('Donate blood')}
          </div>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          {/* <div className="toggle-switch" onClick={handleToggle} style={{ cursor: 'pointer' }}>
              <div className={`switch ${isToggled ? 'on' : 'off'}`} style={{ backgroundColor: isToggled ? 'green' : 'red' }}>
                <span className="toggle-text" style={{ color: 'white' }}>{isToggled ? 'On' : 'Off'}</span>
              </div>
            </div> */}
          <div className="toggle-switch" style={{ cursor: 'pointer' }}>
            <Switch onChange={handleToggle} checked={isToggled} />
          </div>
        </div>
      </div>
      <div className="blooddonation_navbtns">
        <div className="blood_btns"  >
          <Link to={"/createpost"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Your Request")}
            </Button>
          </Link>
          <Link to={"/trackHistory"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Track History")}
            </Button>
          </Link>
          <Link to={"/acceptedList"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Accepted List")}
            </Button>
          </Link>
          <Link to={"/bloodNotification"}>
            <Button className="blood_nav_btns" variant="info">
              <i id="icon-dark" className="notification-icon">&#128276; {t("Notification")}</i>
            </Button>
          </Link>
        </div>
      </div>
      {/* </div> */}

      <Modal show={donateModal} onHide={() => setDonateModal(!donateModal)}>
        <Modal.Header closeButton>
          <Modal.Title> {t("Your Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <FormLabel> {t("Select your State")}:</FormLabel>
              <ReactSelect options={stateOptions} value={selectedState} onChange={handleStateChange} />
            </Form.Group>
            <Form.Group className="mt-3">
              <FormLabel> {t("Select your District")} :</FormLabel>
              <ReactSelect isDisabled={!selectedState} options={districtOptions} value={selectedDistrict} onChange={handleDistrictChange} />
            </Form.Group>
            <Form.Group className="mt-3">
              <FormLabel> {t("Select your Taluk")} :</FormLabel>
              <ReactSelect isDisabled={!selectedDistrict} options={talukOptions} value={selectedTaluk} onChange={(selectedOption) => setSelectedTaluk(selectedOption)} />
            </Form.Group>
            <Form.Group className="">
              <FormLabel> {t("Enter your address")} :</FormLabel>
              <Form.Control className="text-black" as="textarea" rows={3} value={address} onChange={(e) => setAddress(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSubmit}>
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>

      {activeComponent === '' ? (
        <div className="custom-feed-container" onScroll={handleScroll}>
          <div className="feed-container">
            {Array.isArray(bloodReceivers) && bloodReceivers.length === 0 ? (
              // <div className="no-data">
              //   <img style={{height:"65vh"}} src={img} alt="No Data" />
              // </div>
              <div className="no-data" style={{ textAlign: "center", padding: "2rem" }}>
                <h2 style={{ color: "#D32F2F", marginBottom: "1rem" }}>
                  No Blood Posts Available
                </h2>
                <p style={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#555" }}>
                  Every drop of blood is precious. Donating blood saves lives and brings hope
                  to those in need. Currently, there are no blood donation posts available.
                </p>
                <p style={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#555" }}>
                  Be a hero today by donating blood and making a difference. Stay tuned for
                  upcoming blood donation events and opportunities to contribute.
                </p>
              </div>

            ) : (
              bloodReceivers
                .slice() // Display posts up to visiblePosts
                .map((receiver, index) => (
                  <Card key={receiver.postId || index} className="">
                    <div className="post-header">
                      <h2>{receiver.name} needs blood</h2>
                      <span className="blood-group-badge">
                        {receiver.bloodGroup || "All Blood Types"}
                      </span>
                      {/* <span className="blood-group-badge">{index + 1}</span> */}
                    </div>
                    <Card.Body className="text-black">
                      <p>
                        <strong>Mobile :</strong>{" "}
                        {receiver.acceptStatus ? receiver.mobile : "**********"}
                      </p>
                      <p>
                        <strong>hospitalName : </strong>{" "}
                        {receiver.hospitalName || "Not Mentioned"}
                      </p>
                      <p> <strong>Taluk : </strong> {receiver.subDistrict || 'Not Mentioned'}</p>

                      <p>
                        <strong>District : </strong> {receiver.district || "Not Mentioned"}
                      </p>
                      <p>
                        <strong>State : </strong> {receiver.state || "Not Mentioned"}
                      </p>
                      <p>
                        <strong>reason : </strong> {receiver.reason || "Not Mentioned"}
                      </p>
                      <p>
                        <strong>surgeryDate : </strong>
                        {receiver.surgeryDate
                          ? moment(receiver.surgeryDate).format("MMMM D")
                          : "Not Mentioned"}
                      </p>
                    </Card.Body>
                    <div className="card-footer-custom-space">
                      Posted on: {receiver.createdDate ? moment(receiver.createdDate).format('MMM D, h:m A') : 'Not Mentioned'}
                      <Button
                        className={`donate-button ${receiver.acceptStatus ? 'cancel-button' : ''}`}
                        onClick={() => handleWillingToDonate(receiver)}
                      >
                        {receiver.acceptStatus ? receiver.status === 'NT' ? 'Remove' : 'Cancel' : 'Donate'}
                      </Button>
                    </div>
                  </Card>
                ))
            )}
            {loading && !allPostsFetched && (
              <div className="loading-spinner">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
            )}

          </div>
        </div>
      ) : (
        <div>{renderComponent()}</div>
      )}
    </>
  );
};

export default Blood;