import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AuditLogdata, getfruitdata, getlanguage } from "./store/index";
import { Card, Button } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';

const FruitsFeed = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFruits, setFilteredFruits] = useState([]);
  const [expanded, setExpanded] = useState(null); // For expanding fruit details
  const cardRefs = useRef([]); // Array to store refs for all cards
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [placeholder, setPlaceholder] = useState('Search'); 

  const userId = localStorage.id
  const auditLogged = useRef(false);
  const screenName = 'Fruits'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Fruits - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  const handleFocus = () => {
    setPlaceholder(''); // Clear the placeholder text on focus
  };
  const handleBlur = () => {
    if (searchTerm === '') {
      setPlaceholder('Search'); // Reset placeholder if the input is empty
    }
  };
  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        const lang = res.payload.language;
        dispatch(getfruitdata(lang)).then((response) => {
          const data = response.payload.data;
          logAuditData()
          // Sort the data alphabetically by name
          const sortedData = data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          setImageData(sortedData);
          setFilteredFruits(sortedData); // Initially show all fruits
        });
      });
    }
  }, [dispatch]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredFruits(
      imageData.filter((item) =>
        item.name.toLowerCase().includes(searchValue)
      )
    );
  };

  const scrollToCard = (index) => {
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start", // Scrolls to the top of the card
        inline: "nearest", // Prevents horizontal scrolling (optional)
      });
    }
  };


  const toggleDetails = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle expanded details
  };

  return (
    <div className="fruits-feed-container"  >
      {/* <h2 className="text-center mb-2">{t("Fruits")}</h2> */}

      <div className="feed-layout" style={{ height: "75vh" }}>
        {/* Main Content */}
        <div className="feed-main">
          {/* Search Bar */}


          {/* Fruit Cards */}
          <div className="fruit-card-wrapper">
            {filteredFruits.map((item, index) => (
              <Card
                key={index}
                ref={(el) => (cardRefs.current[index] = el)} // Attach ref to each card
                className="fruit-card"
                onClick={() => toggleDetails(index)}
              >
                <div className="fruit-card-header">
                  <div className="fruit-card-img-wrapper">
                    <h5 className="fruit-card-title" style={{ marginTop: "2%", fontSize: "26px" }}>{t(item.name)}</h5>
                    <img
                      src={item.img}
                      alt={item.name}
                      className="fruit-card-img"
                    // style={{ filter: "drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.3))" }}
                    />
                  </div>
                </div>

                <Card.Body className="fruit-card-body">

                  <div
                    className={`fruit-card-details ${expanded === index ? "expanded" : ""
                      }`}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content || t("No content available"),
                      }}
                    ></p>
                  </div>
                  <Button
                    variant="link"
                    className="view-more-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDetails(index);
                    }}
                  >
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>

        {/* Sidebar */}
        <div className="feed-sidebar">
          
          <div className="search-container" style={{ display: 'flex', alignItems: 'center' }}>
            <FaSearch style={{ position: 'absolute', left: '20px', color: '#aaa' }} />
            <input
              type="text"
              placeholder={placeholder}
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
              style={{ paddingRight: '30px' , fontSize:"18px" }}
              autoFocus
              onFocus={handleFocus}
              onBlur={handleBlur} 
            />

          </div>
         
          <ul className="sidebar-list">
            {/* Fruit List */}
            {imageData.map((item, index) => (
              <li
                key={index}
                className="sidebar-item"
                onClick={() => scrollToCard(index)} // Scroll to the corresponding card
                style={{ textAlign: "center", cursor: "pointer" }}
              >
                {t(item.name)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FruitsFeed;
