import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddStress, AuditLogdata } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { MdArrowBack } from "react-icons/md";

const Stress = () => {
  const dispatch = useDispatch();
  const [stress, setStress] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.id;

  const screenName = 'Mental Health'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    const action = 'Stress - Add';
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    const currentQuestion = stressQuestions[currentQuestionIndex].id;

    setStress((prev) => ({
      ...prev,
      [currentQuestion]: choice,
    }));

    if (!isLastQuestion) {
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => prev + 1);
        setSelectedChoice("");
      }, 300); // Add slight delay for better UX
    }
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    setStress((prevStress) => ({ ...prevStress, [currentQuestion]: selectedChoice }));
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;

    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    const adjustedScore = (totalScore * 100) / 40;
    return Math.round(Math.min(adjustedScore, 100)); // Return rounded score
  };



  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current stress question and selected choice
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    const StressData = { ...stress, [currentQuestion]: selectedChoice };

    // Calculate stress score
    const score = calculateScore(StressData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Stress';
    const lastSyncDate = ''; // If no sync date is provided, leave it empty
    const dataToInsert = [{
      stress: StressData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddStress(data)).then((response) => {
      logAuditData()
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Stress Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setStress({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };

    
  const handleBackButton = () => {
    navigate('/mentalhealth');
  }



  const stressQuestions = [
    { question: t("In the last month, how often have you been upset because of something that happened unexpectedly?"), id: "s1" },
    { question: t("In the last month, how often have you felt that you were unable to control the important things in your life?"), id: "s2" },
    { question: t("In the last month, how often have you felt nervous and stressed?"), id: "s3" },
    { question: t("In the last month, how often have you found that you could not cope with all the things that you had to do?"), id: "s4" },
    { question: t("In the last month, how often have you been angered because of things that happened that were outside of your control?"), id: "s5" },
    { question: t("In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?"), id: "s6" },
    { question: t("In the last month, how often have you felt that you were on top of things?"), id: "s7" },
    { question: t("In the last month, how often have you been able to control irritations in your life?"), id: "s8" },
    { question: t("In the last month, how often have you felt that things were going your way?"), id: "s9" },
    { question: t("In the last month, how often have you felt confident about your ability to handle your personal problems?"), id: "s10" }
  ];

  const stressChoices1 = [
    { label: t("Never"), value: "0" ,icon: "😀"},
    { label: t("Almost Never"), value: "1" ,icon: "🙂"},
    { label: t("Sometimes"), value: "2", icon: "😐" },
    { label: t("Fairly often"), value: "3", icon: "🙁" },
    { label: t("Very often"), value: "4" ,icon:"😞"}
  ];

  const stressChoices2 = [
    { label: t("Never"), value: "4" ,icon: "😀"},
    { label: t("Almost Never"), value: "3",icon: "🙂" },
    { label: t("Sometimes"), value: "2" , icon: "😐"},
    { label: t("Fairly often"), value: "1", icon: "🙁" },
    { label: t("Very often"), value: "0" ,icon:"😞"}
  ];

  const getChoicesForQuestion = (questionId) => {
    const idNumber = parseInt(questionId.replace("s", ""), 10);
    return idNumber <= 7 ? stressChoices1 : stressChoices2;
  };

  const isLastQuestion = currentQuestionIndex === stressQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        // Full viewport height
        width: "100%", // Full viewport width
        position: "relative"
      }}
    >
     <div
        className="iconbackButton"
            style={{
             
              transition: "transform 0.3s ease",
            
            }}
            onClick={handleBackButton} // Properly referencing the function
          >
            <i className="fas fa-arrow-alt-circle-left"></i>
        </div>
         <div style={{ width:"55%"}}>
          <h3 className="depressioncardheading" style={{ color: "#059862", fontWeight: "700", marginBottom: "2%", marginTop: "-3%", textAlign:"center" }}>
          Stress Quiz
          </h3>
          <p className="depressioncardnum" style={{  color: "#333", margin: "0 20px", textAlign:"end" , color: "#888", fontWeight: "700"}}>
            Question {currentQuestionIndex + 1} of {stressQuestions.length}
          </p>
        </div>


      <Card
        className="depressioncard"
          style={{
            height: "auto", // Allow dynamic height
            minHeight: "60vh",
            boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
            borderRadius: "12px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* Progress bar at the top */}
          <div
            className="prog-bar"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "10px",
              background: "#e0e0e0",
              borderRadius: "12px 12px 0 0",
              overflow: "hidden",
            }}
          >
            <div
              className="prog"
              style={{
                height: "100%",
                background: "#4caf50",
                transition: "width 0.3s ease",
                width: `${((currentQuestionIndex + 1) / stressQuestions.length) * 100}%`,
              }}
            ></div>
          </div>
          <CardBody style={{ padding: "0", marginTop: "10px" ,marginBottom:"20px"}}>
            <div className="depression-test-container">
              <div className="question_card">
                <p className="question-text">{stressQuestions[currentQuestionIndex].question}</p>
              </div>

              <div className="choices-container-column">
                {getChoicesForQuestion(stressQuestions[currentQuestionIndex].id).map((choice, i) => (
                  <div
                    key={i}
                    className={`choice-column ${selectedChoice === choice.value ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection(choice.value)}
                  >
                    <span className="choice-icon">{choice.icon}</span>
                    <span className="choice-label">{choice.label}</span>
                  </div>
                ))}
              </div>

              <div className="actions-btn-depression">
                {!isFirstQuestion && (
                  <button onClick={handlePreviousQuestion} className="btns btns-previous">
                    {t("Previous")}
                  </button>
                )}
                {!isLastQuestion ? (
                  <button disabled={!selectedChoice} className="btns btns-next">
                    {t("Next")}
                  </button>
                ) : (
                  <button onClick={handleSaveMood} className="btns btns-submit">
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </CardBody>
          </Card>
          </div>
    </Fragment>
  );
};

export default Stress;
