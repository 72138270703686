import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Card, Button, Modal, Row } from "react-bootstrap";
import { diabetesDelete, diabetesEdit, DiabetesData, AddDiabetesData, AuditLogdata } from "./store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Badge, Col, Input, UncontrolledTooltip } from "reactstrap";
import "./diabetes.css"
import { useTranslation } from 'react-i18next'
import classnames from "classnames";
import webservice from '../../../services/webservice';
import { v4 as uuidv4 } from 'uuid';
import img from '../../../images/EmptyFile.svg'
import swal from 'sweetalert';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { selectThemeColors } from "@utils";
// import { Controller } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import moment from "moment";
import { AesDecrypt } from "../../../services/crypto";
import { IoMdAddCircle } from "react-icons/io";
import Swal from "sweetalert2";
// const DataTable = ({ alldata, handleReload }) => {
const DataTable = ({ allData, setAllData }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const userId = localStorage.id
  const [basicModal, setBasicModal] = useState(false);
  const [sugarLevel, setSugarLevel] = useState('');
  const [sugarType, setSugarType] = useState('');
  const [dateTime, setDateTime] = useState(new Date());
  const [meal, setActiveTab] = useState('');
  const [age, setAge] = useState('');
  const dispatch = useDispatch();

  const [bloodSugarTooltipContent, setBloodSugarTooltipContent] = useState('');
  const sugerTypeOptions = [
    { value: 'HBA1C test', label: t('HBA1C test') },
    { value: 'Fasting blood sugar test', label: t('Fasting test') },
    { value: 'Random blood sugar test', label: t('Random test') },
    { value: 'Glucose tolerance test', label: t('Glucose test') }
  ];
  const {
    control,
    setValue,
    formState: { },
    reset,
  } = useForm();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: 'black',
      '&:hover': {
        // backgroundColor: '#c7dbf9',
        backgroundColor: '#d6e0ef',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
    }),
  };

  const screenName = 'Diabetes'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAddAuditData = () => {
    const action = 'Diabetes - Add'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const logUpdateAuditData = () => {
    const action = 'Diabetes - Update'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const logDeleteAuditData = () => {
    const action = 'Diabetes - Delete'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };




  const handleSugerTypeChange = (selectedOption) => {
    setSugarType(selectedOption.value)
  }




  const handleDeleteClick = (_id, uid, createdDate) => {
    // setDeletedId(_id);
    // setUid(uid);
    // setCreatedDate(createdDate);
    const lastSyncDate = '';
    const collection = 'Medical_BS';

    const dataToInsert = [];
    const dataToDelete = [{
      _id,
      uid,
      createdDate
    }]// Set the deleted ID state
    const data = {
      dataToDelete,
      dataToInsert,
      userId,
      collection,
      lastSyncDate
    }
    dispatch(diabetesDelete(data)).then((response) => {
      const Returendata = AesDecrypt(response.payload.data);
      logDeleteAuditData()
      setAllData(Returendata.dataToInsert)
      // handleReload()

    }); // Pass the ID to the deleteBloodPressureData action
  };

  const handleConfirmDelete = (_id, uid, createdDate) => {
    Swal.fire({
      title: `Do you really want to delete this Record ${" "}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons:"yes"
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClick(_id, uid, createdDate);
        Swal.fire(
          'Deleted!',
          'The Record has been deleted.',
          'success'
        );
      }
    });
  };

  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };




  const handlediscard = (index) => {
    setEditingIndex(null);
  };


  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDateTimeChange = (date) => {

    setErrors({});
    setDateTime(date);
  };

  const handleSugarChange = (e) => {

    const error = {};
    error.sugarLevel = t('')
    if (/^\d{0,4}$/.test(e.target.value)) {
      setErrors(error)
      if (e.target.value > 2000) {
        // If the value is above 180, set it to 180 (max limit)
        setSugarLevel(2000);
        // setErrors({ sugarLevel: 'blood Pressure must be at most 2000' });
      } else {
        // If the value is valid (between 12 and 180), just set it
        setSugarLevel(e.target.value);
        setErrors({ pulse: '' }); // Clear any error messages
      }
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const userID = localStorage.getItem('id');
        const response = await fetch(webservice + 'api/bmiValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userID: userID })
        });
        const data = await response.json();
        const dob = data.user.dob;
        // Format dob into a valid date string
        const dobParts = dob.split('-');
        const formattedDOB = dobParts[1] + '-' + dobParts[0] + '-' + dobParts[2];
        const birthDate = new Date(formattedDOB);
        // Calculate age from date of birth
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        // Set the age using setAge
        if (!isNaN(age)) {
          setAge(age);
        } else {
          console.error('Invalid age:', age);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);



  const handleBloodSugarInfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your blood sugar level should be between 70 mg/dL to 140 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 13 && age1 <= 19) {
      suggestion = 'Your blood sugar level should be between 70 mg/dL to 150 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 20 && age1 <= 26) {
      suggestion = 'Your blood sugar level should be between 74 mg/dL to 162 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 27 && age1 <= 40) {
      suggestion = 'Your blood sugar level should be between 78 mg/dL to 166 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 41 && age1 <= 59) {
      suggestion = 'Your blood sugar level should be between 79 mg/dL to 166 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 60 && age1 <= 64) {
      suggestion = 'Your blood sugar level should be between 79 mg/dL to 164 mg/dL (milligrams per deciliter) when fasting.';
    } else {
      suggestion = 'Blood sugar level range for this age group is not available.';
    }

    setBloodSugarTooltipContent(suggestion);
  };


  const getBloodSugarLevelStatus = (sugarLevel) => {
    if (sugarLevel === '') {
      return '';
    }
    const level = parseFloat(sugarLevel);
    if (isNaN(level)) {
      return 'Invalid input'; // Handle invalid input
    }

    if (level < 0) {
      return 'Sugar level cannot be negative'; // Handle negative values
    }

    if (level < 70) {
      return 'Low Blood Sugar (Hypoglycemia)'; // Indicate low blood sugar
    } else if (level >= 126) {
      return 'Diabetes';
    } else if (level >= 100 && level <= 125) {
      return 'Prediabetes';
    } else {
      return 'Normal';
    }
  };


  const getBloodSugarLevelColor = (sugarLevel) => {
    const sugarLevelStatus = getBloodSugarLevelStatus(sugarLevel);
    if (sugarLevelStatus === 'Diabetes') {
      return 'red';
    } else if (sugarLevelStatus === 'Prediabetes') {
      return '#c39e19';
    } else if (sugarLevelStatus === 'Normal') {
      return 'green';
    } else if (sugarLevelStatus === 'Low Blood Sugar (Hypoglycemia)') {
      return 'red';
    } else {
      return 'black'; // Default color
    }
  };


  const UTCDate = moment(dateTime, 'MM-DD-YYYY hh:mm a').utc().toISOString();




  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    let errors = {};

    if (!sugarLevel) {
      errors.sugarLevel = t('Blood Sugar Level is required');
    }
    if (!sugarType) {
      errors.sugarType = t('Test Type is required');
    }
    if (meal === '') {
      errors.meal = t('Meal type is required');
    }

    const uid = uuidv4();
    const collection = 'Medical_BS';
    const lastSyncDate = '';

    if (Object.keys(errors).length === 0) {
      const dataToInsert = [{
        sugarLevel,
        sugarType,
        meal,
        testDate: UTCDate,
        userId,
        createdDate: UTCDate,
        modifiedDate: UTCDate,
        uid
      }];

      const dataToDelete = [];
      const data = {
        dataToInsert,
        lastSyncDate,
        collection,
        dataToDelete,
        userId
      };

      dispatch(AddDiabetesData(data)).then((response) => {
        const Encparam = AesDecrypt(response.payload.data);
        setAllData(Encparam.dataToInsert);
        logAddAuditData()
        Swal.fire(
          'Saved',
          "Blood sugar has been added successfully.",
          'success'
        );

        setBasicModal(false);
        setSugarLevel('');
        setSugarType('');
        setActiveTab('');
        setDateTime(new Date());
        setErrors('');
      });
    } else {
      setErrors(errors);
    }
  };


  const handleClose = async (e) => {
    setBasicModal(false);
    setErrors('');
    setSugarLevel('')

  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      className="custom-datepicker"
    />
  ));



  const VALID_BLOOD_SUGAR_RANGE = { min: 10, max: 2000 };

  const handleKeyDown = (e, field, id) => {
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', // Navigation and edit keys
    ];
  
    if (allowedKeys.includes(e.key)) {
      return;
    }
  
    const currentText = e.target.innerText || '';
  
    if (currentText.length >= 4) {
      e.preventDefault();
      return;
    }
  
    // Allow only numeric keys
    if (!/^[0-9]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleInlineChangeForToggle = (e, field, id, value) => {
    const newData = [...allData];
    const index = newData.findIndex((data) => data._id === id);
    if (index !== -1) {
      newData[index][field] = value;
      setAllData(newData);
      handleSave(id, { [field]: value });
    }
  };
  
  const handleBlur = (e, field, id) => {
    const value = parseInt(e.target.innerText || "0", 10);
    const { min, max } = VALID_BLOOD_SUGAR_RANGE;
  
    let correctedValue = value;
  
    if (value < min || isNaN(value)) {
      alertInvalidValue(field, min, value);
      e.target.innerText = min;
      correctedValue = min; // Set corrected value to minimum
    } else if (value > max) {
      alertInvalidValue(field, max, value);
      e.target.innerText = max;
      correctedValue = max; // Set corrected value to maximum
    }
  
    // Update state and backend
    handleInlineChange(e, field, id, correctedValue);
    handleSave(id, { [field]: correctedValue });
  };

  
  const handleInlineChange = (e, field, id, value) => {
  
    const newData = [...allData];
    const index = newData.findIndex((data) => data._id === id);
    if (index !== -1) {
      newData[index][field] = value;
      setAllData(newData); // Reactively update UI
    }
  };
  
  const handleSave = (id, updatedFieldData) => {
    const updatedData = { ...updatedFieldData, _id: id };
    dispatch(diabetesEdit(updatedData))
      .then((response) => {
        logUpdateAuditData();
  
        if (response.payload.response === "S") {
          toast.success('Data Edited Successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000, // 1 second
          });
        }
  
        setEditingIndex(null);
      })
      .catch((error) => {
        console.error('Save failed', error);
  
        toast.error('Failed to edit data. Please try again.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // 3 seconds
        });
      });
  };
  
  const alertInvalidValue = (field, limit, value) => {
    Swal.fire({
      toast: true,
      icon: 'error',
      title: `${field} value cannot be ${value < limit ? 'less' : 'greater'} than ${limit}.`,
      position: 'center',
      timer: 1000, // 1 second
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };
  






  const handleDateChangeedit = (date, field, id) => {
    const newData = [...allData];
    const index = newData.findIndex((data) => data._id === id);
    if (index !== -1) {
      newData[index][field] = date;
      setAllData(newData);
      handleSave(id, { [field]: date });
    }
  };




  const renderData = () => {
    if (!Array.isArray(allData) || allData.length === 0) {
      return (
        <div className="d-flex justify-content-center">
          <img src={img} alt="No Data" style={{ position: 'relative', top: '50px', height: "400px" }} />
        </div>
      );
    }

    return (
      <div style={{ position: 'relative', boxShadow: "rgba(149, 15  7, 165, 0.2) 0px 8px 24px;" }}>
        {Array.isArray(allData) && allData.length > 0 ? (
          <div className="responsive_row">
            {allData
              .slice() // Create a shallow copy so original data isn't mutated
              .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
              .map((data, index) => (
                <div key={index} className="mood_responsive_row">
                  <div className="card-custom card" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;" }}>
                    <div className="card-body">
                      <div className="card_body_container">
                        <div className="sugar_lvl_type">
                          <div className="sugar_level">
                            <p>
                              <div style={{ display: "flex", textAlign: "center", flexDirection: "column" }}>
                                <span
                                  contentEditable
                                  suppressContentEditableWarning
                                  onBlur={(e) => handleBlur(e, 'sugarLevel', data._id)}
                                  onKeyDown={(e) => handleKeyDown(e, 'sugarLevel', data._id)}
                                >
                                  {data.sugarLevel}
                                </span>


                                <p style={{ fontSize: "9px", marginBottom: "2px", color: "#dddfde" }}>Value</p>
                              </div>
                            </p>
                          </div>
                          <div className="sugar_type">
                            <h5>{t('Sugar Type')}</h5>
                            <div>
                              <select
                                className="select-no-arrow"  // Add this class to remove the arrow
                                value={data.sugarType || ''}
                                onChange={(e) => handleInlineChangeForToggle(null, 'sugarType', data._id, e.target.value)}
                              >
                                <option value="">Select Sugar Type</option>
                                <option value="HBA1C test">HBA1C test</option>
                                <option value="Fasting blood sugar test">Fasting blood sugar test</option>
                                <option value="Random blood sugar test">Random blood sugar test</option>
                                <option value="Postprandial blood sugar test">Postprandial blood sugar test</option>
                              </select>
                            </div>
                          </div>


                          <div onClick={() => handleConfirmDelete(data._id, data.uid, data.createdDate)}>
                            <i className="fa fa-trash" style={{ color: "red" }}></i>
                          </div>
                        </div>

                        {/* Editable meal type (Before Meal / After Meal) */}
                        <div className="meal_container">
                          <div className="by " style={{ marginRight: "2%" }}>taken</div>
                          <div className="meal_ab">
                            <p className="card-text fs-20 font-w500" style={{ color: "black" }}>
                              <div className="meal_content" style={{
                                color: data.meal === 'Before Meal' ? '#1496ce' : '#1bbd77',
                                zIndex: 1,
                              }}>
                                <div className="meal_content">
                                  <h6
                                    className="before"
                                    style={{
                                      background: data.meal === 'Before Meal' ? '#1496ce' : 'transparent',
                                      color: data.meal === 'Before Meal' ? '#fff' : 'black',
                                      fontWeight: data.meal === 'Before Meal' ? 'bold' : 'normal',
                                      zIndex: data.meal === 'Before Meal' ? 10 : 1,
                                      cursor: "pointer"
                                    }}
                                    onClick={() => handleInlineChangeForToggle(null, 'meal', data._id, 'Before Meal')}
                                  >
                                    Before Meal
                                  </h6>
                                  <h6
                                    className="after"
                                    style={{
                                      background: data.meal === 'After Meal' ? '#1bbd77' : 'transparent',
                                      color: data.meal === 'After Meal' ? '#fff' : 'black',
                                      fontWeight: data.meal === 'After Meal' ? 'bold' : 'normal',
                                      zIndex: data.meal === 'After Meal' ? 10 : 1,
                                      cursor: "pointer"
                                    }}
                                    onClick={() => handleInlineChangeForToggle(null, 'meal', data._id, 'After Meal')}
                                  >
                                    After Meal
                                  </h6>
                                </div>
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="datatabledate">

                          <p>
                            <DatePicker
                              selected={new Date(data.testDate)}
                              onChange={(date) => handleDateChangeedit(date, 'testDate', data._id)}
                              dateFormat="MMM dd, yyyy"
                              className="custom-datepicker"
                              showMonthDropdown
                              showYearDropdown
                              maxDate={new Date()}
                              customInput={<CustomInput />}
                              dropdownMode="select"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            No data available to display
          </p>
        )}
      </div>
    );
  };





  return (
    <>
      {/* <div className="bpsugar-responsive-add-button"  >
      
      <h1>Diabetes</h1>
        <Button onClick={() => setBasicModal(true)} className="circle-button_bp" color="primary">+</Button>
      </div> */}
      <div className="main_table" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div >
          <h1 className="container_heading">Blood Sugar</h1>
          <ToastContainer />
        </div>
        <div >
          <IoMdAddCircle onClick={() => setBasicModal(true)} style={{ fontSize: '40px', color: 'green' }} />
        </div>
      </div>
      <Fragment>
        <div style={{ position: 'relative', padding: "2%" }}>
          {renderData()}
        </div>
        <div className="bootstrap-modal">
          <Modal className="fade" show={basicModal} onHide={() => setBasicModal(false)}>
            <Modal.Header>
              <Card.Title>{t('Enter Diabetes Details Here')}</Card.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Blood Sugar Level (mg/dL) ')}
                </label>
                <div className="col-sm-8">
                  <div style={{ position: 'relative' }}>
                    <Input
                      type="text"
                      className={`form-control${errors.sugarLevel ? ' is-invalid' : ''} text-black`}
                      value={sugarLevel}
                      onChange={(e) => handleSugarChange(e)}
                      required
                      placeholder="Enter the sugar level, e.g., 110..."
                    />
                    {errors.sugarLevel && (
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {errors.sugarLevel}
                      </div>
                    )}
                    <span
                      style={{ position: 'absolute', right: 10, top: 18 }}
                      onMouseEnter={handleBloodSugarInfo}
                    >
                      <i className="fa fa-info-circle" id="bloodSugarInfoIcon">
                        <UncontrolledTooltip target="bloodSugarInfoIcon">
                          {bloodSugarTooltipContent}
                        </UncontrolledTooltip>
                      </i>
                    </span>
                  </div>
                  <div className="Bpsuger_lable">
                    <label style={{ color: getBloodSugarLevelColor(sugarLevel) }}>
                      {getBloodSugarLevelStatus(sugarLevel)}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: '8%' }}>
                <label className="col-sm-4 col-form-label text-black">
                  {t('Test Type ')}
                </label>
                <div className="col-sm-8">
                  <Controller
                    id="sugarType"
                    control={control}
                    name="sugarType"
                    render={({ field }) => (
                      <Select
                        options={sugerTypeOptions}
                        classNamePrefix="select"
                        styles={customStyles}
                        className={classnames("react-select", { 'is-invalid': errors.sugarType })}
                        {...field}
                        onChange={handleSugerTypeChange}
                      />
                    )}
                  />
                  {errors.sugarType && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.sugarType}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Meal Type ')}
                </label>
                <div className="col-sm-8">
                  <div style={{ display: 'flex', width: '120%' }}>
                    <div className={`tab ${meal === 'Before Meal' ? 'active' : ''}`} onClick={() => handleTabChange('Before Meal')}>
                      {t('Before Meal')}
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className={`tab ${meal === 'After Meal' ? 'active' : ''}`} onClick={() => handleTabChange('After Meal')}>
                      {t('After meal')}
                    </div>
                  </div>
                  {errors.meal && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.meal}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Date and Time ')}
                </label>
                <div className="col-sm-8">
                  <DatePicker
                    selected={dateTime}
                    onChange={handleDateTimeChange}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy hh:mm a"
                    required
                    maxDate={new Date()}
                    customInput={<CustomInput />}
                  />
                  {errors.date && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.date}</div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button
                    onClick={handleClose}
                    variant="danger"
                    className="text-center mt-2 pt-50"
                  >
                    {t('Close')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    className="text-center mt-2 pt-50 btn-success"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>

    </>
  );
};

export default DataTable;
