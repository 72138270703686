import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddDepression, AuditLogdata } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { MdArrowBack } from "react-icons/md";

const Depression = () => {
  const dispatch = useDispatch();
  const [depression, setDepression] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userId = localStorage.id;
  const screenName = 'Mental Health'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    const action = 'Depression - Add';
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    const currentQuestion = depressionQuestions[currentQuestionIndex].id;

    setDepression((prev) => ({
      ...prev,
      [currentQuestion]: choice,
    }));

    if (!isLastQuestion) {
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => prev + 1);
        setSelectedChoice("");
      }, 300); // Add slight delay for better UX
    }
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = depressionQuestions[currentQuestionIndex].id;
    setDepression((prevMood) => ({ ...prevMood, [currentQuestion]: selectedChoice }));
    setSelectedChoice(depression[depressionQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };
  const handlePreviousQuestion = () => {
    setSelectedChoice(depression[depressionQuestions[currentQuestionIndex - 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const calculateScore = (scoreData) => {
    const scores = Object.values(scoreData).map(Number);
    const totalScore = scores.reduce((acc, score) => acc + score, 0);
    const adjustedScore = (totalScore * 100) / (depressionQuestions.length * 3);
    return Math.round(Math.min(adjustedScore, 100));
  };



  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current depression question and selected choice
    const currentQuestion = depressionQuestions[currentQuestionIndex].id;
    const DepressionData = { ...depression, [currentQuestion]: selectedChoice };

    // Calculate depression score
    const score = calculateScore(DepressionData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Depression';
    const lastSyncDate = '';


    // If no sync date is provided, leave it empty
    const dataToInsert = [{
      depression: DepressionData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddDepression(data)).then((response) => {
      logAuditData()
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          html: `<span style="font-size: 18px; font-weight: bold;">${t("Depression Test Successful")}</span>`,
          icon: "success",
          didOpen: () => {
            const swalPopup = document.querySelector('.swal2-popup');
            if (swalPopup) {
              swalPopup.style.width = '350px'; // Set the desired width
            }
          },
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setDepression({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };

  const handleBackButton = () => {
    navigate('/mentalhealth');
  }



  const depressionQuestions = [
    { id: "d1", question: t("Little interest or pleasure in doing things") },
    { id: "d2", question: t("Feeling down, depressed or hopeless") },
    { id: "d3", question: t("Trouble falling asleep, staying asleep, or sleeping too much") },
    { id: "d4", question: t("Feeling tired or having little energy") },
    { id: "d5", question: t("Poor appetite or overeating") },
    { id: "d6", question: t("Feeling bad about yourself - or that you’re a failure or have let yourself or your family down") },
    { id: "d7", question: t("Trouble concentrating on things, such as reading the newspaper or watching television") },
    { id: "d8", question: t("Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual") },
    { id: "d9", question: t("Thoughts that you would be better off dead or of hurting yourself in some way") }
  ];

  const commonChoices = [
    { label: t("Not at all"), value: "0", icon: "😀" },
    { label: t("Several days"), value: "1", icon: "🙂" },
    { label: t("More than half the days"), value: "2", icon: "😐" },
    { label: t("Nearly every day"), value: "3", icon: "🙁" }
  ];

  const isLastQuestion = currentQuestionIndex === depressionQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (

    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // Full viewport height
          width: "100%", // Full viewport width
          position: "relative"
        }}
      >
        <div
        className="iconbackButton"
            style={{
             
              transition: "transform 0.3s ease",
            
            }}
            onClick={handleBackButton} // Properly referencing the function
          >
            <i className="fas fa-arrow-alt-circle-left"></i>
        </div>
        <div style={{ width: "55%", }}>
          <div >
            <h3 className="depressioncardheading" style={{ color: "#059862", fontWeight: "700", marginBottom: "2%", marginTop: "-3%", textAlign: "center" }}>
              Depression Quiz
            </h3>
          </div>
          <p className="depressioncardnum" style={{ color: "#333", margin: "0 20px", textAlign: "end", color: "#888", fontWeight: "700" }}>
            Question {currentQuestionIndex + 1} of {depressionQuestions.length}
          </p>
        </div>

        <Card
          className="depressioncard"
          style={{
            height: "auto", // Allow dynamic height
            minHeight: "60vh",
            boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
            borderRadius: "12px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* Progress bar at the top */}
          <div
            className="prog-bar"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "10px",
              background: "#e0e0e0",
              borderRadius: "12px 12px 0 0",
              overflow: "hidden",
            }}
          >
            <div
              className="prog"
              style={{
                height: "100%",
                background: "#4caf50",
                transition: "width 0.3s ease",
                width: `${((currentQuestionIndex + 1) / depressionQuestions.length) * 100}%`,
              }}
            ></div>
          </div>

          <CardBody style={{ padding: "0", marginTop: "10px", marginBottom: "20px" }}>
            <div className="depression-test-container">
              <div className="question_card">
                <p className="question-text">{depressionQuestions[currentQuestionIndex].question}</p>
              </div>

              <div className="choices-container-column">
                {commonChoices.map((choice, i) => (
                  <div
                    key={i}
                    className={`choice-column ${selectedChoice === choice.value ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection(choice.value)}
                  >
                    <span className="choice-icon">{choice.icon}</span>
                    <span className="choice-label">{choice.label}</span>
                  </div>
                ))}
              </div>

              <div className="actions-btn-depression">
                {!isFirstQuestion && (
                  <button onClick={handlePreviousQuestion} className="btns btns-previous">
                    {t("Previous")}
                  </button>
                )}
                {!isLastQuestion ? (
                  <button disabled={!selectedChoice} className="btns btns-next">
                    {t("Next")}
                  </button>
                ) : (
                  <button onClick={handleSaveMood} className="btns btns-submit">
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>

  );
};

export default Depression;
