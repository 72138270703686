import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddAnxiety, AuditLogdata } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { MdArrowBack } from "react-icons/md";

const Anxiety = () => {
  const dispatch = useDispatch();
  const [anxiety, setAnxiety] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const userId = localStorage.id;
  const navigate = useNavigate();

  const screenName = 'Mental Health'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  // useEffect(() => {
  //   const action = 'Mood Monitor - View'
  //   const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
  //   dispatch(AuditLogdata(auditData))

  // }, [dispatch])
  const logAuditData = () => {
    const action = 'Anxiety - Add';
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;

    setAnxiety((prev) => ({
      ...prev,
      [currentQuestion]: choice,
    }));

    if (!isLastQuestion) {
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => prev + 1);
        setSelectedChoice("");
      }, 300); // Add slight delay for better UX
    }
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    setAnxiety((prevMood) => ({ ...prevMood, [currentQuestion]: selectedChoice }));
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const [dateTime, setDateTime] = useState(new Date());
  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;

    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    const adjustedScore = (totalScore * 100) / 21;
    return Math.round(Math.min(adjustedScore, 100)); // Return rounded score
  };

  const UTCDate = moment(dateTime, 'MM-DD-YYYY hh:mm a').utc().toISOString();

  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current anxiety question and selected choice
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    const AnxietyData = { ...anxiety, [currentQuestion]: selectedChoice };

    // Calculate anxiety score
    const score = calculateScore(AnxietyData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Anxiety';
    const lastSyncDate = ''; // If no sync date is provided, leave it empty
    const dataToInsert = [{
      anxiety: AnxietyData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddAnxiety(data)).then((response) => {
      logAuditData()
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Anxiety Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setAnxiety({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };

  const handleBackButton = () =>{
    navigate('/mentalhealth');
  }

  const anxietyQuestions = [
    { question: t("Feeling nervous, anxious or on edge"), id: "a1" },
    { question: t("Not being able to stop or control worrying"), id: "a2" },
    { question: t("Worrying too much about different things"), id: "a3" },
    { question: t("Trouble relaxing"), id: "a4" },
    { question: t("Being so restless that it is hard to sit still"), id: "a5" },
    { question: t("Becoming easily annoyed or irritable"), id: "a6" },
    { question: t("Feeling afraid as if something awful might happen"), id: "a7" }
  ];


  const commonChoices = [
    { label: t("Not at all"), value: "0" ,icon: "😀"},
    { label: t("Several days"), value: "1",icon: "🙂" },
    { label: t("More than half the days"), value: "2", icon: "😐"  },
    { label: t("Nearly every day"), value: "3", icon: "🙁" }
  ];

  const isLastQuestion = currentQuestionIndex === anxietyQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        // Full viewport height
        width: "100%", // Full viewport width
        position: "relative"
      }}
    >
      <div
        className="iconbackButton"
            style={{
             
              transition: "transform 0.3s ease",
            
            }}
            onClick={handleBackButton} // Properly referencing the function
          >
            <i className="fas fa-arrow-alt-circle-left"></i>
        </div>
         <div style={{ width:"55%"}}>
          <h3 className="depressioncardheading" style={{ color: "#059862", fontWeight: "700", marginBottom: "2%", marginTop: "-3%", textAlign:"center" }}>
          Anxiety Quiz
          </h3>
          <p className="depressioncardnum" style={{  color: "#333", margin: "0 20px", textAlign:"end" , color: "#888", fontWeight: "700"}}>
            Question {currentQuestionIndex + 1} of {anxietyQuestions.length}
          </p>
        </div>


      <Card
        className="depressioncard"
          style={{
            height: "auto", // Allow dynamic height
            minHeight: "60vh",
            boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
            borderRadius: "12px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* Progress bar at the top */}
          <div
            className="prog-bar"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "10px",
              background: "#e0e0e0",
              borderRadius: "12px 12px 0 0",
              overflow: "hidden",
            }}
          >
            <div
              className="prog"
              style={{
                height: "100%",
                background: "#4caf50",
                transition: "width 0.3s ease",
                width: `${((currentQuestionIndex + 1) / anxietyQuestions.length) * 100}%`,
              }}
            ></div>
          </div>
          <CardBody style={{ padding: "0", marginTop: "10px" ,marginBottom:"20px"}}>
            <div className="depression-test-container">
              <div className="question_card">
                <p className="question-text">{anxietyQuestions[currentQuestionIndex].question}</p>
              </div>

              <div className="choices-container-column">
                {commonChoices.map((choice, i) => (
                  <div
                    key={i}
                    className={`choice-column ${selectedChoice === choice.value ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection(choice.value)}
                  >
                    <span className="choice-icon">{choice.icon}</span>
                    <span className="choice-label">{choice.label}</span>
                  </div>
                ))}
              </div>

              <div className="actions-btn-depression">
                {!isFirstQuestion && (
                  <button onClick={handlePreviousQuestion} className="btns btns-previous">
                    {t("Previous")}
                  </button>
                )}
                {!isLastQuestion ? (
                  <button disabled={!selectedChoice} className="btns btns-next">
                    {t("Next")}
                  </button>
                ) : (
                  <button onClick={handleSaveMood} className="btns btns-submit">
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </CardBody>
          </Card>
          </div>
    </Fragment>
  );
};

export default Anxiety;
