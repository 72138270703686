import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { getAllData, DiabetesData, AuditLogdata } from './store';
import { Col, Button } from 'react-bootstrap';
import DiabetesForm from './DiabetesForm';
import DataTable from './dataTable';
import DualLine from './waveChart';
import { Form } from 'react-bootstrap';
import { Card } from 'reactstrap';
import "./diabetes.css"
import { AesDecrypt } from '../../../services/crypto';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../breadcrumbs'

const Mainindex = () => {

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(5);
  const [showDualLineChart, setShowDualLineChart] = useState(false);
  const [viewMode, setViewMode] = useState('chart');
  const { t } = useTranslation();
  const userId = localStorage.id
  const [allData, setAllData] = useState([]);
  const handleSelectChange = (e) => {
    setSelectedValue(parseInt(e.target.value));
  };
  const auditLogged = useRef(false);
  const screenName = 'Diabetes'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Diabetes - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  // const collection = 'Medical_BS';
  // const lastSyncDate = '';
  // const dataToInsert = [];
  // const dataToDelete = [];
  // const data = {
  //   dataToInsert,
  //   lastSyncDate,
  //   collection,
  //   userId,
  //   dataToDelete
  // };

  // useEffect(() => {
  //   dispatch(getAllData(data))
  //     .then((response) => {

  //       const Encparam = AesDecrypt(response.payload.data)
  //       setAllData(Encparam.dataToInsert)
  //     })
  // }, [dispatch])
  //commend by kaji for looping function
  // },[dispatch]);
  
  useEffect(() => {
    const fetchMoodData = async () => {
      const collection = 'Medical_BS';
      const lastSyncDate = '';
      const dataToInsert = [];
      const dataToDelete = [];
      const data = {
        dataToInsert,
        lastSyncDate,
        collection,
        userId,
        dataToDelete
      };

      try {
        const response = await dispatch(getAllData(data));
        const Encparam = AesDecrypt(response.payload.data);
        setAllData(Encparam.dataToInsert);
      } catch (error) {
        console.error('Error fetching mood data:', error);
      }
    };

    fetchMoodData();
    logAuditData(); // Log audit data only once
  }, [dispatch]);

  const handleViewToggle = () => {
    setViewMode(viewMode === 'chart' ? 'list' : 'chart');
  };

  const handleChartViewClick = () => {
    setShowDualLineChart(true);
    handleViewToggle();
  };

  const handleListViewClick = () => {
    setShowDualLineChart(false);
    handleViewToggle();
  };



  // const handleReload = () => {
  //   dispatch(getAllData())
  //     .then((response) => {
  //       if (response.payload && Array.isArray(response.payload)) {
  //         setAllData(response.payload);
  //       } else {
  //         console.error('Invalid data format:', response.payload);
  //       }
  //     })
  // }


  return (
    <>
      {/* <Breadcrumbs title={t('Diabetes')} data={[{ title: t('Diabetes') }]} /> */}


      <div style={{ display: 'flex', alignItems: 'center', }}>
        <DiabetesForm />
        {/* <Form.Select
            value={selectedValue}
            onChange={handleSelectChange}
            style={{
              maxWidth: "10%",
              marginLeft: "2%",
              marginTop: "2%",
              backgroundColor: "#f8f9fa",
              fontSize: "14px",
              border: "1px solid #ced4da",
              borderRadius: "4px",
              padding: "5px 10px"
            }}

          >
            <option value={5} style={{ backgroundColor: "#fff", color: "#000" }}>Last 5</option>
            <option value={10} style={{ backgroundColor: "#fff", color: "#000" }}>Last 10</option>
            <option value={15} style={{ backgroundColor: "#fff", color: "#000" }}>Last 20</option>
            <option value={50} style={{ backgroundColor: "#fff", color: "#000" }}>Last 50</option>
            <option value={100} style={{ backgroundColor: "#fff", color: "#000" }}>Last 100</option>
          </Form.Select> */}

        {/* <Col>
            {viewMode === 'chart' ? (

              <Button
                color="secondary"
                className='small-but'
                onClick={handleChartViewClick}
                style={{ display: 'flex', minWidth: '193px' }}
              >
                <div style={{ marginLeft: '15%' }}>
                  <i className="fas fa-chart-bar"></i>
                </div>
                <div style={{ marginLeft: '7%' }}>
                  {t('Chart View')}
                </div>
              </Button>
            ) : (
              <Button
                color="secondary"
                className='small-but'
                onClick={handleListViewClick}
                style={{ display: 'flex', minWidth: '193px' }}
              >
                <div style={{ marginLeft: '15%' }}>
                  <i className="fas fa-list"></i>
                </div>
                <div style={{ marginLeft: '7%' }}>
                  {t('List View')}
                </div>
              </Button>
            )}
          </Col> */}
      </div>

      {/* {showDualLineChart ? (
          <DualLine alldata={allData.sort((a, b) => new Date(b.testDate) - new Date(a.testDate)).slice(0, selectedValue)} />
          // <DualLine alldata={allData} />
        ) : (
         
           <DataTable allData={allData} setAllData={setAllData} />
        )} */}

      <DataTable allData={allData} setAllData={setAllData} />

    </>
  );
};

export default Mainindex;
