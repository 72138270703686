import React, { Fragment, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { getbloodpresurechartData } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { Button } from "react-bootstrap";
import '../../../css/custom.css';
import { Link } from "react-router-dom";
import { t } from "i18next";
import moment from "moment";

const DualLine = () => {
  const dispatch = useDispatch();
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _id = localStorage.id;
        const res = await dispatch(getbloodpresurechartData(_id));
        const tempdata = AesDecrypt(res.payload.data);

        if (Array.isArray(tempdata) && tempdata.length !== 0) {
          setBloodPressureData(tempdata);
          setShowChart(true);
        } else {
          setShowChart(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const chartData = {
    labels: bloodPressureData.map((entry) => moment(entry.testDate).format('MMM-DD')),
    datasets: [
      {
        label: "Systolic",
        data: bloodPressureData.map((entry) => entry.systolic),
        borderColor: "rgba(235,129,83,1)",
        borderWidth: 2,
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(235,129,83,1)",
        pointBorderColor: "rgba(235,129,83,1)",
        pointHoverBorderColor: "rgba(255, 99, 132)",
        pointHoverBackgroundColor: "rgba(235,129,83,0.8)",
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
      {
        label: "Diastolic",
        data: bloodPressureData.map((entry) => entry.diastolic),
        borderColor: "rgba(30, 227, 145)",
        borderWidth: 2,
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(30, 227, 145)",
        pointBorderColor: "rgba(30, 227, 145)",
        pointHoverBorderColor: "rgba(54, 162, 235)",
        pointHoverBackgroundColor: "rgba(30, 227, 145, 0.8)",
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 12, // Adjust font size
          },
        },
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "xy",
      intersect: true, // Ensures hover is specific to data points only
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
        display: true,
      },
      x: {
        ticks: {
          padding: 5,
        },
        display: true,
      },
    },
    maintainAspectRatio: false, // Allow dynamic height and width
    responsive: true, // Ensure responsiveness
    onHover: (event, elements) => {
      const chart = event.chart;
      chart.canvas.style.cursor = elements.length > 0 ? "pointer" : "default";
    },
  };
  
  return (
    <Fragment>
    <div className="chart-wrapper"> {/* Apply chart wrapper class */}
      <Line data={chartData} options={options} />
    </div>
    {!showChart && (
      <Link to={"/BloodPressure"}>
        <div className="BP_nodata_container">
          <div className="BP_contant_container">
            <h3 className="bp-header-font">{t("No data available to display")}</h3>
          </div>
        </div>
      </Link>
    )}
  </Fragment>
  );
  
};

export default DualLine;
