
import Swal from 'sweetalert2';
import {
    formatError,
    login,
    saveTokenInLocalStorage,
    signUp,
    otpVerify,
    Fulldetails_login,
    forgotPassword,
    resetPassword,
    checkEmail
} from '../../services/AuthService';
import webservice from '../../services/webservice'
import { AesDecrypt, AesEncrypt } from '../../services/crypto';
// import axios from 'axios';



export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';


export function signupAction(mobile, password, navigate) {
    return (dispatch) => {
        return signUp(mobile, password)
            .then((response) => {

                if (response.data.response === "F") {
                    return response.data
                    // alert('Mobile number already exists');
                } else if (response.data.response === "S") {
                    // If response is success, save token and navigate to OTP page
                    saveTokenInLocalStorage(response.data);
                    dispatch(confirmedSignupAction(response.data));
                    navigate('/OTPPage');
                    // If mobile number already exists, dispatch action to show toast message
                }
                // else {
                //     // Handle other responses or errors
                //     alert('An error occurred. Please try again later.');
                // }
            })
            .catch((error) => {

                // Handle error responses
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}


export function OtpVerifyAction(mobile, password,web_token, navigate) {
    return (dispatch) => {
        otpVerify(mobile, password,web_token)
            .then((response) => {
                localStorage.removeItem('userDetails');
                localStorage.removeItem('name');
                localStorage.removeItem('dob');
                localStorage.removeItem('gender');
                localStorage.removeItem('maritial');
                localStorage.removeItem('bloodgroup');
                localStorage.removeItem('height');
                localStorage.removeItem('weight');
                localStorage.removeItem('profession');
                localStorage.removeItem('email');
                localStorage.removeItem('goSteps')
                const Decrypted = AesDecrypt(response.data.data)
                saveTokenInLocalStorage(Decrypted);
                const userID = Decrypted._id
                const WebToken = Decrypted.web_token
                localStorage.setItem('userID', userID)
                localStorage.setItem('WebToken', WebToken)
                if (response.data.response === "S") {
                    dispatch(loginConfirmedAction(Decrypted))
                    navigate('/Demography');
                }
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}
export function checkEmailAction(email) {
    return (dispatch) => {
        return checkEmail(email)
            .then((response) => {

                return response.data
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };

}
export function forgotAction(email) {
    return (dispatch) => {
        return forgotPassword(email)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };

}
export function resetPasswordAction(data) {
    return (dispatch) => {
        return resetPassword(data)
            .then((response) => {

                return response.data
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {
    const id = localStorage.getItem('id')
    navigate('/login');
    localStorage.removeItem('userDetails');
        fetch(webservice + 'api/WebLogOutStatus', {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
        })
    return {
        type: LOGOUT_ACTION,
    };
}   
 
export function loginAction(mobile, password, web_token, navigate) {
    
    return (dispatch) => {
        return login(mobile, password ,web_token)
            .then((response) => {
                if (response.data.userDataFlag === "A") {
                    const Decrypted = AesDecrypt(response.data.data)
                    const Id = Decrypted._id
                    const EncParams =AesEncrypt({Id,web_token})
                    // const usertype = Decrypted.userType
                    saveTokenInLocalStorage(Decrypted);
                    // dispatch(loginConfirmedAction(Decrypted))
                    // navigate('/dashboard');
                    if (Decrypted.login_status === 'Y') {
                        return Decrypted;
                      } else {
                        dispatch(loginConfirmedAction(Decrypted));
                        navigate('/dashboard');
                        fetch(webservice + 'api/updateLoginStatus', {
                          method: 'POST',
                          body: JSON.stringify({ EncParams }),
                          headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                          }
                        })
                        .then((response) => response.json())
                        .then((res) => {
                        })
                        .catch((error) => {
                          console.error('Update login status error:', error);
                        });
            
                        return Decrypted;
                      }
                }   else if (response.data.userDataFlag === "I") {
                    localStorage.removeItem('userDetails');
                    localStorage.removeItem('name');
                    localStorage.removeItem('dob');
                    localStorage.removeItem('gender');
                    localStorage.removeItem('maritial');
                    localStorage.removeItem('bloodgroup');
                    localStorage.removeItem('height');
                    localStorage.removeItem('weight');
                    localStorage.removeItem('profession');
                    localStorage.removeItem('email');
                    localStorage.removeItem('goSteps')
                    const Decrypted = AesDecrypt(response.data.data)
                    saveTokenInLocalStorage(Decrypted);
                    dispatch(loginConfirmedAction(Decrypted))
                    navigate('/Demography')
                } else if (response.data.response === "MN") {
                    return response.data

                } else if (response.data.response === "PN") {
                    return response.data
                    
                }
            })
            // .catch((error) => {
            //     Swal.fire({
            //         title: 'Error!',
            //         text: 'Could not reach the server, please try again later.',
            //         icon: 'error',
            //         confirmButtonText: 'OK'
            //     });

            //     const errorMessage = formatError(error.response.data);
            //     dispatch(loginFailedAction(errorMessage));

            // });
            .catch(() => {

                Swal.fire({
                    title: 'Error!',
                    text: 'Could not reach the server, please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });

            });
    };
}

export function Fulldetails(name,
    dob,
    gender,
    foodType,
    maritalStatus,
    bloodGroup,
    profession,
    height,
    weight,
    email,
    mobile,
    userId, navigate) {

    return (dispatch) => {
        Fulldetails_login(name,
            dob,
            gender,
            foodType,
            maritalStatus,
            bloodGroup,
            profession,
            height,
            weight,
            email,
            mobile,
            userId,)
            .then((response) => {

                const Decrypted = AesDecrypt(response.data.data)                
                if (response.data.response === 'S') {
                    // If response indicates user exists, navigate to the dashboard
                    saveTokenInLocalStorage(Decrypted);
                    dispatch(loginConfirmedAction(Decrypted))
                    navigate('/dashboard');
                    window.location.reload()
                } else {
                    // Otherwise, navigate to the NamePage
                    navigate('/Demography');
                }
            })
            .catch((error) => {
                // Handle error responses
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}


export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};