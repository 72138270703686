import React, { Fragment, useRef, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, CardTitle, Input, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddMood, AuditLogdata } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import love from '../../../images/emoji/Emoji - Heart Eyes.gif'
import angry from '../../../images/emoji/anger.gif'
import Fear from '../../../images/emoji/fear.gif'
import Sadness from '../../../images/emoji/sad.gif'
import Happy from '../../../images/emoji/happy.gif'
import Cheerful from '../../../images/emoji/cheerful.gif'
import Madness from '../../../images/emoji/Madness.gif'
import Calm from '../../../images/emoji/calm.gif'
import Negative from '../../../images/emoji/negative_mood.gif'
import Anxious from '../../../images/emoji/Anxious.gif'
import Irritability from '../../../images/emoji/irritability.gif'
import Disgust from '../../../images/emoji/disgust.gif'
import moment from "moment";
import { CardHeader } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { MdArrowBack } from "react-icons/md";


const MoodMonitor = ({ onMoodSaved }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mood, setMood] = useState("");
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [activeSection, setActiveSection] = useState("mood");
  const { t } = useTranslation();
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [activeCard, setActiveCard] = useState(0);

  const userId = localStorage.id;
  const screenName = 'Mood Monitor'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    const action = 'Mood Monitor - Add'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  const handleMoodSelection = (selectedMood) => {
    setMood(selectedMood);
    setSelectedMood(selectedMood);
    setTimeout(() => {
      setActiveSection("reason");
    }, 1200); // Delay of 2 seconds
  };

  const handleReasonSelection = (selectedReason) => {
    setReason(selectedReason);
    setSelectedReason(selectedReason);
    setTimeout(() => {
      setActiveSection("note");
    }, 1200); // Delay of 2 seconds
  };
  const textareaRef = useRef(null)

  const handleCardBodyClick = () =>{
    if(textareaRef.current){
      textareaRef.current.focus()
    }
  }

  const [dateTime, setDateTime] = useState(new Date());

  const UTCDate = moment(dateTime, 'MM-DD-YYYY hh:mm a').utc().toISOString();

  // const userId = localStorage.id;

  const handleSaveMood = () => {
    const uid = uuidv4();
    const collection = 'Mood';
    const lastSyncDate = ''; 
    const dataToInsert = [{
      mood,
      reason,
      note,
      userId,
      uid,
      createdDate: UTCDate, 
      modifiedDate: UTCDate, 
    }];
  
    const dataToDelete = []; 
  
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };
  
    dispatch(AddMood(data)).then((response) => {
      const decryptedMoodData = AesDecrypt(response.payload.data);
  
      if (response.payload.response === 'S') {
        logAuditData()
        navigate('/mood_monitor');
        
        setActiveSection("mood");
        setMood("");
        setReason("");
        setNote("");
        setSelectedMood("");
        setSelectedReason("");
      } else {
        alert('Failed to save mood data, please try again.');
      }
    }).catch((error) => {
      console.error('Error while saving mood:', error);
      alert('An error occurred while saving the mood data.');
    });
  };
  

  const style = {
    width: '90%',
    height: '100%'
  }
 


  const moodOptions = [
    { value: "Anger", label: t("Anger"), emoji: <img src={angry} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Fear", label: t("Fear"), emoji: <img src={Fear} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Sadness", label: t("Sadness"), emoji: <img src={Sadness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Happy", label: t("Happy"), emoji: <img src={Happy} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Cheerful", label: t("Cheerful"), emoji: <img src={Cheerful} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Romantic", label: t("Romantic"), emoji: <img src={love} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Madness", label: t("Madness"), emoji: <img src={Madness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Calm", label: t("Calm"), emoji: <img src={Calm} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Negative", label: t("Negative"), emoji: <img src={Negative} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Anxious", label: t("Anxious"), emoji: <img src={Anxious} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Irritability", label: t("Irritability"), emoji: <img src={Irritability} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Disgust", label: t("Disgust"), emoji: <img src={Disgust} alt="Heart Eyes Emoji" style={style} /> }
  ];

  const reasonOptions = [
    { value: "Family", label: t("Family") },
    { value: "Friend", label: t("Friend") },
    { value: "School", label: t("School") },
    { value: "College", label: t("College") },
    { value: "Education", label: t("Education") },
    { value: "Love", label: t("Love") },
    { value: "Care", label: t("Care") },
    { value: "Restful", label: t("Restful") },
    { value: "Game", label: t("Game") },
    { value: "Job", label: t("Job") },
    { value: "Money", label: t("Money") },
    { value: "Others", label: t("Others") },
  ];


  return (
    <div style={{ position: "relative" }}>
      <Fragment>
        <div className="mood-selector-container">
          <div >
            <div className={`card-slider ${activeSection === "reason" ? "slide-left" : ""}`} >
              {/* Mood Selection Card */}
              {activeSection === "mood" && (
                <Card className="mood-card"> 
                  <CardHeader
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <CardTitle className="text-black">Select Your Mood</CardTitle>

                    <Link to="/mood_monitor">
                      <span className="cross_btn"
                        style={{
                          position: "absolute",
                          fontWeight: "bolder", 
                          top: "35%",
                          right: "7%",
                          background: "red",
                          borderRadius: "50%",
                          fontSize: "18px", 
                          color: "#FFF",
                          width: "26px",
                          height: "26px",
                          padding: "0px",  
                          cursor: "pointer",
                        }}
                      >
                        ✖
                      </span>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <div className="mood-grid">
                      {moodOptions.map((option, index) => (
                        <div
                          key={index}
                          className={`mood-item ${selectedMood === option.value ? "selected" : ""}`}
                          onClick={() => {
                            handleMoodSelection(option.value);

                          }}
                        >
                          <span
                            className={`emoji ${selectedMood === option.value ? "checked" : ""}`}
                          >
                            {option.emoji}
                          </span>
                          <p style={{marginTop:"-10px"}}>{option.label}</p>
                        </div>

                      ))}
                    </div>
                  </CardBody>
                </Card>
              )}

              {/* Reason for Mood Card */}
              {activeSection === "reason" && selectedMood && (
                <Card className="reason-card">
                  <CardHeader
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <MdArrowBack
                      style={{
                        fontWeight: "900",  
                        fontSize: "28px",    
                        color: "#FFF",      
                        cursor: "pointer",
                        width: "25px",  
                        height: "25px",
                        background: "green",  
                        transition: "transform 0.3s ease", 
                        position: "absolute",
                        top: "35%",
                        left: "7%",
                        borderRadius: "50%"
                      }}
                      onClick={() => setActiveSection("mood")}
                    />

                    <CardTitle className="text-black">Reason For Your Mood</CardTitle>

                    <Link to="/mood_monitor">
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "bolder",  
                          top: "35%",
                          right: "7%",
                          background: "red",
                          borderRadius: "50%",
                          fontSize: "18px", 
                          color: "#FFF",
                          width: "26px",
                          height: "26px",
                          padding: "0px",  
                          cursor: "pointer",
                        }}
                      >
                        ✖
                      </span>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <div className="reason-grid">
                      {reasonOptions.map((option, index) => (

                          <div
                            key={index}
                            className={`reason-item ${selectedReason === option.value ? "selected" : ""
                              }`}
                            onClick={() => {
                              handleReasonSelection(option.value);

                            }}

                          >
                            <p
                              className={`reason-label ${selectedReason === option.value ? "checked" : ""
                                }`}
                            >
                              {option.label}
                            </p>
                          </div>

                      ))}
                    </div>
                  </CardBody>
                </Card>
              )}

              {/* Add A Note Section */}
              {selectedMood && selectedReason && activeSection === "note" && (
                <Card className="note-card" style={{ width: "100%" }}>
                  <CardHeader
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <MdArrowBack
                      style={{
                        fontWeight: "900",  
                        fontSize: "28px",    
                        color: "#FFF",      
                        cursor: "pointer",
                        width: "25px",   
                        height: "25px",
                        background: "green",   
                        transition: "transform 0.3s ease", 
                        position: "absolute",
                        top: "35%",
                        left: "7%",
                        borderRadius: "50%"
                      }}
                      onClick={() => setActiveSection("reason")}
                    />
                    <CardTitle className="text-black">Add a note</CardTitle>
                    <Link to="/mood_monitor">
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "bolder",  
                          top: "35%",
                          right: "7%",
                          background: "red",
                          borderRadius: "50%",
                          fontSize: "18px", 
                          color: "#FFF",
                          width: "26px",
                          height: "26px",
                          padding: "0px", 
                          cursor: "pointer",
                        }}
                      >
                        ✖
                      </span>
                    </Link>
                  </CardHeader>
                  <CardBody onClick={handleCardBodyClick} style={{ padding: "10px", width: "100%" }}>
                    <div className="mood-input">
                      <textarea
                      ref={textareaRef}
                        style={{
                          height: "50vh",
                          padding: "40px 30px",
                          width: "100%",
                          border: "none",
                          outline: "none",
                        }}
                        placeholder="Write a note here to express you can reflect on your mood"
                        className="important-height"
                        onChange={(e) => setNote(e.target.value)}
                      />

                      <div>
                        <Button
                          style={{
                            marginTop: "10px",
                            float: "right",
                            fontSize:"22px",
                            padding:"1px 1px"
                          }}
                          color="primary"
                          onClick={handleSaveMood}
                          className="btn-success"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>

          {/* Pagination Dots Below Cards */}
          <div className="pagination-dots">
            <span
              className={`dot ${activeSection === "mood" ? "active" : ""}`}
              onClick={() => {
                if (selectedMood) {
                  setActiveSection("mood");
                } 
              }}
            ></span>

            <span
              className={`dot ${activeSection === "reason" ? "active" : ""}`}
              onClick={() => {
                if (selectedReason) {
                  setActiveSection("reason");
                } 
              }}
            ></span>

            <span
              className={`dot ${activeSection === "note" ? "active" : ""}`}
              onClick={() => {
                if (selectedMood && selectedReason) {
                  setActiveSection("note");
                } 
              }}
            ></span>
          </div>
        </div>
      </Fragment>
    </div>






  );
};

export default MoodMonitor;