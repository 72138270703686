import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Flatpickr from 'react-flatpickr';
import { Calendar, ChevronsLeft, ChevronsRight } from 'react-feather';
import { Card, CardHeader, CardTitle, CardBody, Label, Col, Button, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';
// import { selectThemeColors } from '@utils';
import {  getstepChart } from "./store";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getDaysInMonth } from 'date-fns';
import { AesDecrypt } from '../../../services/crypto';

import { motion } from 'framer-motion'; // For animations

// import websocket_webservice from '../../../../service/websocket_webservice';
// import webservice from '../../../../service/webservice';
// import { AesEncrypt } from '../../../../service/crypto';

const WorkoutStatistic = ({ direction }) => {

  const columnColors = {
    received: '#b0a2f2',
    ocr: '#d49494',
    Review: '#f296dc',
    segregation: '#f2d47e',
    completed: '#8ad9e3',
    approved: '#86e38a'
  };

  const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userdata, Setuserdata] = useState([]);
  const [selectedClient, setuserlabel] = useState([]);
  const [SelectedClientID, setuservalue] = useState([]);
  const [resivedata, setResivedata] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [chartColors, setChartColors] = useState([]);

  const [startDate, setstartDate] = useState([]);
  const [endDate, setendDate] = useState([]);

  const dispatch = useDispatch();

  const currentDate = new Date();
  const currentMonth = {
    label: currentDate.toLocaleString('default', { month: 'long' }),
    value: currentDate.getMonth() + 1, // Adding 1 to get the month index starting from 1
  };
  const currentYear = {
    label: currentDate.getFullYear(),
    value: currentDate.getFullYear(),
  };

  const [selectedYear, setSelectedYear] = useState(currentYear);


  const getMonthOptions = () => {
    const months = Array.from({ length: 12 }, (_, index) => ({
      label: new Date(0, index).toLocaleString('default', { month: 'long' }),
      value: index + 1,
    }));
    return months;
  };

  // Function to get an array of year options (you can customize the range based on your needs)
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => ({
      label: currentYear - index,
      value: currentYear - index,
    }));
    return years;
  };
  const [currentStartDate, setCurrentStartDate] = useState(new Date()); // State to track the start date for the selected period (year, month, week)



  const updateDate = (type, direction) => {
    const newStartDate = new Date(currentStartDate);

    if (type === 'year') {
      if (direction === 'next') {
        newStartDate.setFullYear(newStartDate.getFullYear() + 1);
      } else if (direction === 'prev') {
        newStartDate.setFullYear(newStartDate.getFullYear() - 1);
      }
    } else if (type === 'month') {
      if (direction === 'next') {
        newStartDate.setMonth(newStartDate.getMonth() + 1);
      } else if (direction === 'prev') {
        newStartDate.setMonth(newStartDate.getMonth() - 1);
      }
    } else if (type === 'week') {
      if (direction === 'next') {
        newStartDate.setDate(newStartDate.getDate() + 7);
      } else if (direction === 'prev') {
        newStartDate.setDate(newStartDate.getDate() - 7);
      }
    }

    setCurrentStartDate(newStartDate);
  };
  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions();
  const [xAxisLabels, setXAxisLabels] = useState([]);

  const [daysInMonth, setDaysInMonth] = useState(); // Initialize with the maximum number of days

  const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
  
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [isCurrentWeek, setIsCurrentWeek] = useState(false); // State to track if it's the current week


  // Function to update the week
  const updateWeek = (direction) => {
    const newWeekStart = new Date(currentWeekStart);

    if (direction === 'next') {
      newWeekStart.setDate(newWeekStart.getDate() + 7);
    } else if (direction === 'prev') {
      newWeekStart.setDate(newWeekStart.getDate() - 7);
    }

    // Check if the updated week is still in the selected month
    if (
      newWeekStart.getMonth() === selectedMonth.value - 1 &&
      newWeekStart.getFullYear() === selectedYear.value
    ) {
      setCurrentWeekStart(newWeekStart);

      // Check if it's the current week
      const today = new Date();
      const nextWeek = new Date(newWeekStart);
      nextWeek.setDate(nextWeek.getDate() + 7);
      setIsCurrentWeek(today >= newWeekStart && today < nextWeek);
    } else {
      // If the updated week falls into a different month, adjust it to the first day of the selected month
      setCurrentWeekStart(new Date(selectedYear.value, selectedMonth.value - 1, 1));
    }
  };


  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const daysInMonth = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value - 1,));
      setDaysInMonth(daysInMonth);

      // Update the current week start to the beginning of the selected month
      setCurrentWeekStart(new Date(selectedYear.value, selectedMonth.value - 1, 1));
    }
  }, [selectedMonth, selectedYear]);
  useEffect(() => {
    setXAxisLabels(Array.from({ length: daysInMonth }, (_, index) => {
      const currentDate = new Date(selectedYear.value, selectedMonth.value - 1, index + 1);
      return currentDate.toLocaleDateString('default', { day: 'numeric' });
    }));
  }, [daysInMonth, selectedMonth, selectedYear]);


  // Function to handle date selection
  const handleDateChange = (date) => {
    setstartDate(date); // Flatpickr returns an array of selected dates, so we take the first one
  };
  const handleendDateChange = (date) => {
    setendDate(date); // Flatpickr returns an array of selected dates, so we take the first one
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _id = localStorage.id;
        const resReceived = await dispatch(getstepChart(_id));
        const dataArrayReceived = AesDecrypt(resReceived.payload.data);

        const processedData = Array.from({ length: 7 }, (_, index) => {
          // Start from the beginning of the week (currentWeekStart)
          const currentDate = new Date(currentWeekStart);
          currentDate.setDate(currentDate.getDate() + index);

          // Format the date as 'DD-MM-YYYY'
          const day = String(currentDate.getDate()).padStart(2, '0');
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const year = currentDate.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;


          // Retrieve the value from dataArrayReceived, defaulting to 0 if not found
          return parseInt(dataArrayReceived[formattedDate] || '0', 10);
        });

        setResivedata(processedData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, [dispatch, SelectedClientID, selectedClient, selectedMonth, selectedYear, daysInMonth, currentWeekStart]);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const _id = localStorage.id;
  //       const resReceived = await dispatch(getstepChart(_id));
  //       const dataArrayReceived = AesDecrypt(resReceived.payload.data);

  //       const filterDataByDate = (dataArray, status, dateKey) => {
  //         const startDay = currentWeekStart.getDate();
  //         const startMonth = currentWeekStart.getMonth();
  //         const startYear = currentWeekStart.getFullYear();

  //         const filteredData = dataArray.filter((file) => {
  //           const fileDate = new Date(file[dateKey]);
  //           return (
  //             fileDate.getMonth() === startMonth &&
  //             fileDate.getFullYear() === startYear
  //           );
  //         });

  //         const dailyCounts = Array.from({ length: 7 }, (_, index) => {
  //           const currentDate = new Date(startYear, startMonth, startDay + index);

  //           // Avoid future dates
  //           if (currentDate > new Date()) {
  //             return 0;
  //           }

  //           const count = filteredData.filter((file) => {
  //             const fileDate = new Date(file[dateKey]);
  //             return (
  //               fileDate.getDate() === currentDate.getDate() &&
  //               fileDate.getMonth() === currentDate.getMonth() &&
  //               fileDate.getFullYear() === currentDate.getFullYear()
  //               // Additional conditions can be added here if needed
  //             );
  //           }).length;
            
  //           return count;
  //         });

  //         return dailyCounts;
  //       };

  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();

  // }, [dispatch, SelectedClientID, selectedClient, selectedMonth, selectedYear, daysInMonth, currentWeekStart]);



  useEffect(() => {
    const currentDate = new Date();
    const daysInMonth = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value - 1));
    const lastDayOfMonth = new Date(selectedYear.value, selectedMonth.value - 1, daysInMonth);
    setIsCurrentWeek(true)
    // Check if the current date is in the selected month and year
    if (
      currentDate.getMonth() === selectedMonth.value - 1 &&
      currentDate.getFullYear() === selectedYear.value
    ) {
      // Calculate the remaining days in the month from the current date
      const remainingDays = daysInMonth - currentDate.getDate() + 1;
      // Set the current week start to the current date minus 6 days
      const currentWeekStartDate = new Date(currentDate);
      currentWeekStartDate.setDate(currentDate.getDate() - 6);
      setCurrentWeekStart(currentWeekStartDate);
      setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
        const date = new Date(currentWeekStartDate);
        date.setDate(date.getDate() + index);
        return date.toLocaleDateString('default', { day: 'numeric' });
      }));
    } else {
      // If the current date is not in the selected month, just show the default 7 days
      setCurrentWeekStart(lastDayOfMonth);
      setXAxisLabels(Array.from({ length: 7 }, (_, index) => {
        const date = new Date(lastDayOfMonth);
        date.setDate(lastDayOfMonth.getDate() - (6 - index));
        return date.toLocaleDateString('default', { day: 'numeric' });
      }));
    }
  }, [selectedMonth, selectedYear]);


  const maxCount = Math.max(
    ...resivedata,
  );
  const options = {
    chart: {
      height: 1700,
      type: 'bar',
      parentHeightOffset: 0,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        colors: {
          backgroundBarColors: chartColors,
          backgroundBarRadius: 10
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'start'
    },
    colors: [columnColors.received, columnColors.ocr, columnColors.segregation, columnColors.completed, columnColors.approved],
    stroke: {
      show: true,
      colors: ['transparent']
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: Array.from({ length: 7 }, (_, index) => {
        const currentDate = new Date(currentWeekStart);
        currentDate.setDate(currentDate.getDate() + index);

        // Check if the date is in the future
        if (currentDate > new Date()) {
          return null; // Return null for future dates
        }

        const day = currentDate.toLocaleDateString('default', { day: 'numeric' });
        const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
        const formattedMonth = month < 10 ? `0${month}` : month; // Ensure two-digit month format
        const year = currentDate.getFullYear(); // Get the year

        return `${day}/${formattedMonth}/${year}`;
      })
        .filter(date => date !== null), // Filter out null values (future dates)
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    yaxis: {
      opposite: direction === 'rtl',
      labels: {
        show: true,
        min: 0, // Set the minimum value for the y-axis
        formatter: function (value, index) {
          return Math.round(value); // Round the value before displaying it on the y-axis
        }
      },
      max: maxCount, // Set the maximum value of the y-axis to the maximum data count
    }
  };


  const series = [
    {
      name: 'Step Count',
      data: resivedata 
    },
  ];

  useEffect(() => {
    setValue('usertype', selectedUserType ? { label: selectedUserType.label, value: selectedUserType.value } : null);
  }, [selectedUserType]);

  const handleUserTypeChange = (selectedOption) => {
    setValue('usertype', selectedOption ? '' : null);
    if (selectedOption) {
      setuserlabel(selectedOption.label);
      setuservalue(selectedOption.value);
    } else {
      setuservalue([]);
      setuserlabel([]);

      // setValue('usertype', selectedUserType ? { label: selectedUserType.label, value: selectedUserType.value } : null);
      setValue('usertype', selectedUserType ? { label: selectedUserType.label, value: selectedUserType.value } : null);
    }

  };
  const playStoreLink = "https://play.google.com/store/apps/details?id=com.instagram.android";
  const appStoreLink = "https://apps.apple.com/app/instagram/id389801252";

  return (
    <Card>
      <CardBody>
      {resivedata.length === 0 && (
        
        <motion.div
  initial={{ opacity: 0, scale: 0.95 }}
  animate={{ opacity: 1, scale: 1 }}
  transition={{ duration: 0.7 }}
  style={{
    background: 'linear-gradient(to bottom right, #0F2027, #203A43, #2C5364)',
    color: 'white',
    padding: '40px',
    borderRadius: '20px',
    textAlign: 'center',
    maxWidth: '800px',
    margin: 'auto',
  }}
>
  <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '20px',color:"white" }}>
    Ready to Track Your Steps?
  </h1>
  <p style={{ fontSize: '1.2rem', marginBottom: '30px' ,color:"white" }}>
    Start your fitness journey today. Every step brings you closer to your goals!
  </p>
  <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginBottom: '40px' }}>
    <motion.div
      whileHover={{ scale: 1.1 }}
      style={{
        background: 'rgba(255, 255, 255, 0.1)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <img
        src="https://img.icons8.com/color/96/google-play.png"
        alt="Google Play"
        style={{ width: '60px', height: '60px', marginBottom: '10px' }}
      />
      <a
        href={playStoreLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: '#00FFAA',
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        Get it on Google Play
      </a>
    </motion.div>
    <motion.div
      whileHover={{ scale: 1.1 }}
      style={{
        background: 'rgba(255, 255, 255, 0.1)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <img
        src="https://img.icons8.com/ios-filled/100/apple-app-store.png"
        alt="App Store"
        style={{ width: '60px', height: '60px', marginBottom: '10px' }}
      />
      <a
        href={appStoreLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: '#00FFAA',
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        Download on App Store
      </a>
    </motion.div>
  </div>
  <div>
    <h2 style={{ color: '#00FFAA', marginBottom: '20px' }}>How to Use:</h2>
    <ul style={{ listStyle: 'none', padding: '0', fontSize: '1rem' }}>
      <li>✅ Install the app from the store of your choice.</li>
      <li>✅ Open the app and enable step tracking.</li>
      <li>✅ Return to this screen to view your step data.</li>
    </ul>
  </div>
</motion.div>

        
      )}
      </CardBody>
       {resivedata.length > 0 &&(
        <>
      <CardHeader className='d-flex flex-md-row flex-column justify-content-between align-items-center'>
  


          <>
            <Col xs={8} md={4}></Col>
            <div className='d-flex ml-md-auto'>
              <div className='d-flex align-items-center mt-md-0 mt-1 mr-md-3'>
                <Col xl={12}>
                  <Label className='form-label' htmlFor='selectedMonth'>
                    Month
                  </Label>
                  <Select
                    // theme={selectThemeColors}
                    options={monthOptions}
                    value={selectedMonth}
                    classNamePrefix='select'
                    className={classNames('react-select', {})}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setSelectedMonth(selectedOption);
                      } else {
                        setSelectedMonth(null);
                      }
                    }}
                  />
                </Col>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className='d-flex align-items-center mt-md-0 mt-1'>
                <Col xl={12}>
                  <Label className='form-label' htmlFor='selectedYear'>
                    Year
                  </Label>
                  <Select
                    // theme={selectThemeColors}
                    options={yearOptions}
                    value={selectedYear}
                    classNamePrefix='select'
                    className={classNames('react-select', {})}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setSelectedYear(selectedOption);
                      } else {
                        setSelectedYear(null);
                      }
                    }}
                  />
                </Col>
              </div>
            </div>
          </>

      </CardHeader>



      <CardBody >
        
        <Chart id="chart-container" options={options} series={series} type='bar' height={330} />

        <div className='d-flex align-items-center mt-md-0 mt-1'>
          <Button className="btn btn-primary mr-2" color="primary" id='PreviousWeek' onClick={() => updateWeek('prev')}>
            <ChevronsLeft />
            <UncontrolledTooltip placement='top' target='PreviousWeek'>
              Previous Week
            </UncontrolledTooltip>
          </Button>
          <Button className="btn btn-primary" id='NextWeek' color="primary" onClick={() => updateWeek('next')} style={{ marginLeft: "80%" }} disabled={isCurrentWeek}>
            <ChevronsRight />
            <UncontrolledTooltip placement='top' target='NextWeek'>
              Next Week
            </UncontrolledTooltip>
          </Button>
        </div>
      </CardBody>
</>
)}
    </Card>

  );
};

export default WorkoutStatistic;
