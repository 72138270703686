import React, { useState, useEffect, Fragment, useRef } from "react";
import { getdata, getDob, getlanguage, AuditLogdata } from "./store/index";
import { useDispatch } from "react-redux";
import { Col, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { AesDecrypt } from "../../../services/crypto";
import Breadcrumbs from '../../breadcrumbs'
import img from '../../../images/EmptyFile.svg'

const Medicalcheckup = () => {
  const [allData, setAllData] = useState([]);
  const [showAllData, setShowAllData] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("");
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [loadings, setIsLoadings] = useState(false); // Add loading state
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const userId = localStorage.id;
  const screenName = 'Medical Checkup list'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  // useEffect(() => {
  //   const action = 'Medical Checkup list - View'
  //   const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
  //   dispatch(AuditLogdata(auditData))

  // }, [dispatch])

  // const logAuditData = () => {
    
  // };
  // useEffect(() => {
  //   logAuditData(); // Calling inside useEffect to mimic previous behavior.
  // }, []);



  const auditLogged = useRef(false);
  useEffect(() => {
    const fetchData = async () => {
      const id = localStorage.getItem('id');
      if (id !== "undefined") {
        const response = await dispatch(getDob(id));
        if (response.payload && response.payload.DOB) {
          const decryptedDOB = AesDecrypt(response.payload.DOB);

          if (!auditLogged.current) {
            const action = 'Medical Checkup list - View';
            const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
            dispatch(AuditLogdata(auditData));
            auditLogged.current = true; // Mark as logged
          }

          // Parse dd-mm-yyyy format
          const [day, month, year] = decryptedDOB.split("-");
          const parsedDOB = new Date(`${year}-${month}-${day}`); // Convert to yyyy-mm-dd

          if (!isNaN(parsedDOB)) {
            setDob(parsedDOB); // Store as a Date object
          } else {
            console.error("Invalid DOB format:", decryptedDOB);
          }
        }
      }
    };

    fetchData();
  }, [dispatch]); 



  // useEffect(() => {

  //   const fetchData = async () => {
  //     const id = localStorage.getItem('id');
  //     if (id !== "undefined") {
  //       const response = await dispatch(getDob(id));
  //       if (response.payload && response.payload.DOB) {
  //         const decryptedDOB = AesDecrypt(response.payload.DOB);
  //         const action = 'Medical Checkup list - View'
  //         const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
  //         dispatch(AuditLogdata(auditData));
  //         // Parse dd-mm-yyyy format
  //         const [day, month, year] = decryptedDOB.split("-");
  //         const parsedDOB = new Date(`${year}-${month}-${day}`); // Convert to yyyy-mm-dd
  //         if (!isNaN(parsedDOB)) {
  //           setDob(parsedDOB); // Store as a Date object
  //         } else {
  //           console.error("Invalid DOB format:", decryptedDOB);
  //         }
  //       }
  //     }
  //   };
  //   fetchData();

  // }, [dispatch]);


  useEffect(() => {

    const id = localStorage.id;
    if (id !== "undefined") {
      
      dispatch(getlanguage(id)).then((res) => {
    
        const lang = res.payload.language;

        dispatch(getdata(lang)).then((response) => {
          
          const filteredData = !showAllData
         
            ? response.payload.data.filter((item) => {
              return (
                item.year_start <= calculateAgeInDays(new Date(dob)) &&
                item.year_end >= calculateAgeInDays(new Date(dob))
              );
            })
            : response.payload.data;

          const roundedData = filteredData.map((item) => ({
            ...item,
            startAge: calculateRoundedAge(item.year_start),
            endAge: calculateRoundedAge(item.year_end),
          }));

          setAllData(roundedData);
          setIsLoading(false); // Set loading to false after data is fetched
        });
      });
    }
    
  }, [dispatch, dob, showAllData]);


  function calculateAgeInDays(birthday) {
    const ageInMilliseconds = Date.now() - birthday.getTime();
    const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.floor(ageInDays);
  }

  function calculateRoundedAge(age) {
    if (age < 365) {
      return `${age} ${t('days')}`;
    }
    return Math.round(age / 365);
  }

  // const toggleShowAllData = () => {
  //   setShowAllData(!showAllData);
  //   setButtonLabel(showAllData ? t("All Medical Test list") : t("Current Age"));
  // };

  // useEffect(() => {
  //   setButtonLabel(t("All Medical Test list"));
  // }, [t]);

  const toggleShowAllData = () => {
    setIsLoadings(true);
    setShowAllData((prevState) => !prevState);
    setButtonLabel((prevState) =>
      prevState === t("All Medical Test list") ? t("Your Age") : t("All Medical Test list")
    );
    setIsLoadings(false);
  };

  useEffect(() => {
    setShowAllData(true); // Initially show all data
    setButtonLabel(t("Your Age")); // Set default button label
  }, [t]);

  return (
    <Fragment>
      <div className="main_table" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 className="container_heading">Medical Examination</h1>
        {/* <Button  style={{maxWidth:'50px',marginBottom:'2%'}} color="primary">+</Button> */}
        <Button onClick={toggleShowAllData} style={{ maxWidth: '20%', marginBottom: '2%' }} >
          {loadings ? (
            <div className="loader">
              <div className="box-load1"></div>
              <div className="box-load2"></div>
              <div className="box-load3"></div>
            </div>
          ) : (
            buttonLabel
          )}
        </Button>
      </div>

      {/* <Breadcrumbs title={t('Medical Checkup Details')} data={[{ title: t('Medical Checkup') }]} /> */}

      <div className="shopping-cart mb-5">
        {/* <Button   onClick={toggleShowAllData}> */}

      </div>
      <div className="row">
        {allData.length === 0 ? (
          <div className="d-flex justify-content-center">
            <img src={img} alt="No Data" style={{ height: "400px" }} />
          </div>
        ) : (
          allData.map((item, index) => (
            <div className="mood-responsive-card" key={index}>
              <div
                // Always use a unique key when rendering lists
                className="card card-zoom"
                style={{
                  boxShadow: "0px 0px 10px 0px rgb(153, 201, 144)",
                }}
              >
                <Link to={`/medicalcheckup/${item._id}`}>
                  <div style={{ padding: '5%' }} >
                    <div className="media">
                      <div className="media-body">
                        <h4 className="fs-22 font-w600" >
                          {t(item.checkup_name)}
                        </h4>
                        <h4 className="fs-18" style={{ color: 'rgb(77, 77, 77)' }}>
                          {t(item.reason)}
                        </h4>
                        {item.year && item.year !== "nil" && (
                          <h4 className="fs-18 font-w600" style={{ color: 'rgb(51, 102, 255)' }}>
                            {t(item.year)}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </Fragment>

  );
};

export default Medicalcheckup;
