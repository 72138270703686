import React, { useEffect, useState } from 'react';
import { BiUpArrowAlt } from "react-icons/bi";
import axios from "axios";
import webservice from '../../../../src/services/webservice'
import { useDispatch } from 'react-redux';
import { UserChatHistory } from './store';
import { Button } from 'react-bootstrap';
const ChatBot = () => {
  const [inputValue, setInputValue] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("id") || "defaultUserId";  // Ensure userId is set properly
  // const textGendration = "http://192.168.0.12:8000/" // My local port
  const textGendration = "http://192.168.0.12:8000/" // My local port
  // const textGendration = "http://165.1.67.224:8877/" // MRR live server
  const handleUpload = () => {
    const trimmedInput = inputValue.trim();

    if (!trimmedInput) return; // Prevent empty submissions

    const userMessage = trimmedInput; // Clean user input
    const messageIndex = chatLog.length; // Use the current length of chatLog as the index

    // Update the chat log with the user's message immediately
    setChatLog((prevLog) => [
      ...prevLog,
      { message: userMessage, isRequest: true, index: messageIndex },
    ]);

    setInputValue(""); // Clear the input field

    // Add a temporary "typing..." message to simulate loading
    setChatLog((prevLog) => [
      ...prevLog,
      { message: "Typing...", isRequest: false, isLoading: true, index: messageIndex },
    ]);

    // Send the user's message to the backend
    axios
      .post(`${textGendration}generate/`, {
        prompt: userMessage,
        userId: userId,
      })
      .then((response) => {
        console.log('responseresponseresponseresponseresponseresponseresponseresponseresponseresponse', response);

        // Replace the "typing..." message with the actual response
        setChatLog((prevLog) =>
          prevLog.map((msg) =>
            msg.isLoading && msg.index === messageIndex
              ? { ...msg, message: response.data.generated_text, isLoading: false }
              : msg
          )
        );
      })
      .catch((error) => {
        console.error("Axios Error:", error);

        // Replace the "typing..." message with an error message
        setChatLog((prevLog) =>
          prevLog.map((msg) =>
            msg.isLoading && msg.index === messageIndex
              ? { ...msg, message: "Error: Unable to fetch a response.", isLoading: false }
              : msg
          )
        );
      });
  };


  useEffect(() => {
    fetchChatHistory();
  }, []);

  const fetchChatHistory = async () => {
    // try {
    //   dispatch(UserChatHistory(userId)).then((response) => {
    //     if (response.payload.response === 'S') {
    //       setChatLog(response.payload.history || []);  
    //     } else {
    //       console.log('error fetching user chat history')
    //     }

    //   })

    // } catch (error) {
    //   console.error("Error fetching chat history:", error);
    // }
  };


  return (
    <div className="chatBotStyle">
      {/* Chat Header */}
      <div
        style={{
          padding: "10px 10px",
          fontWeight: "bold",
          backgroundColor: "rgb(0, 153, 204)",
          color: "#fff",
          marginTop: '-10px',
          fontSize: "18px",
          textAlign: "center",
          borderRadius: "5px"
        }}
      >
        Medhorizone Chat Bot
      </div>

      {/* Chat Body */}
      <div
        className="chatBodyStyle"
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          maxHeight: "calc(60vh - 5vh)",
        }}
      >
        {chatLog.map((msg, index) => (
          <>
            {/* <div key={index} style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  padding: "12px 15px",
                  backgroundColor: msg.isRequest ? "#e0dfe0" : "#7e7e7e",
                  color: msg.isRequest ? "#000" : "#FFF",
                  borderRadius: msg.isRequest ? "15px 15px 0 15px" : "15px 15px 15px 0",
                  maxWidth: "70%",
                  wordWrap: "break-word",
                  textAlign: "left",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                }}
                className={msg.isLoading ? "typing" : ""}
              >
                {msg.message}    
                {msg.request}
 
              </div>
            </div>
            <div key={index} style={{ display: "flex", justifyContent: "flex-start" }}>
              <div
                style={{
                  padding: "12px 15px",
                  backgroundColor: msg.isRequest ? "#e0dfe0" : "#7e7e7e",
                  color: msg.isRequest ? "#000" : "#FFF",
                  borderRadius: msg.isRequest ? "15px 15px 0 15px" : "15px 15px 15px 0",
                  maxWidth: "70%",
                  wordWrap: "break-word",
                  textAlign: "left",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                }}
                className={msg.isLoading ? "typing" : ""}
              >
                {msg.message}    
                {msg.response}
              </div>
            </div> */}
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: msg.isRequest ? "flex-end" : "flex-start", // Align user messages right, bot messages left
              }}
            >
              <div
                style={{
                  padding: "12px 15px",
                  backgroundColor: msg.isRequest ? "#e0dfe0" : "#7e7e7e", // Different colors for user and bot
                  color: msg.isRequest ? "#000" : "#FFF",
                  borderRadius: msg.isRequest
                    ? "15px 15px 0 15px"
                    : "15px 15px 15px 0",
                  maxWidth: "70%",
                  wordWrap: "break-word",
                  textAlign: "left",
                  whiteSpace: "pre-wrap", // Ensures line breaks are respected
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                }}
                className={msg.isLoading ? "typing" : ""}
              >
                {msg.message}
              </div>
            </div>


          </>

        ))}
      </div>

      {/* Chat Input */}
      <div
        className="chatinput_upload"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          borderTop: "1px solid #ddd",
        }}
      >
        <input
          placeholder="Type your message..."
          style={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: "20px",
            padding: "10px",
            resize: "none",
            outline: "none",
            fontSize: "14px",
            minHeight: "40px",
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          rows="1"
          onInput={(e) => {
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleUpload();
            }
          }}
        />
        {/* <Button
          className="chat_upload"
          disabled={!inputValue.trim() || chatLog.some((msg) => msg.isLoading)}
          onClick={handleUpload}
          style={{
            margin: '10px',
            opacity: inputValue ? 1 : 0.3,
            pointerEvents: inputValue ? 'auto' : 'none',
            cursor: inputValue ? 'pointer' : 'default',
            color: '#1a73e8',
            fontSize: '20px',
          }}
        >
          <BiUpArrowAlt />
        </Button> */}
        <Button
          className="chat_upload"
          disabled={!inputValue.trim() || chatLog.some((msg) => msg.isLoading)}
          onClick={handleUpload}
          style={{
            margin: '10px',
            opacity: inputValue ? 1 : 0.3,
            pointerEvents: inputValue ? 'auto' : 'none',
            cursor: inputValue ? 'pointer' : 'default',
            background:"#000000",
            color: '#FFF',
            width: '40px', 
            height: '40px', 
            borderRadius: '50%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            border:"1px solid #000"
          }}
        >
          <BiUpArrowAlt />
        </Button>

        {/* <button
          className="chat_upload"
          type="button"
          style={{
            margin: '10px',
            opacity: inputValue ? 1 : 0.3,
            pointerEvents: inputValue ? 'auto' : 'none',
            cursor: inputValue ? 'pointer' : 'default',
            color: '#1a73e8',
            fontSize: '20px',
          }}
          disabled={!inputValue.trim() || chatLog.some((msg) => msg.isLoading)}
          onClick={handleUpload}
        >
          <BiUpArrowAlt />
        </button> */}
      </div>
    </div>
  );
};

export default ChatBot;