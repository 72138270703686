import React, { useState, useEffect, Fragment } from "react";
import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux
import { getbloodsugarchart } from './store'; // Import your action for fetching data

import 'chart.js/auto';
import { AesDecrypt } from "../../../services/crypto";
import { Link } from "react-router-dom";
import { Label } from "recharts";
import entry from "react-time-picker";
import { t } from "i18next";
import moment from "moment";

const LineChart1 = () => {
  // const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const dispatch = useDispatch();
  const [showChart, setShowChart] = useState(false);
  const [diabetes, setDiabets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _id = localStorage.id;
        const res = await dispatch(getbloodsugarchart(_id));
        const tempdata = AesDecrypt(res.payload.data)
        if (Array.isArray(tempdata) && tempdata.length !== 0) {
          setDiabets(tempdata)
          setShowChart(true);

        }
        else {
          setShowChart(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error
      }
    };

    fetchData();
  }, [dispatch]);

  const chartData = {
    labels: diabetes.map((entry) => moment(entry.testDate).format("MMM-DD")),
    datasets: [
      {
        label: "Sugar Level",
        data: diabetes.map((entry) => entry.sugarLevel),
        borderColor: "rgba(160, 44, 250, 1)", // Solid line color
        borderWidth: 2, // Fix borderWidth to be a number
        backgroundColor: "rgba(160, 44, 250, 0.2)", // Light fill color for better readability
        tension: 0.4, // Smooth line
        pointBackgroundColor: "rgba(160, 44, 250, 1)",
        pointBorderColor: "rgba(160, 44, 250, 1)",
        pointRadius: 4,
      },
    ],
  };
  
  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 12, // Adjust font size
          },
        },
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "xy",
      intersect: true,
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
        display: true,
      },
      x: {
        ticks: {
          padding: 5,
        },
        display: true,
      },
    },
    maintainAspectRatio: false, // Allow the chart to adapt to the container's height
    responsive: true, // Make the chart responsive
    onHover: (event, elements) => {
      const chart = event.chart;
      chart.canvas.style.cursor = elements.length > 0 ? "pointer" : "default";
    },
  };
  
  return (
    <Fragment>
  <div className="chart-wrapper"> {/* Apply chart wrapper class */}
    <Line data={chartData} options={options} />
  </div>
  {!showChart && (
    <Link to={"/Diabetes"}>
      <div className="BP_nodata_container">
        <div className="BP_contant_container">
          <h3 className="bp-header-font">{t("No data available to display")}</h3>
        </div>
      </div>
    </Link>
  )}
</Fragment>

  );
  
}

export default LineChart1;