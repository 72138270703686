import React, { Fragment, useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { RadialGauge } from "react-canvas-gauges";
import BreadCrumbs from "../../breadcrumbs";
import { useTranslation } from "react-i18next";

const CheckBmi = () => {
  const { t } = useTranslation();

  const [age, setAge] = useState(24);
  const [weight, setWeight] = useState(''); // kg
  const [height, setHeight] = useState(''); // cm
  const [bmi, setBMI] = useState(0);
  const [category, setCategory] = useState("");
  const [error, setError] = useState("")
  const [isCalculated, setIsCalculated] = useState(false);
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // Modal state


  const [showModal, setShowModal] = useState(false);

  const calculateBMI = () => {




    setError("");
    const heightInMeters = height / 100; // Convert cm to meters
    const bmiValue = weight / (heightInMeters * heightInMeters);
    setBMI(bmiValue.toFixed(2) || 0);
    setIsCalculated(true);
    // Determine BMI Category
    if (bmiValue < 18.5) setCategory("Under Weight");
    else if (bmiValue >= 18.5 && bmiValue < 25) setCategory("Normal");
    else if (bmiValue >= 25 && bmiValue < 30) setCategory("Over Weight");
    else if (bmiValue >= 30 && bmiValue < 35) setCategory("Obese Class I");
    else if (bmiValue >= 35 && bmiValue < 40) setCategory("Obese Class II");
    else setCategory("Obese Class III");
  };

  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => setShowModal(true);

  const handleCalculate = () => {
    setErrorMessage("");
    setHeightError("");
    setWeightError("");

    if (height === undefined || height === null || height === '' || isNaN(height) || height <= 0 || height === 0) {
      setHeightError('Height is required')
      setTimeout(() => {
        setHeightError('');
      }, 2800);
    }

    if (weight === undefined || weight === null || weight === '' || isNaN(weight) || weight <= 0 || weight === 0) {
      setWeightError('Weight is required')
      setTimeout(() => {
        setWeightError('');
      }, 2800);
    }

    if (weight === "" && height === "") {
      setErrorMessage("Weight and Height is required")
      setTimeout(() => {
        setErrorMessage('');
      }, 2800);
    }
  }

  return (
    <Fragment>
      {/* Button to trigger modal */}
      <button
        className="bmicheckbtn"
        onClick={handleModalOpen}
        style={{ marginBottom: "" }}
      >
        BMI Calculator
      </button>

      {/* Modal Component */}
      <Modal show={showModal} onHide={handleModalClose}  >
        <Modal.Header closeButton>
          <Modal.Title>{t("BMI Calculator")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* BMI Calculator Card */}
          <div className="row">
            <div className="gauge_container" >
              {/* Radial Gauge Section */}
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: "180px",
                  maxWidth: "300px",
                  maxHeight: "300px",
                  margin: "2% auto",
                  position: "relative",
                }}
              >
                {isCalculated && (
                  <div className="bmistatus">
                    {category}
                  </div>
                )}
                {/* Radial Gauge Component */}
                <RadialGauge
                  units="BMI"
                  title="Body Mass Index"
                  value={isNaN(bmi) || !isFinite(bmi) ? 0 : bmi}

                  minValue={15}
                  maxValue={40}
                  majorTicks={["15", "20", "25", "30", "35", "40"]}
                  highlights={[
                    { from: 15, to: 20, color: "#76c7c0" },
                    { from: 20, to: 25, color: "#28a745" },
                    { from: 25, to: 30, color: "#ffc107" },
                    { from: 30, to: 35, color: "#fd7e14" },
                    { from: 35, to: 40, color: "#dc3545" },
                  ]}
                  colorPlate="#eef3f7"
                  borders={false}
                  needleType="arrow"
                  needleWidth={2}
                  animationDuration={10000} // 10 seconds for the entire animation
                  animationRule="linear"
                  valueBox={true}
                  style={{
                    maxWidth: "110%",
                    maxHeight: "300px",  // Keep the max size for the RadialGauge
                  }}
                />
                <h2
                  style={{
                    fontSize: "100%",
                    color: "blue",
                    margin: "10px auto",
                    padding: "0px",
                  }}
                >
                  {!isNaN(bmi) && isFinite(bmi) && weight && height ? (
                    <span>
                      <span style={{ color: "#555", fontWeight: "600" }}>
                        Your BMI result is
                      </span>
                      <span style={{ color: "blue", fontWeight: "600" }}> {bmi}</span>
                    </span>
                  ) : null}

                </h2>
              </div>
            </div>
          </div>

          {/* Input Fields Section */}
          <div className="row bmimodelinputs" style={{ justifyContent: "center", margin: "10% 0" }}>
            <div className="col-xl-3">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "15px",
                }}
              >
                <label
                  htmlFor="weight"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Weight (kg)
                </label>

                <input
                  type="text"
                  value={weight}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (numericValue === "" ||(parseInt(numericValue, 0) > 0 && parseInt(numericValue, 10) <= 640)) {
                      setWeight(numericValue); // Update the state with the new value (or empty string if deleted)
                      calculateBMI(); // Recalculate BMI if necessary
                    }
                  }}
                  className="bmi_inputs"
                  placeholder="Enter weight"
                  style={{
                    padding: "1px",
                    fontSize: "18px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    width: "100px",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "15px",
                }}
              >
                <label
                  htmlFor="height"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Height (cm)
                </label>

                <input
                  type="text"
                  value={height}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (numericValue === "" || (parseInt(numericValue, 0) >0 && parseInt(numericValue, 10) <= 300)) {
                      setHeight(numericValue); // Update the state with the new value (or empty string if deleted)
                      calculateBMI(); // Recalculate BMI if necessary
                    }
                  }}
                  className="bmi_inputs"
                  placeholder="Enter height"
                  style={{
                    padding: "1px",
                    fontSize: "18px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    width: "100px",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>

            <div style={{ textAlign: "center", marginBottom: "3%" }}>
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorMessage || heightError || weightError}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  calculateBMI();
                  handleCalculate();
                }}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px 15px",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                Calculate
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CheckBmi;
