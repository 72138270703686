import React, { useState, Fragment, useEffect, useRef } from "react";
import { Button, Card, Modal, ModalHeader, ModalBody, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteMood, RecentMood, AuditLogdata } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import "../../../css/custom.css";
import BreadCrumbs from "../../breadcrumbs";
import moment from 'moment';
import love from '../../../images/emoji/Emoji - Heart Eyes.gif'
import angry from '../../../images/emoji/anger.gif'
import Fear from '../../../images/emoji/fear.gif'
import Sadness from '../../../images/emoji/sad.gif'
import Happy from '../../../images/emoji/happy.gif'
import Cheerful from '../../../images/emoji/cheerful.gif'
import Madness from '../../../images/emoji/Madness.gif'
import Calm from '../../../images/emoji/calm.gif'
import Negative from '../../../images/emoji/negative_mood.gif'
import Anxious from '../../../images/emoji/Anxious.gif'
import Irritability from '../../../images/emoji/irritability.gif'
import Disgust from '../../../images/emoji/disgust.gif'
import img from '../../../images/EmptyFile.svg'

import Swal from "sweetalert2";
import { IoMdAddCircle } from "react-icons/io";

const MoodMonitor = () => {
  const [RecentData, setRecentData] = useState([]);
  const dispatch = useDispatch();
  const userId = localStorage.id;
  const { t } = useTranslation();

  // const userId = localStorage.id;
  const screenName = 'Mood Monitor'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const auditLogged = useRef(false); // Ref to track if audit logging has been done

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Mood Monitor - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  const deleteAuditData = () => {
    const action = 'Mood Monitor - Delete'
    const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
    dispatch(AuditLogdata(auditData));
  };

  useEffect(() => {
    const fetchMoodData = async () => {
      const collection = 'Mood';
      const lastSyncDate = '';
      const dataToInsert = [];
      const dataToDelete = [];
      const data = {
        dataToInsert,
        lastSyncDate,
        collection,
        userId,
        dataToDelete
      };

      try {
        const response = await dispatch(RecentMood(data));
        const MoodData = AesDecrypt(response.payload.data);
        setRecentData(MoodData.dataToInsert);
      } catch (error) {
        console.error('Error fetching mood data:', error);
      }
    };

    fetchMoodData();
    logAuditData(); // Log audit data only once
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = moment(dateString, "DD-MM-YYYY HH:mm:ss");
    if (!date.isValid()) {
      return "Invalid Date";
    }
    return date.format("DD MMM, YYYY");
  };

  const [expandedReason, setExpandedReason] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("");
  const [selectedMood, setSelectedMood] = useState("");

  const toggleReason = (index) => {
    setExpandedReason({
      ...expandedReason,
      [index]: !expandedReason[index]
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleReadMore = (item) => {
    if (item.note.trim() === '') {
      setModalReason(getEmptyReason(item.mood));
    } else {
      setModalReason(item.note);
    }
    setSelectedMood(item.mood);
    toggleModal();
  };

  const getEmptyReason = (mood) => {
    switch (mood) {
      case "Anger":
        return "Take a moment to pause and reflect on your anger, then channel it into positive and constructive actions.";
      case "Fear":
        return "Embrace courage and take a step forward despite your fears.";
      case "Sadness":
        return "Courageously face your fears and move forward with confidence.";
      case "Happy":
        return "Spread joy and share your happiness with others.";
      case "Cheerful":
        return "Spread your joy and uplift those around you with your cheerful spirit.";
      case "Romantic":
        return "Express your love openly and wholeheartedly, cherishing every moment together.";
      case "Madness":
        return "Find peace within yourself and seek clarity through mindfulness and self-reflection.";
      case "Calm":
        return "Continue to cultivate and spread your inner calm.";
      case "Negative":
        return "Find moments of joy and gratitude, even in the midst of negativity.";
      case "Anxious":
        return "Focus on the present moment and engage in calming activities to soothe your anxiety.";
      case "Irritability":
        return "Practice patience and find ways to calmly address your concerns.";
      case "Disgust":
        return "Find the beauty in every situation and approach it with an open mind and heart.";
      default:
        return "N/A";
    }
  };

  const moodColors = {
    Anger: "red",
    Fear: "orange",
    Sadness: "gray",
    Happy: "#1eac00",
    Cheerful: "blue",
    Romantic: "#ff8096",
    Madness: "#b0b058",
    Calm: "#1eac00",
    NegativeMood: "red",
    Anxious: "#b0b058",
    Irritability: "red",
    Disgust: "orange"
  };

  const style = {
    width: '100%',
    height: '100%'
  }

  const moodOptions = [
    { value: "Anger", label: t("Anger"), emoji: <img src={angry} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Fear", label: t("Fear"), emoji: <img src={Fear} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Sadness", label: t("Sadness"), emoji: <img src={Sadness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Happy", label: t("Happy"), emoji: <img src={Happy} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Cheerful", label: t("Cheerful"), emoji: <img src={Cheerful} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Romantic", label: t("Romantic"), emoji: <img src={love} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Madness", label: t("Madness"), emoji: <img src={Madness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Calm", label: t("Calm"), emoji: <img src={Calm} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Negative", label: t("Negative"), emoji: <img src={Negative} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Anxious", label: t("Anxious"), emoji: <img src={Anxious} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Irritability", label: t("Irritability"), emoji: <img src={Irritability} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Disgust", label: t("Disgust"), emoji: <img src={Disgust} alt="Heart Eyes Emoji" style={style} /> }
  ];

  const reasonOptions = [
    { value: "Family", label: t("Family") },
    { value: "Friend", label: t("Friend") },
    { value: "School", label: t("School") },
    { value: "College", label: t("College") },
    { value: "Education", label: t("Education") },
    { value: "Love", label: t("Love") },
    { value: "Care", label: t("Care") },
    { value: "Others", label: t("Others") },
  ];


  const handleDeleteMood = (_id, uid, createdDate) => {
    const lastSyncDate = '';
    const collection = 'Mood';

    const dataToInsert = [{
    }];
    const dataToDelete = [{
      _id,
      uid,
      createdDate
    }];
    const data = {
      dataToDelete,
      dataToInsert,
      userId,
      collection,
      lastSyncDate
    }
    dispatch(DeleteMood(data)).then((response) => {
      deleteAuditData()
      const Returendata = AesDecrypt(response.payload.data);
      setRecentData(Returendata.dataToInsert)

    });
  };

  const handleDeleteClick = (_id, uid, createdDate) => {
    Swal.fire({
      title: 'Do you really want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons:'yes'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMood(_id, uid, createdDate);
        Swal.fire(
          'Deleted!',
          'The record has been deleted.',
          'success'
        );
      }
    });
  };


  return (
    <>
      <Fragment>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}  >
          <h1>Mood Monitor</h1>
          <Link to={`/add_mood`}>
            <Button style={{ maxWidth: '150px', marginBottom: '15%', minWidth: '50px' }} color="primary">+</Button>
          </Link>
        </div> */}
        <div className="main_table" style={{marginBottom:"4%"}}>
          <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
            <div >
              <h1 className="container_heading">Mood Monitor</h1>
            </div>
            <Link to={`/add_mood`}>
              <IoMdAddCircle style={{ fontSize: '40px', color: 'green' }} />
            </Link>
          </div>
        </div>
       
        <div className="mood_responsive_row" style={{gap:"0%", marginTop:"2% auto"}}>
          {Array.isArray(RecentData) && RecentData.length === 0 ? (
            <div className="d-flex justify-content-center">
              <img
                src={img}
                alt="No Data"
                style={{ position: 'relative', top: '100px', height: '400px' }}
              />
            </div>
          ) : (
            Array.isArray(RecentData) &&
            RecentData

              .slice()
              .sort((a, b) => new Date(b.lastSyncDate) - new Date(a.lastSyncDate))
              .map((item, index) => (
                <div className="" style={{ padding: '1% ' }} key={index}>
                  <Card style={{ padding: '4% 5%', margin: "auto 3% " }}
                    className="card1 card-hover">
                    <div className="card-header-custom-mood">
                      <h4><span style={{ color: 'black' }}>
                        {moment(item.createdDate).format('MMM DD')}
                      </span>{', '}
                        <span style={{ color: 'gray' }}>
                          {moment(item.createdDate).format('hh:mm A')}
                        </span></h4>
                      <span
                        title={t('Delete')}
                        onClick={() => handleDeleteClick(item._id, item.uid, item.createdDate)}

                      >

                        <i className="fa fa-trash" style={{ color: "red" }}></i>
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <div
                        className="mood_emoji"
                        style={{
                          backgroundColor: '#ffffff36',
                          boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset`,
                          padding: '8px',
                          borderRadius: '18px',
                        }}
                      >
                        {moodOptions.map(
                          (moodOption) =>
                            moodOption.value === item.mood && <span key={moodOption.label}>{moodOption.emoji}</span>
                        )}
                      </div>
                      <div style={{ marginLeft: '8%' }}>
                        <div className="mood_text">
                          <h6>Mood :</h6>
                          <h3 style={{ color: moodColors[item.mood] }}>{t(item.mood)}</h3>
                        </div>
                        <div className="mood_text2">
                          <h6>Reason :</h6>
                          <h3>{t(item.reason)}</h3>
                        </div>
                        
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      {reasonOptions.map(
                        (reasonOption) =>
                          reasonOption.label === item.reason && <span className="" key={reasonOption.label}>{reasonOption.emoji}</span>
                      )}
                    </div>

                    <h4 style={{ color: moodColors[item.mood], marginBottom: '10px' }}>
                      {item.note.trim() !== '' ? (
                        item.note.length > 45 && !expandedReason[index] ? (
                          <div
                            style={{ color: moodColors[item.mood] }}
                            className="mood-ReadMore"
                            onClick={() => handleReadMore(item)}
                          >
                            {`${item.note.substring(0, 85)}...`} {t('Read More')}
                          </div>
                        ) : (
                          <div className="mood_reson" style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <p style={{ marginRight: '5px' }}>Note :</p> */}
                            <p className="reason_item" style={{ color: moodColors[item.mood] }}>{item.note}</p>
                          </div>
                        )
                      ) : (
                        <div style={{ color: moodColors[item.mood] , marginBottom:"1.5%" }} className="mood-ReadMore" onClick={() => handleReadMore(item)}>
                          {getEmptyReason(item.mood).split(' ').slice(0, 4).join(' ')}
                          <span className="mood_readmore">{t('...Read More')}</span>
                        </div>
                      )}
                    </h4>
                  </Card>
                </div>
              ))
          )}
        </div>

        {/* <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}><strong>{t(selectedMood)}</strong></ModalHeader>
          <ModalBody>
            <p>{modalReason}</p>
          </ModalBody>
        </Modal> */}
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <strong>{t(selectedMood)}</strong>
          </ModalHeader>
          <ModalBody style={{ width: "95%", wordWrap: "break-word", whiteSpace: "normal", margin:"0 auto"}}>
            <p>{modalReason}</p>
          </ModalBody>
        </Modal>
      </Fragment>
    </>
  );
};

export default MoodMonitor;
