import axios from 'axios';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';

export const getlanguage = createAsyncThunk('sidemenu/getlanguage', async (data) => {

    const response = await fetch(webservice + 'api/getLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

export const AuditLogdata = createAsyncThunk('usermanagement/auditLogdata', async (auditData) => {
    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});

export const getfruitdata = createAsyncThunk('UserType/getfruitdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/fruits', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const fruitsdetails = createAsyncThunk('UserType/fruitsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/fruitsDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const getvegdata = createAsyncThunk('UserType/getvegdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/vegetables', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const vegdetails = createAsyncThunk('UserType/vegdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/vegDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const getnutdata = createAsyncThunk('UserType/getnutdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/grains', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const nutsdetails = createAsyncThunk('UserType/nutsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/grainsDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const DocumentUploadSlice = createSlice({
    name: 'DocumentUpload',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getfruitdata.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})