import { useDispatch } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef } from "react";
import { AddBloodPressureData, AuditLogdata, BloodPressureData } from './store';
import BloodPressureTable from './dataTable';
import DualLine from './waveChart';
import { Button, Col, Form } from 'react-bootstrap';
import { Card } from 'reactstrap';
import "../../../css/custom.css";
import { AesDecrypt } from '../../../services/crypto';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../breadcrumbs'


function ChartChartjs() {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(5);
  const [showDualLineChart, setShowDualLineChart] = useState(false);
  const [viewMode, setViewMode] = useState('chart');
  const { t } = useTranslation();

  const handleSelectChange = (e) => {
    setSelectedValue(parseInt(e.target.value));
  };
  const [allData, setAllData] = useState([]);
  const auditLogged = useRef(false);
  const userId = localStorage.id
  const screenName = 'Blood Pressure'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Blood Pressure - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  // const collection = 'Medical_BP';
  // const lastSyncDate = '';
  // const userId = localStorage.id
  // const dataToInsert = [];
  // const dataToDelete = [];
  // const data = {
  //   dataToInsert,
  //   lastSyncDate,
  //   collection,
  //   userId,
  //   dataToDelete
  // };

  // useEffect(() => {
  //   dispatch(BloodPressureData(data))
  //     .then((response) => {
  //       const Encparam = AesDecrypt(response.payload.data)
  //       setAllData(Encparam.dataToInsert);
  //     });
  //   // });
  //   //commend by kaji for looping function
  // }, [dispatch]);

  useEffect(() => {
    const fetchMoodData = async () => {
      const collection = 'Medical_BP';
      const lastSyncDate = '';
      const dataToInsert = [];
      const dataToDelete = [];
      const data = {
        dataToInsert,
        lastSyncDate,
        collection,
        userId,
        dataToDelete
      };

      try {
        const response = await dispatch(BloodPressureData(data));
        const Encparam = AesDecrypt(response.payload.data);
        setAllData(Encparam.dataToInsert);
      } catch (error) {
        console.error('Error fetching mood data:', error);
      }
    };

    fetchMoodData();
    logAuditData(); // Log audit data only once
  }, [dispatch]);



  const handleViewToggle = () => {
    setViewMode(viewMode === 'chart' ? 'list' : 'chart');
  };

  const handleChartViewClick = () => {
    setShowDualLineChart(true);
    handleViewToggle();
  };

  const handleListViewClick = () => {
    setShowDualLineChart(false);
    handleViewToggle();
  };

  // const handleReload = () => {
  //   dispatch(BloodPressureData())
  //     .then((response) => {
  //       if (response.payload && Array.isArray(response.payload)) {
  //         setAllData(response.payload);
  //       } else {
  //         console.error('Invalid data format:', response.payload);
  //       }
  //     })
  // }


  return (
    <>
      {/* <Breadcrumbs title={t('Blood Pressure')} data={[{ title: t(' ') }]} /> */}

      <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
        {/* <Form.Select
            value={selectedValue}
            onChange={handleSelectChange}
            style={{
              maxWidth: "10%",
              marginLeft: "2%",
              marginTop: "2%",
              backgroundColor: "#f8f9fa",
              fontSize: "14px",
              border: "1px solid #ced4da",
              borderRadius: "4px",
              padding: "5px 10px"
            }}
          >
            <option value={5} style={{ backgroundColor: "#fff", color: "#000" }}>Last 5</option>
            <option value={10} style={{ backgroundColor: "#fff", color: "#000" }}>Last 10</option>
            <option value={50} style={{ backgroundColor: "#fff", color: "#000" }}>Last 50</option>
            <option value={100} style={{ backgroundColor: "#fff", color: "#000" }}>Last 100</option>
          </Form.Select> */}


        {/* <Col>
          {viewMode === 'chart' ? (
            <Button
              color="secondary"
              className='small-but'
              onClick={handleChartViewClick}
              style={{ display: 'flex', minWidth: '173px' }}
            >
              <div style={{ marginLeft: '15%' }}>
                <i className="fas fa-chart-bar"></i>
              </div>
              <div style={{ marginLeft: '7%' }}>
                {t('Chart View')}
              </div>
            </Button>
          ) : (
            <Button
              color="secondary"
              className='small-but'
              onClick={handleListViewClick}
              style={{ display: 'flex', minWidth: '193px' }}
            >
              <div style={{ marginLeft: '15%' }}>
                <i className="fas fa-list"></i>
              </div>
              <div style={{ marginLeft: '7%' }}>
                {t('List View')}
              </div>
            </Button>

          )}
        </Col> */}

      </div>
      {/* {showDualLineChart ? (
        <DualLine alldata={allData.sort((a, b) => new Date(b.testDate) - new Date(a.testDate)).slice(0, selectedValue)} />
        // <DualLine alldata={allData} />
      ) : (
        <BloodPressureTable allData={allData} setAllData={setAllData} />
      )} */}
      <BloodPressureTable allData={allData} setAllData={setAllData} />

    </>
  );
}

export default ChartChartjs;
