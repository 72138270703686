import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';


export const getUserdata = createAsyncThunk('sidemenu/userDataFlag', async (data) => {
    const response = await fetch(webservice + 'api/getauditdatas', {
        method: 'POST',
        body: JSON.stringify({ data }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});


export const AuditLogdata = createAsyncThunk('usermanagement/auditLogdata', async (auditData) => {
    
    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }), 
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});


export const BMISlice = createSlice({
    name: 'BMI',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getUserdata.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})