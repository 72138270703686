import React, { useState, useMemo, useEffect } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import BreadCrumbs from "../../breadcrumbs";
import { t } from "i18next";
import webservice from "../../../services/webservice";
import { blooddata, findAllBloodDonor, sendTargetDonor } from "./store";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";

const Finddonor = () => {
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
  const { id } = useParams();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [talukOptions, setTalukOptions] = useState([]);
  const [stateData, setStateData] = useState({});
  const [results, setResults] = useState([]);
  const [selectedDonors, setSelectedDonors] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userId = localStorage.id;
  const { t } = useTranslation();
  const bloodGroupOptions = [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
  ];

  const stateOptions = [
    { value: 'Andhra Pradesh', label: 'ANDHRA PRADESH' },
    { value: 'Arunachal Pradesh', label: 'ARUNACHAL PRADESH' },
    { value: 'Assam', label: 'ASSAM' },
    { value: 'Bihar', label: 'BIHAR' },
    { value: 'Chhattisgarh', label: 'CHHATTISGARH' },
    { value: 'Goa', label: 'GOA' },
    { value: 'Gujarat', label: 'GUJARAT' },
    { value: 'Haryana', label: 'HARYANA' },
    { value: 'Himachal Pradesh', label: 'HIMACHAL PRADESH' },
    { value: 'Jharkhand', label: 'JHARKHAND' },
    { value: 'Karnataka', label: 'KARNATAKA' },
    { value: 'Kerala', label: 'KERALA' },
    { value: 'Madhya Pradesh', label: 'MADHYA PRADESH' },
    { value: 'Maharashtra', label: 'MAHARASHTRA' },
    { value: 'Manipur', label: 'MANIPUR' },
    { value: 'Meghalaya', label: 'MEGHALAYA' },
    { value: 'Mizoram', label: 'MIZORAM' },
    { value: 'Nagaland', label: 'NAGALAND' },
    { value: 'Odisha', label: 'ODISHA' },
    { value: 'Punjab', label: 'PUNJAB' },
    { value: 'Rajasthan', label: 'RAJASTHAN' },
    { value: 'Sikkim', label: 'SIKKIM' },
    { value: 'Tamil Nadu', label: 'TAMIL NADU' },
    { value: 'Telangana', label: 'TELANGANA' },
    { value: 'Tripura', label: 'TRIPURA' },
    { value: 'Uttar Pradesh', label: 'UTTAR PRADESH' },
    { value: 'Uttarakhand', label: 'UTTARAKHAND' },
    { value: 'West Bengal', label: 'WEST BENGAL' },
    { value: 'Andaman and Nicobar Islands', label: 'ANDAMAN AND NICOBAR ISLANDS' },
    { value: 'Chandigarh', label: 'CHANDIGARH' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU' },
    { value: 'Lakshadweep', label: 'LAKSHADWEEP' },
    { value: 'Delhi', label: 'DELHI' },
    { value: 'Puducherry', label: 'PUDUCHERRY' },
    { value: 'Ladakh', label: 'LADAKH' },
    { value: 'Jammu and Kashmir', label: 'JAMMU AND KASHMIR' }
  ];

  useEffect(() => {

    dispatch(findAllBloodDonor(id)).then((response) => {
      if (response.payload.response === 'S') {
        const decryptedData = AesDecrypt(response.payload.data);
        setResults(Array.isArray(decryptedData) ? decryptedData : []);
        setShowTable(true);
      } else {
        Swal.fire({
          title: 'nodonor found'
        })
      }

    });
  }, [dispatch]);

  const handleBloodGroupChange = (selectedOption) => {
    setSelectedBloodGroup({ ...selectedOption, id });
  };

  const handleStateChange = async (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setDistrictOptions([]);
    setTalukOptions([]);
    try {
      const response = await axios.post(webservice + "api/get-districts-taluks", {
        state: selectedOption.value,
      });
      const data = response.data.districtsAndTaluks;
      setStateData(data);
      const districts = Object.keys(data).map((district) => ({
        value: district,
        label: district,
      }));
      setDistrictOptions(districts);
    } catch (error) {
      console.error("Error fetching districts and taluks:", error);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedDistrict(null);
      setTalukOptions([]);
      return;
    }

    setSelectedDistrict(selectedOption);
    if (selectedState && selectedOption) {
      const taluks = stateData[selectedOption.value].map((taluk) => ({
        value: taluk,
        label: taluk,
      }));
      setTalukOptions(taluks);
      setSelectedTaluk(null);
    }
  };

  // const handleHeaderCheckboxChange = (event) => {
  //   const checked = event.target.checked;
  //   if (checked) {
  //     setSelectedDonors(results.map((donor) => ({ original: donor }))); // Select all donors when checked
  //   } else {
  //     setSelectedDonors([]); // Clear selection when unchecked
  //   }
  // };

  const handleCheckboxChange = (event, row) => {
    if (!row || !row.original || !row.original.userId) {
      console.error("Invalid row object:", row);
      return;
    }

    const checked = event.target.checked;
    if (checked) {
      setSelectedDonors((prevSelected) => [...prevSelected, { original: row.original }]);
    } else {
      setSelectedDonors((prevSelected) =>
        prevSelected.filter((donor) => donor.original.userId !== row.original.userId)
      );
    }
  };


  const handleSubmit = async () => {
    setSelectedBloodGroup(null)
    setSelectedDistrict(null)
    setSelectedTaluk(null)
    setSelectedState(null)
  };

  const filteredResults = useMemo(() => {
    return results.filter((result) => {
      if (selectedBloodGroup && result.bloodGroup !== selectedBloodGroup.value) {
        return false;
      }
      if (selectedState && result.state !== selectedState.value) {
        return false;
      }
      if (selectedDistrict && result.district !== selectedDistrict.value) {
        return false;
      }
      if (selectedTaluk && result.subDistrict !== selectedTaluk.value) {
        return false;
      }
      return true;
    });
  }, [results, selectedBloodGroup, selectedState, selectedDistrict, selectedTaluk]);





  const handleSubmitSelectedDonors = () => {
    if (selectedDonors.length > 0) {
      const receiverId = selectedDonors.map(donor => donor.original.userId); // Assuming userId is the ID field
      const postData = {
        donorId: receiverId,
        receiverId: userId,
        postId: id,
      };

      dispatch(sendTargetDonor(postData)).then((response) => {

        if (response.payload.response === 'S') {
          dispatch(findAllBloodDonor(id)).then((response) => {
            const decryptedData = AesDecrypt(response.payload.data);
            setResults(Array.isArray(decryptedData) ? decryptedData : []);
            setShowTable(true);
          });
          // Optionally, clear selected donors
          setSelectedDonors([]);
          Swal.fire({
            title: "Complete",
            text: "Request sent to the donor successfully.",
            icon: "success",
            toast:true,
            position:'top-end',
            timer:3000,
            timerProgressBar:true,
            showConfirmButton: false,
            // confirmButtonText: "OK",
          });
          navigate('/createpost')
        } else {
          console.error("Error fetching user data: response.payload.response is not 'S'");
          Swal.fire({
            title: "Error",
            text: "Unable to fetch user data. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }).catch((error) => {
        console.error("Error fetching user data:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while fetching user data. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    } else {
      Swal.fire({
        title: t("No Donors Selected"),
        text: t("Please select at least one donor from the list before proceeding. Your selection helps us ensure that you receive the best possible match."),
        icon: "warning",
        confirmButtonText: t("Got it"),
      });
    }
  };



  return (
    <>
      {/* <div >
          <Link to={"/"}>
            <i style={{ color: '#007bff', fontSize: '32px', position: 'relative', bottom: '15px' }} className="fas fa-arrow-alt-circle-left"></i>
          </Link>
        </div> */}

      <div>
        <h1>Find a blood donor</h1>
        {/* <BreadCrumbs title={t('Find a blood donor')} data={[{ title: t('Blood Donors') }]} /> */}
      </div>

      <div className="find-donor-form-container">
        <Form className="find-donor-form">
          <Form.Group className="bloodgroup-contant" controlId="bloodGroup">
            <Form.Label>{t("Blood group")}</Form.Label>
            <ReactSelect
              className="react-select-container"
              options={bloodGroupOptions}
              value={selectedBloodGroup}
              onChange={handleBloodGroupChange}
              placeholder={t("blood group")}
            />
          </Form.Group>
          <Form.Group className="bloodgroup-contant" controlId="stateSelect">
            <Form.Label>{t("State")}</Form.Label>
            <ReactSelect
              className="react-select-container"
              options={stateOptions}
              value={selectedState}
              onChange={handleStateChange}
              placeholder={t("Select state")}
              isDisabled={!selectedBloodGroup}
            />
          </Form.Group>
          <Form.Group className="bloodgroup-contant" controlId="districtSelect">
            <Form.Label>{t("District")}</Form.Label>
            <ReactSelect
              className="react-select-container"
              options={districtOptions}
              value={selectedDistrict}
              onChange={handleDistrictChange}
              placeholder={t("Select district")}
              isDisabled={!selectedState}
            />
          </Form.Group>
          <Form.Group className="bloodgroup-contant" controlId="talukSelect">
            <Form.Label>{t("Taluk")}</Form.Label>
            <ReactSelect
              className="react-select-container"
              options={talukOptions}
              value={selectedTaluk}
              onChange={(selectedOption) => setSelectedTaluk(selectedOption)}
              placeholder={t("Select taluk")}
              isDisabled={!selectedDistrict}
            />
          </Form.Group>
          <Button style={{ maxWidth: '150px', maxHeight: '45px', marginTop: '28px' }}
            variant="primary" onClick={handleSubmit}>
            {t("Reset")}
          </Button>
        </Form>
      </div>
      <div className="table-container">
      </div>



      <div>
        {showTable && (
          <div className="table-container">
            {filteredResults.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th className="checkbox-table-head"></th>
                    <th>{t("name")}</th>
                    <th>{t("mobile")}</th>
                    <th>{t("state")}</th>
                    <th>{t("district")}</th>
                    <th>{t("taluk")}</th>
                    <th>{t("blood group")}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResults.map((donor) => (
                    <tr key={donor.userId}>
                      <td className="checkbox-table-head">

                        <Input
                          type="checkbox"
                          checked={selectedDonors.some((selected) => selected.original.userId === donor.userId)}
                          onChange={(event) => handleCheckboxChange(event, { original: donor })}
                        />
                      </td>
                      <td>{donor.name}</td>
                      <td>{donor.mobile}</td>
                      <td>{donor.state}</td>
                      <td>{donor.district}</td>
                      <td>{donor.subDistrict}</td>
                      <td>{donor.bloodGroup}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot></tfoot>
              </Table>
            ) : (
              <>
                <h3 style={{ textAlign: 'center', marginTop: '10%', color: 'grey' }}>No donors found, please try again later</h3>
              </>
            )}
            {filteredResults.length > 0 && (
              <Button
                className="fixed-button"
                variant="primary"
                onClick={handleSubmitSelectedDonors}
              >
                {t('Submit')}
              </Button>
            )}
          </div>
        )}
      </div>

    </>
  );
};

export default Finddonor;
