import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const getlanguage = createAsyncThunk('sidemenu/getlanguage', async (data) => {

    const response = await fetch(webservice + 'api/getLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

export const AuditLogdata = createAsyncThunk('usermanagement/auditLogdata', async (auditData) => {

    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});



export const getDob = createAsyncThunk('UserType/getPassword', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/getDob', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getdata = createAsyncThunk('Medical/getdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/medicalCheckup', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

// export const getdata = createAsyncThunk('Medical/getdata', async () => {
//     try {
//         const response = await fetch(webservice + 'api/medicalChecklist', {
//             method: 'POST', // Change method to GET
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
  
//         const responseData = await response.json();
        
  
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
//   });

  export const testdetails = createAsyncThunk('Medical/testdetails', async (id) => {
    try {
        const response = await fetch(webservice + 'api/medicalCheckdetails', {
            method: 'POST',
            body: JSON.stringify({id}), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
  
        const responseData = await response.json();
  
  
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
  });



export const medicalcheckup_details = createAsyncThunk('Medical/medicalcheckup_details', async (data) => {

    try {
        const response = await fetch(webservice + 'api/medicalCheckdetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});