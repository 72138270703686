import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';


export const getAllData = createAsyncThunk('DiabetesForm/getAllData', async (data) => {
    const Encparams = AesEncrypt((data));

    try {
        const response = await fetch(webservice + 'api/pushData', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }), // Change method to GET
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        // const responseData = AesDecrypt(responseDataEnc.data)
        // responseData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const AuditLogdata = createAsyncThunk('usermanagement/auditLogdata', async (auditData) => {
    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});



export const AddDiabetesData = createAsyncThunk('DiabetesForm/DiabetesData', async (data) => {


    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/pushData', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const diabetesEdit = createAsyncThunk('dataTable/diabetesEdit', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/updateBloodSugar', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const diabetesDelete = createAsyncThunk('dataTable/diabetesDelete', async (data) => {
    const Encparams = AesEncrypt((data));

    try {
        const response = await fetch(webservice + 'api/pushData', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const DocumentUploadSlice = createSlice({
    name: 'bloodpressure',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})