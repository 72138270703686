import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { bloodreceiverdatas, getAcceptedBloodPost, getRequestedDonor, updateCancelAndRemove, userAccesptPost } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useDispatch } from "react-redux";
import { Button, Card, Label } from "reactstrap";
import { t } from "i18next";
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import img from '../../../images/EmptyFile.svg'
const userId = localStorage.id


const AcceptedList = () => {
    const { id } = useParams();

    const [acceptedBloodPost, setacceptedBloodPost] = useState([]);
    const dispatch = useDispatch();
    const [showMobileFor, setShowMobileFor] = useState(null);

    useEffect(() => {
        dispatch(getAcceptedBloodPost(userId))
            .then((response) => {

                if (response.payload.response === 'S') {
                    try {
                        const decryptedData = AesDecrypt(response.payload.data);
                        if (Array.isArray(decryptedData)) {
                            setacceptedBloodPost(decryptedData);
                        } else {
                            setacceptedBloodPost([]);
                        }
                    } catch (error) {
                        console.error('Error decrypting data:', error);
                        setacceptedBloodPost([]);
                    }
                } else {
                    setacceptedBloodPost([]);
                }

            })
            .catch((error) => {
                console.error('Error fetching accepted blood posts:', error);
                setacceptedBloodPost([]);
            });
    }, [dispatch]);



    const handleWillingToDonate = async (receiver) => {
        try {
            setShowMobileFor(receiver.postId);
            const response = await dispatch(userAccesptPost(receiver))
            const decryptedData = response.payload.response
            if (decryptedData === 'S') {
                dispatch(getAcceptedBloodPost(userId)).then((response) => {
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        if (Array.isArray(decryptedData)) {
                            setacceptedBloodPost(decryptedData);
                        } else {
                            setacceptedBloodPost([]);
                        }
                    } else {
                        setacceptedBloodPost([]);
                    }
                });
                if (!receiver.acceptStatus) {
                    Swal.fire({
                        title: 'Success',
                        text: 'You have accepted to donate.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                } else {
                    setShowMobileFor(null);
                    Swal.fire({
                        icon: 'info',
                        title: 'Donation Canceled',
                        // text: 'You have canceled the donation.',
                        toast: true, // Enables the toast mode
                        position: 'top-end', // You can choose from 'top-start', 'top-end', 'bottom-start', 'bottom-end'
                        showConfirmButton: false, // Hides the confirm button
                        timer: 3000, // Duration for the toast to stay visible in milliseconds
                        timerProgressBar: true, // Shows a progress bar during the timer
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer); // Stops the timer when hovered
                            toast.addEventListener('mouseleave', Swal.resumeTimer); // Resumes the timer when mouse leaves
                        }
                    });
                    
                }
            } else {
                setacceptedBloodPost([]);
            }
            return;

        } catch (error) {
            console.error('Error fetching isDonor:', error);
            Swal.fire({
                title: "Error",
                text: "Error while accepting status. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    return (

        <>
        <div className="blooddonation-topmenu">
        <div className="bloodbackbutton" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={"/blood"}>
                        <i style={{ color: '#007bff',  marginBottom: '0%' }} className="fas fa-arrow-alt-circle-left"></i>
                    </Link>
                </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '50px' }}> */}
                  
                   <div>
                   &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
                   </div>

                </div>
                <div className="blooddonation_navbtns">
        <div className="blood_btns"  >
         <Link to={"/createpost"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Your Request")}
            </Button>
          </Link>
          <Link to={"/trackHistory"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Track History")}
            </Button>
          </Link>
          <Link to={"/acceptedList"}>
            <Button className="blood_nav_btns" variant="info">
              {t("Accepted List")}
            </Button>
          </Link>
          <Link to={"/bloodNotification"}>
            <Button className="blood_nav_btns" variant="info">
              <i id="icon-dark" className="notification-icon">&#128276; {t("Notification")}</i>
            </Button>
          </Link>
         </div>
        </div>

            <div>
               
                <div>
                    {Array.isArray(acceptedBloodPost) && acceptedBloodPost.length === 0 ? (
                        null
                    ) : (
                        <h1 style={{ textAlign: 'center' }}>You accepted this donors</h1>
                    )}
                    {/* <BreadCrumbs title={t('You Accepted these donors')} data={[{ title: t('Blood Request') }]} /> */}
                </div>
            </div>

            <div className="card-container">
                {Array.isArray(acceptedBloodPost) && acceptedBloodPost.length === 0 ? (

                    // <div className="d-flex justify-content-center">
                    //     <img src={img} alt="No Data" style={{ position: 'relative', top: '100px', height: "300px" }} />
                    // </div>

                    <div
                        className="no-data"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "auto",
                            textAlign: "center",
                            padding: "2rem",
                            // backgroundImage: `url(${img})`, // Replace img with the actual path to your image
                            backgroundSize: "contain", // Makes the image smaller
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            opacity: 0.8,
                            borderRadius: "12px",
                            margin: "auto",
                            marginTop: "2rem",
                            maxWidth: "800px",
                            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                            color: "#ffffff",
                        }}

                    >
                        <h2 style={{
                            color: "rgb(76, 175, 80)",
                            marginBottom: "1rem",
                            fontSize: "2rem",
                            fontWeight: "bold",
                            // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                        }}>
                            No Accepted Donations Yet
                        </h2>
                        <p style={{
                            fontSize: "1.2rem",
                            lineHeight: "1.8",
                            color: "black",
                            // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                            marginBottom: "1rem",
                        }}>
                            You haven't accepted any blood donation requests yet.
                            Every donation brings hope and saves lives!
                        </p>
                        <p style={{
                            fontSize: "1.2rem",
                            lineHeight: "1.8",
                            color: "black",
                            // textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                            marginBottom: "1.5rem",
                        }}>
                            Check out the blood posts and accept a request to make a positive impact.
                            Together, we can save lives and build a healthier community.
                        </p>
                    </div>

                ) : (
                    Array.isArray(acceptedBloodPost) && acceptedBloodPost.map((receiver) => (
                        <Card key={receiver.postId} className="card-custom">
                            <div className="card-header-custom">
                                <h2>{receiver.name} needs blood</h2>
                                <span className="blood-group-blood">  {receiver.bloodGroup || "All Blood Types"}</span>
                            </div>
                            <div className="card-body-custom">
                                <p>
                                    <strong>Mobile :</strong> {receiver.acceptStatus ? receiver.mobile : '**********'}
                                </p>
                                {/* <p>Mobile: {receiver.mobile}</p> */}
                                {/* <p> <strong>Mobile :</strong> {'**********'}</p> */}
                                <p> <strong>hospitalName : </strong> {receiver.hospitalName || 'Not Mentioned'}</p>
                                <p> <strong>Taluk : </strong> {receiver.subDistrict || 'Not Mentioned'}</p>
                                <p> <strong>District : </strong> {receiver.district || 'Not Mentioned'}</p>
                                <p> <strong>State : </strong> {receiver.state || 'Not Mentioned'}</p>
                                <p> <strong>hospital Address : </strong> {receiver.address || 'Not Mentioned'}</p>
                            </div>
                            <div className="card-footer-custom-space">
                                Posted on: {new Date(receiver.createdDate).toLocaleDateString()}
                                <Button
                                    className={`donate-button ${receiver.acceptStatus ? 'cancel-button' : ''}`}
                                    onClick={() => handleWillingToDonate(receiver)}
                                >
                                    {receiver.acceptStatus ? (receiver.status === 'PC') || (receiver.status === 'NT') ? 'Remove' : 'Cancel' : 'Accept'}
                                </Button>
                            </div>
                        </Card>
                    ))
                )}

            </div>
        </>
    );
}

export default AcceptedList;
