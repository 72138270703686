import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';

export const BloodPressureData = createAsyncThunk('bloodpressure/getAllData', async (data) => {
 // Corrected variable name from Idd to userId

    const Encparams = AesEncrypt((data));
    try {
        const response = await fetch(webservice + 'api/pushData', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        // const responseData = AesDecrypt(responseDataEnc.data)
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const AuditLogdata = createAsyncThunk('usermanagement/auditLogdata', async (auditData) => {
    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});


export const AddBloodPressureData = createAsyncThunk('bloodpressure/BloodPressureData', async (data) => {
    const Encparams = AesEncrypt(data)
    
    try {
        const response = await fetch(webservice + 'api/pushData', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const updateBloodPressureData = createAsyncThunk('bloodpressure/updateBloodPressureData', async ({ data }) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/updateBloodPressure', {
            method: 'POST', // Change method to POST
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ Encparams }), // Include both ID and data in the request body
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const deleteBloodPressureData = createAsyncThunk('bloodpressure/deleteBloodPressureData', async (data) => { // Modified to accept only ID
  
    const Encparams = AesEncrypt(data);
    try {
        const response = await fetch(webservice + 'api/pushData', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            //body: JSON.parse(id)
            body: JSON.stringify({ Encparams }), // Pass only the ID in the request body
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const DocumentUploadSlice = createSlice({
    name: 'bloodpressure',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(BloodPressureData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})