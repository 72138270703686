import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const getlanguage = createAsyncThunk('sidemenu/getlanguage', async (data) => {

    const response = await fetch(webservice + 'api/getLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});


export const AuditLogdata = createAsyncThunk('Insurance/auditLogdata', async (auditData) => {
    const EncParams = AesEncrypt({ auditData });
    const response = await fetch(webservice + 'api/auditLogdata', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
        .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
});

export const GetAllInsuranceData = createAsyncThunk('Insurance/GetAllInsuranceData', async (data) => {
    const EncParams = AesEncrypt({ data });
    try {
        const response = await fetch(webservice + 'api/GetAllInsuranceData', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});


export const AddInsuranceData = createAsyncThunk('Insurance/AddInsuranceData', async (formData) => {
    const EncParams = AesEncrypt({ formData });

    try {
        const response = await fetch(webservice + 'api/AddInsuranceData', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});


export const InsuranceUploadSlice = createSlice({
    name: 'Insurance',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(GetAllInsuranceData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})