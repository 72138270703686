import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from "react";
import { AuditLogdata, getAllData } from '../store';
import PrescriptionListing from './prescriptionListing';
import Breadcrumbs from '../../../breadcrumbs'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap';
import { Card } from 'reactstrap';
function ChartChartjs() {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState(10);
  const userId = localStorage.id

  const auditLogged = useRef(false);
  const screenName = 'Document Upload'
  const updatedDate = new Date()
  const deviceType = 'web'
  const appVersion = ''

  const logAuditData = () => {
    if (!auditLogged.current) {
      const action = 'Document Upload - View';
      const auditData = [{ userId, screenName, action, updatedDate, deviceType, appVersion }];
      dispatch(AuditLogdata(auditData));
      auditLogged.current = true; // Mark as logged
    }
  };

  const handleSelectChange = (e) => {
    setSelectedValue(parseInt(e.value));
  };
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    dispatch(getAllData())
      .then((response) => {
        setAllData(response.payload);
        logAuditData()
      });
  }, [dispatch]);

  const handleReload = () => {
    dispatch(getAllData())
      .then((response) => {
        if (response.payload && Array.isArray(response.payload)) {
          setAllData(response.payload);
        } else {
          console.error('Invalid data format:', response.payload);
        }
      })
  }




  return (
    <>
      <PrescriptionListing
        alldata={allData && allData.length > 0 ? allData.slice(-selectedValue) : []}
        handleReload={handleReload}
        handleSelectChange={handleSelectChange}
        selectedValue={selectedValue}
      />
    </>
  );
}

export default ChartChartjs;
